import { Component, OnInit } from '@angular/core';

import { ThinkDaily } from '../../core/models';
import { ThinkDailyService } from '../services';

@Component({
    templateUrl: 'think-daily.component.html',
    styleUrls: ['think-daily.component.css']
})
export class ThinkDailyComponent implements OnInit {
    public businessThinkDailies: ThinkDaily[] = [];
    public showBusiness = true;
    public thinkDailies: ThinkDaily[] = [];

    constructor(
        private thinkDailyService: ThinkDailyService
    ) {}

    public ngOnInit(): void {
        this.thinkDailyService.getThinkDaily()
            .subscribe((thinkDailies: ThinkDaily[]) => {
                this.thinkDailies = thinkDailies;
            });

        this.thinkDailyService.getThinkDailyBusiness()
            .subscribe((thinkDailies: ThinkDaily[]) => {
                this.businessThinkDailies = thinkDailies;
            });
    }
}