import { Component, Input, OnInit } from '@angular/core';

import { ScaleType } from '@swimlane/ngx-charts';

import { WidgetService } from '../../core';
import { Spread } from '../../student-tools/models';

@Component({
    selector: 'cn-spread-widget',
    templateUrl: './spread-widget.component.html',
    styleUrls: ['./spread-widget.component.css']
})
export class SpreadWidgetComponent implements OnInit {
    public calculatedColumns: boolean[] = [false, false, false, false, false, false, true];
    public colors = { name: 'Spread', selectable: false, group: ScaleType.Linear, domain: ['#06631C', '#FFA400', '#E9190F', '#000', '#0101AD'] };
    @Input() public data: { data: Spread[] } | undefined = undefined;
    public displayedSpreadColumns: string[] = ['Month', 'Year', 'Cash', 'Receivables', 'Payables', 'Sales', 'Spread'];
    public pending = false;
    @Input() public showLink = true;

    constructor(
        private widgetService: WidgetService
    ) { }

    public getSpread(columnName: string = 'Spread', rowData: Spread): any {
        if (rowData.cash && rowData.receivables && rowData.payables) {
            const spread = +((+((rowData.cash as any).replace(/[^0-9]/g, '')) + +((rowData.receivables as any).replace(/[^0-9]/g, '')) - +((rowData.payables as any).replace(/[^0-9]/g, ''))).toFixed(2));
            if (isNaN(spread)) {
                return undefined;
            } else {
                return spread;
            }
        } else {
            return undefined;
        }
    }

    public ngOnInit(): void {
        if (!this.data) {
            this.data = { data: [] };
            this.pending = true;
            this.widgetService.getData('spread')
                .subscribe((data: Spread[]) => {
                    this.data!.data = data.length ? data : [];
                    this.pending = false;
                });
        }
    }
}
