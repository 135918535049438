<div class="wrapper">
    <h3>Comments</h3>
    <div class="input-wrapper flex-row flex-start-center">
        <a routerLink="/profile/{{ userId$ | async }}">
            <img class="avatar" [src]="userAvatar$ | async">
        </a>
        <mat-form-field hintLabel="Max 4000 characters">
            <mat-label>Have any comments or questions?</mat-label>
            <textarea matInput name="comment-input" [(ngModel)]="newCommentText" (keyup)="startComment()"></textarea>
            <mat-hint [class.error]="newCommentText!.length > 4000" align="end">{{ newCommentText.length || 0 }}/4000</mat-hint>
        </mat-form-field>
        <button mat-raised-button class="add-comment-button" [disabled]="isPosting || newCommentText === '' || newCommentText.length > 4000" (click)="addComment()">
            Post
        </button>
    </div>
    <div class="comment-container" *ngIf="comments.length > 0; else noComments">
        <div class="comment flex-row flex-start-start" *ngFor="let comment of comments">
            <a routerLink="/profile/{{ comment.author?.id }}">
                <img class="avatar" [src]="comment?.author?.avatar || 'assets/default-avatar.png'">
            </a>
            <div class="content">
                <div class="header">
                    {{ comment.author?.first_name }} {{ comment.author?.last_name }}.
                    <span class="date">{{ comment.created_on | amTimeAgo }}</span>
                </div>
                <div class="text" [innerHtml]="comment.text"></div>
            </div>
        </div>
    </div>
    <ng-template #noComments>
        <div *ngIf="haveCommentsLoaded; else loading">Be the first to leave a comment!</div>
        <ng-template #loading>
            <div class="loading-wrapper flex-row flex-start-center">
                <span>Loading...</span>
                <mat-spinner diameter="20"></mat-spinner>
            </div>
        </ng-template>
    </ng-template>
</div>