import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { Store } from '@ngrx/store';
import { Subscription} from 'rxjs';
import { map } from 'rxjs/operators';

import { AccessService, TimeoutService, Window } from '../../core';
import { Course, SchoolFeatures, Subject, SubjectType } from '../../core/models';
import { Auth } from '../../root-store';

@Component({
    selector: 'cn-course-display',
    templateUrl: './course-display.component.html',
    styleUrls: ['./course-display.component.css']
})
export class CourseDisplayComponent implements OnChanges, OnDestroy, OnInit {
    @Input() public allowFavorites = true;
    @Input() public autoPlay = false;
    public canAccessStore: boolean;
    @Input() public columns = 2;
    @Output() public courseFinished = new EventEmitter<number>();
    @Output() public courseClicked = new EventEmitter<number>();
    @Input() public courses: Course[] = [];
    public displayCourses: Course[] = [];
    public filteredCourses: Course[] = [];
    public filterPending = false;
    @Input() public grid = false;
    @Input() public hideLibraryButton = false;
    @Input() public order = 'Title (A-Z)';
    @Input() public orderOptions = ['Recently Added', 'Subject (A-Z)', 'Title (A-Z)'];
    @Input() public owned = false;
    public pageIndex = 0;
    public pageSize = 100;
    public selectedSubjects: { [key: number]: Subject[] } = {};
    @Input() public showFilters = true;
    public showOnlyFavorites = false;
    @Input() public standalone = false;
    @Input() public subjects: Subject[];
    public subjectsByType: { [key: number]: Subject[] } = {};
    @Input() public subjectTypes: SubjectType[];

    private sortSubscription: Subscription;

    public get paginatedCourses(): Course[] {
        return this.displayCourses.slice(this.pageIndex * this.pageSize, (this.pageIndex * this.pageSize) + this.pageSize);
    }

    constructor(
        private accessService: AccessService,
        private timeout: TimeoutService,
        private store: Store<Auth.State>,
        @Inject(Window) private window: Window
    ) {}

    public filterCourses(): void {
        this.filterPending = true;

        // Set timeout so there's enough time to display the loading spinner before processing the filters
        this.timeout.setTimeout(() => {
            this.filteredCourses = this.courses.filter((c: Course) => {
                let missingSubject = false;
                if (this.selectedSubjects) {
                    Object.keys(this.selectedSubjects).forEach((selectedType: string) => {
                        let key = Number(selectedType);
                        let hasSubjectType = this.subjects.find((s: Subject) => s?.type === key && (c.subject === s?.id || c.subjects?.includes(s?.id)));
                        let hasSelected = this.selectedSubjects[key].find((s: Subject) => s?.id === c.subject || c.subjects?.includes(s?.id));
                        let isNoneSelected = this.selectedSubjects[key].find((s: Subject) => s?.id === 0);

                        // Don't show any courses if none of the subjects are selected
                        if (!this.selectedSubjects[key].length) {
                            missingSubject = true;
                        }

                        // Don't show a course if it doesn't have a subject of the current type and "No Subjects" is not selected
                        if (!hasSubjectType && !isNoneSelected) {
                            missingSubject = true;
                        }

                        // Don't show a course if it doesn't have a subject that is selected
                        if (!hasSelected && hasSubjectType) {
                            missingSubject = true;
                        }
                    });
                }

                // Make sure the course has a selected subject of each type
                if (missingSubject) {
                    return false;
                }

                // Check if showOnlyFavorites is checked and course is not favorited
                if (this.showOnlyFavorites && !c.favorite) {
                    return false;
                }

                return true;
            });

            this.pageIndex = 0;
            this.sortCourses();
            this.filterPending = false;
        }, 100);
    }

    public fireCourseClicked(courseId: number): void {
        this.courseClicked.emit(courseId);
    }

    public fireFinishedPlaying(courseId: number): void {
        this.courseFinished.emit(courseId);
    }

    public handlePageChange(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.window.scrollTo(0, 0);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.courses || changes.subjects) {
            this.displayCourses = this.filteredCourses = this.courses;
            this.selectedSubjects = {};
            this.subjectsByType = {};

            if (this.subjects) {
                this.subjects.forEach((s: Subject) => {
                    if (!s.type) {
                        s.type = 0;
                    }

                    // Build list of subjects for every subject type
                    if (!this.subjectsByType[s.type]) {
                        this.subjectsByType[s.type] = [];
                    }
                    this.subjectsByType[s.type].push(s);

                    // Build list of subjects that are selected by default for every subject type
                    if (this.courses.find((c: Course) => c.subject === s.id || c.subjects?.includes(s.id))) {
                        if (!this.selectedSubjects[s.type]) {
                            this.selectedSubjects[s.type] = [];
                        }
                        this.selectedSubjects[s.type].push(s);
                    }
                });
            }

            // Add a "No Subjects" option for each subject type if there are multiple types
            if (this.subjectTypes && this.subjectTypes.length > 1) {
                this.subjectTypes.forEach((st: SubjectType) => {
                    let noSubject: Subject = { id: 0, title: `No ${st.title}`, color: "#000000" };
                    this.subjectsByType[st.id] = this.subjectsByType[st.id] ? [noSubject, ...this.subjectsByType[st.id]] : [noSubject];
                    this.selectedSubjects[st.id] = this.selectedSubjects[st.id] ? [noSubject, ...this.selectedSubjects[st.id]] : [noSubject];
                });
            }

            this.sortCourses();
        }
    }

    public ngOnDestroy(): void {
        if (this.sortSubscription) {
            this.sortSubscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.accessService.canAccessStore()
            .subscribe(
                (canAccessStore: boolean) => {
                    this.canAccessStore = canAccessStore;
                },
                (err: any) => {
                    this.canAccessStore = false;
                }
            );
    }

    public sortCourses(): void {
        if (this.sortSubscription) {
            this.sortSubscription.unsubscribe();
        }

        this.store.select(Auth.selectSchoolFeatures).pipe(
            map((features: SchoolFeatures) => {
                switch (this.order) {
                    case 'Recently Watched': // Owned courses only
                        this.displayCourses = this.filteredCourses.sort((a: Course, b: Course) => {
                            if (features.use_wrapped_courses && !a.wrapped !== !b.wrapped) { // Handle 0 vs false
                                return a.wrapped ? -1 : 1;
                            } else if (a.recent_activity && b.recent_activity) {
                                return a.recent_activity > b.recent_activity ? -1 : 1;
                            } else if (a.recent_activity !== b.recent_activity) { // One has not been started
                                return a.recent_activity ? -1 : 1;
                            } else if (a.created_on && b.created_on) {
                                return a.created_on > b.created_on ? -1 : 1;
                            }

                            return 0;
                        });
                        break;
                    case 'Recently Added':
                        this.displayCourses = this.filteredCourses.sort((a: Course, b: Course) => {
                            if (features.use_wrapped_courses && !a.wrapped !== !b.wrapped) {
                                return a.wrapped ? -1 : 1;
                            } else if (a.created_on && b.created_on) { // Owned courses
                                return a.created_on > b.created_on ? -1 : 1;
                            } else if (a.live_on && b.live_on) { // Unowned courses
                                return a.live_on > b.live_on ? -1 : 1;
                            }

                            return 0;
                        });
                        break;
                    case 'Subject (A-Z)':
                        this.displayCourses = this.filteredCourses.sort((a: Course, b: Course) => {
                            if (features.use_wrapped_courses && !a.wrapped !== !b.wrapped) {
                                return a.wrapped ? -1 : 1;
                            } else if (a.subject === b.subject) {
                                return a.title > b.title ? 1 : -1;
                            } else {
                                return a.subject_title > b.subject_title ? 1 : -1;
                            }
                        });
                        break;
                    case 'Title (A-Z)':
                        this.displayCourses = this.filteredCourses.sort((a: Course, b: Course) => {
                            if (features.use_wrapped_courses && !a.wrapped !== !b.wrapped) {
                                return a.wrapped ? -1 : 1;
                            } else {
                                return a.title > b.title ? 1 : -1;
                            }
                        });
                        break;
                    case 'Most Popular':
                        this.displayCourses = this.filteredCourses.sort((a: Course, b: Course) => {
                            if (features.use_wrapped_courses && !a.wrapped !== !b.wrapped) {
                                return a.wrapped ? -1 : 1;
                            } else if (a.rating && b.rating) {
                                return a.rating > b.rating ? -1 : 1;
                            } else if (a.rating !== b.rating) { // One has not been rated
                                return a.rating ? -1 : 1;
                            } else if (a.created_on && b.created_on) { // Owned courses
                                return a.created_on > b.created_on ? -1 : 1;
                            } else if (a.live_on && b.live_on) { // Unowned courses
                                return a.live_on > b.live_on ? -1 : 1;
                            }

                            return 0;
                        });
                        break;
                    case 'Unwatched':
                        this.displayCourses = this.filteredCourses.sort((a: Course, b: Course) => {
                            // Wrapped courses first
                            if (features.use_wrapped_courses && !a.wrapped !== !b.wrapped) { // Handle 0 vs false
                                return a.wrapped ? -1 : 1;
                            }

                            // Then incomplete courses
                            const aCompletion = a.completion || 0;
                            const bCompletion = b.completion || 0;
                            if (aCompletion !== bCompletion) {
                                return aCompletion < bCompletion ? -1 : 1;
                            }

                            // Then assigned date
                            if (a.created_on && b.created_on) {
                                return a.created_on > b.created_on ? -1 : 1;
                            }

                            return 0;
                        });
                        break;
                    default:
                        break;
                }

                // If we are using the slide feature, limit to the first 20 courses
                if (!this.grid) {
                    this.displayCourses = this.displayCourses.slice(0, 20);
                }

                this.pageIndex = 0;
            })
        ).subscribe();
    }

    public trackById(index: number, course: Course): number {
        return course.id;
    }
}
