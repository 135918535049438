import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Config, IConfig } from '../config/config.model';
import { School, SchoolDomain } from '../models';

@Injectable({
    providedIn: 'root'
})
export class SchoolService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public createDomain(schoolId: number, domain: string): Observable<SchoolDomain> {
        return this.http.post<SchoolDomain>(`${this.apiUrl}/admin/schools/${schoolId}/domains`, { domain });
    }

    public deleteDomain(schoolId: number, domainId: number): Observable<void> {
        return this.http.delete<undefined>(`${this.apiUrl}/admin/schools/${schoolId}/domains/${domainId}`);
    }

    public getAll(): Observable<School[]> {
        return this.http.get<School[]>(`${this.apiUrl}/schools`);
    }

    public getAllDomains(schoolId: number): Observable<SchoolDomain[]> {
        return this.http.get<SchoolDomain[]>(`${this.apiUrl}/admin/schools/${schoolId}/domains`);
    }

    public getOne(schoolId: number): Observable<School> {
        return this.http.get<School>(`${this.apiUrl}/schools/${schoolId}`);
    }

    public updateAuthBackground(schoolId: number, formData: FormData): Observable<string> {
        return this.http.put<string>(`${this.apiUrl}/admin/schools/${schoolId}/auth-background`, formData);
    }

    public updateDarkLogo(schoolId: number, formData: FormData): Observable<string> {
        return this.http.put<string>(`${this.apiUrl}/admin/schools/${schoolId}/dark-logo`, formData);
    }

    public updateDefaultNetworkLogo(schoolId: number, formData: FormData): Observable<string> {
        return this.http.put<string>(`${this.apiUrl}/admin/schools/${schoolId}/default-network-logo`, formData);
    }

    public updateFavicon(schoolId: number, formData: FormData): Observable<string> {
        return this.http.put<string>(`${this.apiUrl}/admin/schools/${schoolId}/favicon`, formData);
    }

    public updateLightLogo(schoolId: number, formData: FormData): Observable<string> {
        return this.http.put<string>(`${this.apiUrl}/admin/schools/${schoolId}/light-logo`, formData);
    }

    public updateOne(school: Partial<School>): Observable<void> {
        return this.http.put<undefined>(`${this.apiUrl}/admin/schools/${school.id}`, school);
    }
}