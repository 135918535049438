<cn-widget-card>
    <ng-container class="title">Visual Ambition</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <div class="content">
        <div *ngIf="loaded; else loading">
            <div *ngIf="data.length > 0; else noPosters">
                <div *ngFor="let poster of data" class="poster flex-row flex-space-between-center">
                    <a class="link" [routerLink]="['/tools', 'visual-ambition', poster.name]">
                        <div class="title-wrapper flex-row flex-start-center">
                            <div class="thumbnail">
                                <img src="assets/visual-ambition-thumbnail.png">
                            </div>
                            <div class="title">
                                {{ poster.data.name }} ({{ poster.data.year }})
                            </div>
                        </div>
                    </a>
                    <a class="print" [routerLink]="['/tools', 'visual-ambition', poster.name, 'print']">
                        <mat-icon>print</mat-icon>
                    </a>
                </div>
                <div class="bottom"><a routerLink="/tools/visual-ambition">View All Goal Posters</a> or <a (click)="createPoster()">Create a New Poster</a></div>
            </div>
            <ng-template #noPosters>
                <img class="logo" src="assets/visual-ambition-pink.png">
                <div>Set your business goals and print a poster to hang in your office!</div>
                <div><a (click)="createPoster()">Create Your First Poster!</a></div>
            </ng-template>
        </div>
        <ng-template #loading>
            Loading... <mat-spinner diameter="25"></mat-spinner>
        </ng-template>
    </div>
</cn-widget-card>