import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { Route } from '@angular/router';

import { RoleGuard } from '../role/role.guard';

/**
 * This class should never be imported/exported by a module.
 * Instead, it should be extended, and the routes should be provided by the module that declares the subclass
 */
@Directive()
export abstract class ShowLinkDirective implements OnInit {
    public abstract destUrl: string;

    private el: HTMLElement;
    private visibleDisplay: string;

    constructor(
        el: ElementRef,
        private roleGuard: RoleGuard,
        @Inject('routes') protected routes: Route[]
    ) {
        this.el = el.nativeElement;
        this.visibleDisplay = this.el.style.display || 'block';
    }

    public ngOnInit(): void {
        // Find the relevant path in the routes array
        const route = this.findRoute(this.destUrl, this.routes);

        // Get the list of roles in its data field, if it has RoleGuard in its canActivate array
        if (route && route.canActivate && route.canActivate.find((f: any) => f.name === 'RoleGuard') && route.data && route.data.roles) {
            // Check roleGuard.hasAccess with those roles
            this.roleGuard.hasAccess(route.data.roles, route.data.networks)
                .subscribe((hasAccess: boolean) => {
                    // If we have access, set the display to its given value. If not, hide it
                    this.el.style.display = hasAccess ? this.visibleDisplay : 'none';
                });
        }
    }

    private findRoute(searchPath: string, routes: Route[], accPath: string = ''): Route | null {
        // Override to allow for routerLinks to the root of the module (for example, the Admin dashboard)
        if (searchPath === './') {
            searchPath = '';
        }

        for (const route of routes) {
            if (route.children) {
                // If the route has children, we want to first search its children to find a matching path, if one exists
                const foundRoute = this.findRoute(searchPath, route.children, accPath ? `${accPath}/${route.path}` : route.path);

                if (foundRoute) {
                    // We found a matching route, return it
                    return foundRoute;
                }
            } else if (searchPath === (accPath ? `${accPath}/${route.path}` : route.path)) {
                // Route has no children, so check if this path (the full path, including any parents') matches what we're looking for
                return route;
            }
        }

        // No routes matched
        return null;
    }
}