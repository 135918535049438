import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ContentChild, EventEmitter, HostListener, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';

import { curveLinear } from 'd3';
import { scaleLinear, scalePoint, scaleTime } from 'd3-scale';

import { BaseChartComponent, ColorHelper, LineSeriesComponent, LegendPosition, Orientation, ScaleType, ViewDimensions, calculateViewDimensions, getScaleType, getUniqueXDomainValues } from '@swimlane/ngx-charts';

@Component({
    selector: 'cn-dual-axis-chart',
    templateUrl: './dual-axis-chart.component.html',
    styleUrls: ['./dual-axis-chart.component.css'],
    animations: [
        trigger('animationState', [
            transition(':leave', [
                style({
                    opacity: 1
                }),
                animate(500, style({
                    opacity: 0
                }))
            ])
        ])
    ]
})
export class DualAxisChartComponent extends BaseChartComponent implements OnChanges {
    @Output() public activate: EventEmitter<any> = new EventEmitter();
    @Input() public activeEntries: any[] = [];
    public areaPath: any;
    @Input() public autoScale: any;
    public colors: ColorHelper;
    public combinedSeries: any[];
    @Input() public curve: any = curveLinear;
    @Output() public deactivate: EventEmitter<any> = new EventEmitter();
    public dims: ViewDimensions;
    public filteredDomain: any;
    @Input() public gradient: boolean;
    public hasRange: boolean;
    @Input() public height: number;
    public hoveredVertical: any;
    @Input() public legend: any;
    public legendOptions: any;
    @Input() public legendPosition = LegendPosition.Right;
    @Input() public legendTitle = 'Legend';
    @ViewChild(LineSeriesComponent) public lineSeriesComponent: LineSeriesComponent;
    public margin = [10, 20, 10, 20];
    public Orientation = Orientation;
    @Input() public rangeFillOpacity: number;
    @Input() public referenceLines: any;
    @Input() public resultsRight?: any[];
    public rightAxisSpacing = 0;
    @Input() public roundDomains = false;
    public scaleType: ScaleType;
    @Input() public schemeType: ScaleType;
    public series: any;
    public seriesDomain: any;
    @ContentChild('seriesTooltipTemplate') public seriesTooltipTemplate: TemplateRef<any>;
    @Input() public showGridLines = true;
    @Input() public showRefLabels = true;
    @Input() public showRefLines = false;
    @Input() public showXAxisLabel: any;
    @Input() public showYAxisLabel: any;
    @Input() public timeline: any;
    public timelineHeight = 50;
    public timelinePadding = 10;
    public timelineTransform: any;
    public timelineWidth: any;
    public timelineXDomain: any;
    public timelineXScale: any;
    public timelineYScale: any;
    public timelineYScaleRight: any;
    @Input() public tooltipDisabled = false;
    @ContentChild('tooltipTemplate') public tooltipTemplate: TemplateRef<any>;
    public transform: string;
    @Input() public width: number;
    @Input() public xAxis: any;
    public xAxisHeight = 0;
    @Input() public xAxisLabel: any;
    @Input() public xAxisTickFormatting: any;
    @Input() public xAxisTicks: any[];
    public xDomain: any;
    public xScale: any;
    @Input() public xScaleMax: any;
    @Input() public xScaleMin: any;
    public xSet: any;
    @Input() public yAxis: any;
    @Input() public yAxisLabel: any;
    @Input() public yAxisLabelRight: any;
    @Input() public yAxisRight: any;
    @Input() public yAxisTickFormatting: any;
    @Input() public yAxisTickFormattingRight: any;
    @Input() public yAxisTicks: any[];
    @Input() public yAxisTicksRight: any[];
    public yAxisWidth = 0;
    public yDomain: any;
    public yDomainRight: any;
    public yScale: any;
    public yScaleCombined: any;
    @Input() public yScaleMax: number;
    @Input() public yScaleMin: number;
    public yScaleRight: any;

    public deactivateAll(): void {
        this.activeEntries = [...this.activeEntries];
        for (const entry of this.activeEntries) {
            this.deactivate.emit({ value: entry, entries: [] });
        }
        this.activeEntries = [];
    }

    public getLegendOptions(): any {
        const opts: any = {
            scaleType: this.schemeType,
            colors: undefined,
            domain: [],
            title: undefined,
            position: this.legendPosition
        };
        if (opts.scaleType === 'ordinal') {
            opts.domain = this.seriesDomain;
            opts.colors = this.colors;
            opts.title = this.legendTitle;
        } else {
            opts.domain = this.seriesDomain;
            opts.colors = this.colors.scale;
        }

        return opts;
    }

    public getSeriesDomain(): any[] {
        this.combinedSeries = this.results.concat(this.resultsRight);

        return this.combinedSeries.map((d: any) => d.name);
    }

    public getXDomain(): any[] {
        let values = getUniqueXDomainValues(this.results);

        this.scaleType = getScaleType(values);
        let domain = [];

        if (this.scaleType === 'linear') {
            values = values.map(Number);
        }

        let min;
        let max;
        if (this.scaleType === 'time' || this.scaleType === 'linear') { // eslint-disable-line 
            min = this.xScaleMin
                ? this.xScaleMin
                : Math.min(...values);

            max = this.xScaleMax
                ? this.xScaleMax
                : Math.max(...values);
        }

        switch (this.scaleType) {
            case 'time':
                domain = [new Date(min), new Date(max)];
                this.xSet = [...values].sort((a: any, b: any) => {
                    const aDate = a.getTime();
                    const bDate = b.getTime();

                    return aDate > bDate ? 1 : bDate > aDate ? -1 : 0;
                });
                break;
            case 'linear':
                domain = [min, max];
                // Use compare function to sort numbers numerically
                this.xSet = [...values].sort((a: any, b: any) => (a - b));
                break;
            default:
                domain = values;
                this.xSet = values;
                break;
        }

        return domain;
    }

    public getXScale(domain: any, width: any): any {
        let scale;

        switch (this.scaleType) {
            case 'time':
                scale = scaleTime()
                    .range([0, width])
                    .domain(domain);
                break;
            case 'linear':
                scale = scaleLinear()
                    .range([0, width])
                    .domain(domain);

                if (this.roundDomains) {
                    scale = scale.nice();
                }
                break;
            case 'ordinal':
            default:
                scale = scalePoint()
                    .range([0, width])
                    .padding(0.1)
                    .domain(domain);
                break;
        }

        return scale;
    }

    public getYDomain(right?: 'right'): any[] {
        const domain = [];
        if (right && this.resultsRight) {
            for (const results of this.resultsRight) {
                for (const d of results.series) {
                    if (domain.indexOf(d.value) < 0) {
                        domain.push(d.value);
                    }
                    if (d.min !== undefined) {
                        this.hasRange = true;
                        if (domain.indexOf(d.min) < 0) {
                            domain.push(d.min);
                        }
                    }
                    if (d.max !== undefined) {
                        this.hasRange = true;
                        if (domain.indexOf(d.max) < 0) {
                            domain.push(d.max);
                        }
                    }
                }
            }
        } else {
            for (const results of this.results) {
                for (const d of results.series) {
                    if (domain.indexOf(d.value) < 0) {
                        domain.push(d.value);
                    }
                    if (d.min !== undefined) {
                        this.hasRange = true;
                        if (domain.indexOf(d.min) < 0) {
                            domain.push(d.min);
                        }
                    }
                    if (d.max !== undefined) {
                        this.hasRange = true;
                        if (domain.indexOf(d.max) < 0) {
                            domain.push(d.max);
                        }
                    }
                }
            }
        }

        const values = [...domain];
        if (!this.autoScale) {
            values.push(0);
        }

        const min = this.yScaleMin
            ? this.yScaleMin
            : Math.min(...values);

        const max = this.yScaleMax
            ? this.yScaleMax
            : Math.max(...values);

        return [min, max];
    }

    public getYScale(domain: any, height: any): any {
        const scale = scaleLinear()
            .range([height, 0])
            .domain(domain);

        return this.roundDomains ? scale.nice() : scale;
    }

    @HostListener('mouseleave')
    public hideCircles(): void {
        this.hoveredVertical = null;
        this.deactivateAll();
    }

    public onActivate(item: any): any {
        this.deactivateAll();

        const idx = this.activeEntries.findIndex((d: any) => d.name === item.name && d.value === item.value);
        if (idx > -1) {
            return;
        }

        this.activeEntries = [item];
        this.activate.emit({ value: item, entries: this.activeEntries });
    }

    public onClick(data: any, series?: any): void {
        if (series) {
            data.series = series.name;
        }

        this.select.emit(data);
    }

    public onDeactivate(item: any): void {
        const idx = this.activeEntries.findIndex((d: any) => d.name === item.name && d.value === item.value);

        this.activeEntries.splice(idx, 1);
        this.activeEntries = [...this.activeEntries];

        this.deactivate.emit({ value: item, entries: this.activeEntries });
    }

    public setColors(): void {
        const domain = this.schemeType === 'ordinal' ? this.seriesDomain : this.yDomain;

        this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    }

    public trackBy(index: number, item: any): string {
        return item.name;
    }

    public update(): void {
        super.update();

        this.dims = calculateViewDimensions({
            width: this.width,
            height: this.height,
            margins: this.margin,
            showXAxis: this.xAxis,
            showYAxis: this.yAxis,
            xAxisHeight: this.xAxisHeight,
            yAxisWidth: this.yAxisWidth,
            showXLabel: this.showXAxisLabel,
            showYLabel: this.showYAxisLabel,
            showLegend: this.legend,
            legendType: this.schemeType,
            legendPosition: this.legendPosition
        });

        this.rightAxisSpacing = this.showYAxisLabel && this.yAxis && this.yAxisRight && (!this.legend || this.legendPosition === 'below') ? 50 : 0;

        if (this.timeline) {
            this.dims.height -= (this.timelineHeight + this.margin[2] + this.timelinePadding);
        }

        this.xDomain = this.getXDomain();
        if (this.filteredDomain) {
            this.xDomain = this.filteredDomain;
        }

        this.yDomain = this.getYDomain();
        this.yDomainRight = this.getYDomain('right');
        this.seriesDomain = this.getSeriesDomain();

        this.xScale = this.getXScale(this.xDomain, this.dims.width);
        this.yScale = this.getYScale(this.yDomain, this.dims.height);
        this.yScaleRight = this.getYScale(this.yDomainRight, this.dims.height);
        this.yScaleCombined = this.yDomain[1] > this.yDomainRight[1] ? this.yScale : this.yScaleRight;

        this.updateTimeline();

        this.setColors();
        this.legendOptions = this.getLegendOptions();

        this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
    }

    public updateDomain(domain: any): void {
        this.filteredDomain = domain;
        this.xDomain = this.filteredDomain;
        this.xScale = this.getXScale(this.xDomain, this.dims.width);
    }

    public updateHoveredVertical(item: any): void {
        this.hoveredVertical = item.value;
        this.deactivateAll();
    }

    public updateTimeline(): void {
        if (this.timeline) {
            this.timelineWidth = this.dims.width;
            this.timelineXDomain = this.getXDomain();
            this.timelineXScale = this.getXScale(this.timelineXDomain, this.timelineWidth);
            this.timelineYScale = this.getYScale(this.yDomain, this.timelineHeight);
            this.timelineYScaleRight = this.getYScale(this.yDomainRight, this.timelineHeight);
            this.timelineTransform = `translate(${this.dims.xOffset}, ${-this.margin[2]})`;
        }
    }

    public updateXAxisHeight({ height }: any): void {
        this.xAxisHeight = height;
        this.update();
    }

    public updateYAxisWidth({ width }: any): void {
        this.yAxisWidth = width;
        this.update();
    }
}