import { Component, OnInit } from '@angular/core';

import { WidgetService } from '../../core';
import { PersonalityQuizResults } from '../../core/models';

@Component({
    selector: 'cn-team-strength-widget',
    templateUrl: './team-strength-widget.component.html',
    styleUrls: ['./team-strength-widget.component.css']
})
export class TeamStrengthWidgetComponent implements OnInit {

    public barEnd: number;
    public data: PersonalityQuizResults[];
    public noResults = false;
    public pending = false;
    public teamnumbers = [{ title: 'Director', value: 0 }, { title: 'Relater', value: 0 }, { title: 'Socializer', value: 0 }, { title: 'Thinker', value: 0 }];

    constructor(
        private widgetService: WidgetService
    ) { }

    public buildTeamData(): void {
        const teamnumbers = [{ title: 'Director', value: 0 },
        { title: 'Relater', value: 0 },
        { title: 'Socializer', value: 0 },
        { title: 'Thinker', value: 0 }];

        this.data.forEach((eachEmployee: PersonalityQuizResults) => {
            switch (eachEmployee.primary) {
                case 'Relater':
                    teamnumbers[1].value += 1;
                    break;
                case 'Socializer':
                    teamnumbers[2].value += 1;
                    break;
                case 'Thinker':
                    teamnumbers[3].value += 1;
                    break;
                default:
                    teamnumbers[0].value += 1;
                    break;
            }
        });

        teamnumbers.sort((a: { title: string; value: number }, b: { title: string; value: number }) => b.value - a.value);
        this.barEnd = teamnumbers[0].value + 1;
        this.teamnumbers = teamnumbers;
    }

    public ngOnInit(): void {
        this.pending = true;

        this.widgetService.getData('team-strength')
            .subscribe((data: PersonalityQuizResults[]) => {
                this.data = data;
                if (this.data.length) {
                    this.data = this.data.filter((eachEmployee: PersonalityQuizResults) => !eachEmployee.deleted);
                    this.buildTeamData();
                }
                if (!this.data.length) {
                    this.noResults = true;
                }
                this.pending = false;
            });
    }
}
