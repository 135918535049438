import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { ThinkDailyGuard } from './services/think-daily/think-daily.guard';

import { ThinkDailyComponent } from './think-daily/think-daily.component';

const routes: Route[] = [
    {
        path: 'think-daily',
        canActivateChild: [ThinkDailyGuard],
        children: [
            { path: '', component: ThinkDailyComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThinkDailyRoutingModule {}