import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CourseService, StandaloneLinkService } from '../../core';
import { Content, Course, SchoolFeatures, UserPlaylist } from '../../core/models';
import { Auth, Playlist, Theme } from '../../root-store';
import { UnwrapCourseDialogComponent } from '../../shared/unwrap-course-dialog/unwrap-course-dialog.component';

import { CoursePlayerComponent } from '../course-player/course-player.component';

@Component({
    selector: 'cn-course-card',
    templateUrl: './course-card.component.html',
    styleUrls: ['./course-card.component.css']
})
export class CourseCardComponent {
    @Input() public allowFavorites = true;
    @Input() public autoPlay = false; // Force to always autoplay
    public autoPlay$: Observable<boolean>; // School feature for if it should autoplay only if there's only one piece content
    @Output() public clicked = new EventEmitter();
    @Input() public course: Course;
    @Output() public finishedPlaying = new EventEmitter();
    @Input() public hideLibraryButton = false;
    @Input() public owned = false;
    public playlists$: Observable<UserPlaylist[]>;
    public showCourseIds$: Observable<boolean>;
    public showPlaylists$: Observable<boolean>;
    @Input() public standalone = false;
    public wrapCourses$: Observable<boolean | undefined>;

    constructor(
        private courseService: CourseService,
        private standaloneLinkService: StandaloneLinkService,
        private store: Store<Theme.State>,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.wrapCourses$ = this.store.select(Theme.selectWrapCourses);
        this.autoPlay$ = this.store.select(Auth.selectSchoolFeatures).pipe(map((features: SchoolFeatures) => features.auto_play_single_content_courses));
        this.showCourseIds$ = this.store.select(Auth.selectSchoolFeatures).pipe(map((features: SchoolFeatures) => features.show_course_ids));
        this.showPlaylists$ = this.store.select(Auth.selectSchoolFeatures).pipe(map((features: SchoolFeatures) => features.use_playlists));
        this.playlists$ = this.store.select(Playlist.selectUserPlaylists);
    }

    public addCourseToPlaylist(playlist: UserPlaylist): void {
        const playlistCourses = playlist.courses || [];
        this.store.dispatch(new Playlist.Actions.EditUserPlaylist({ playlist, courses: [...playlistCourses, this.course] }));
    }

    public createPlaylistWithCourse(): void {
        this.store.dispatch(new Playlist.Actions.CreateUserPlaylist({ playlist: { title: 'New Playlist' }, courses: [this.course.id] }));
    }

    public getValidPlaylists(playlists: UserPlaylist[] | null): UserPlaylist[] {
        if (!playlists) {
            return [];
        }

        return playlists.filter((p: UserPlaylist) => !p.courses || p.courses.findIndex((c: Course) => c.id === this.course.id) === -1);
    }

    public handleNavigation(): void {
        this.clicked.emit();

        this.autoPlay$.pipe(
            take(1)
        ).subscribe({
            next: (autoPlay: boolean) => {
                if (this.autoPlay || (autoPlay && this.course.total_content === 1)) {
                    this.watch();
                } else {
                    this.router.navigate(['/library', 'course', this.course.id]);
                }
            }
        });
    }

    public toggleFavorite(): void {
        // If this course card shouldn't have favorites, ignore the request
        if (!this.allowFavorites) {
            return;
        }

        // Change the value right away to make it appear to work instantaneously
        const previousValue = this.course.favorite;
        this.course.favorite = !this.course.favorite;

        const favorite$ = previousValue ?
            this.courseService.unfavoriteCourse(this.course.id) :
            this.courseService.favoriteCourse(this.course.id);

        favorite$.subscribe({
            error: () => {
                // Favorite wasn't saved, so revert back to the previous value
                this.course.favorite = previousValue;
                this.snackbar.open(`There was an error, please try again`, undefined, { duration: 3000 });
            }
        });
    }

    public unwrap(): void {
        const dialogRef = this.dialog.open(UnwrapCourseDialogComponent, { data: { course: this.course }, panelClass: 'overflowing-modal' });
        dialogRef.afterClosed().subscribe((shouldWatch: boolean) => {
            this.courseService.unwrapCourse(this.course.id)
                .subscribe(() => {
                    this.course.wrapped = false;
                    if (shouldWatch) {
                        this.watch();
                    } else {
                        this.dialog.closeAll();
                    }
                });
        });
    }

    public watch(): void {
        const obs$ = this.standalone ?
            this.standaloneLinkService.getCourseContent(this.course.id, this.route.snapshot.queryParams.t) :
            this.courseService.getCourseContent(this.course.id);

        obs$.subscribe((contentList: Content[]) => {
            const coursePlayerDialogRef = this.dialog.open(CoursePlayerComponent, { disableClose: true, panelClass: 'fullscreen-modal', data: { hideLibraryButton: this.hideLibraryButton} });
            coursePlayerDialogRef.componentInstance.activeContentIndex = 0;
            coursePlayerDialogRef.componentInstance.mediaContent = contentList;
            coursePlayerDialogRef.componentInstance.course = this.course;
            coursePlayerDialogRef.componentInstance.standalone = this.standalone;
            coursePlayerDialogRef.afterClosed().subscribe(() => {
                // Wrap in a try/catch to ignore errors caused by courses in ngrx
                try {
                    this.course.completed_content = contentList.reduce((acc: number, c: Content) => {
                        if (c.is_completed) {
                            acc++;
                        }

                        return acc;
                    }, 0);
                } catch (e) {
                    // Do nothing
                }

                this.finishedPlaying.emit();

                this.dialog.closeAll();
            });
        });
    }
}
