import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IntervalService {
    private interval: NodeJS.Timeout;

    public clearInterval(): void {
        clearInterval(this.interval);
    }

    public setInterval(time: number, callback: () => void): void {
        this.interval = setInterval(callback, time);
    }
}
