import { Injectable } from '@angular/core';

import confetti from 'canvas-confetti';

// More effect samples: https://www.kirilv.com/canvas-confetti/
@Injectable({
	providedIn: 'root'
})
export class ConfettiService {
    private activeConfettiInterval: NodeJS.Timeout | undefined;
    private canvas: any;

    public fireworks(duration: number | null = null, canvas?: any): void {
        this.stop();

        const animationEnd = duration ? Date.now() + duration : 0;
        const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min: number, max: number): number {
            return Math.random() * (max - min) + min;
        }

        if (canvas) {
            this.canvas = canvas;
            this.canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true, useWorker: true });
        }

        this.activeConfettiInterval = setInterval(() => {
            let timeLeft = 0;
            if (duration) {
                timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    this.stop();
                }
            }

            const particleCount = duration ? 50 * (timeLeft / duration) : 50;

            if (this.canvas) {
                this.canvas.confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }, zIndex: 101 });
                this.canvas.confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }, zIndex: 101 });
            } else {
                confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }, zIndex: 101 });
                confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }, zIndex: 101 });
            }
        }, 250);
    }

    public stop(): void {
        if (this.activeConfettiInterval) {
            // Stop any intervals that would create more confetti
            clearInterval(this.activeConfettiInterval);
            this.activeConfettiInterval = undefined;

            // Stop all active confetti
            if (this.canvas) {
                this.canvas.confetti.reset();
                this.canvas = undefined;
            } else {
                confetti.reset();
            }
        }
    }
}