import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Course, Content } from '../models';
import { Config, IConfig } from '../config/config.model';

@Injectable({
    providedIn: 'root'
})
export class StandaloneLinkService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public getCourse(courseId: number, token: string): Observable<Course> {
        return this.http.get<Course>(`${this.apiUrl}/standalone-link/courses/${courseId}?token=${token}`);
    }

    public getCourseContent(courseId: number, token: string): Observable<Content[]> {
        return this.http.get<Content[]>(`${this.apiUrl}/standalone-link/courses/${courseId}/content?token=${token}`);
    }

    public getCourses(token: string): Observable<Course[]> {
        return this.http.get<Course[]>(`${this.apiUrl}/standalone-link/courses?token=${token}`);
    }
}