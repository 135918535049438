import { UserPlaylist } from '../../core/models';

import { ActionTypes, PlaylistActions } from './playlist.actions';
import { State, initialState } from './playlist.state';

export function reducer(state: State = initialState, action: PlaylistActions): State {
    switch (action.type) {
        case ActionTypes.GET_CN_PLAYLISTS_SUCCESS: {
            return {
                ...state,
                cn_playlists: action.payload.playlists,
                cn_loaded: true
            };
        }
        case ActionTypes.GET_CN_PLAYLISTS_FAILURE: {
            return {
                ...state,
                cn_loaded: true
            };
        }
        case ActionTypes.GET_USER_PLAYLISTS_SUCCESS: {
            return {
                ...state,
                user_playlists: action.payload.playlists,
                user_loaded: true
            };
        }
        case ActionTypes.GET_USER_PLAYLISTS_FAILURE: {
            return {
                ...state,
                user_loaded: true
            };
        }
        case ActionTypes.CREATE_USER_PLAYLIST_SUCCESS: {
            return {
                ...state,
                user_playlists: [...state.user_playlists, action.payload]
            };
        }
        case ActionTypes.EDIT_USER_PLAYLIST_SUCCESS: {
            const playlistIndex = state.user_playlists.findIndex((p: UserPlaylist) => p.id === action.payload.playlist.id);

            return {
                ...state,
                user_playlists: [
                    ...state.user_playlists.slice(0, playlistIndex),
                    {
                        ...action.payload.playlist,
                        courses: [...action.payload.courses]
                    },
                    ...state.user_playlists.slice(playlistIndex + 1)
                ]
            };
        }
        case ActionTypes.DELETE_USER_PLAYLIST_SUCCESS: {
            const playlistIndex = state.user_playlists.findIndex((p: UserPlaylist) => p.id === action.payload.playlistId);

            return {
                ...state,
                user_playlists: [
                    ...state.user_playlists.slice(0, playlistIndex),
                    ...state.user_playlists.slice(playlistIndex + 1)
                ]
            };
        }
        default: {
            return state;
        }
    }
}