<cn-widget-card>
    <ng-container class="title">Sales Backlog in Days</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <div class="content">
        <div class="chart-wrapper" *ngIf="!pending; else loading">
            <cn-table-chart [chartHeight]="222" [chartOnly]="true" [calculatedColumns]="calculatedColumns"
                [colorScheme]="colors" [dataObject]="data" [displayedColumns]="displayedColumns" [dollars]="false"
                yAxisLabel="Days Behind"></cn-table-chart>
        </div>
        <a *ngIf="showLink" routerLink="/tools/sales-backlog">Add/Edit Sales Backlog Data</a>
        <ng-template #loading>
            Loading your data... <mat-spinner diameter="25"></mat-spinner>
        </ng-template>
    </div>
</cn-widget-card>