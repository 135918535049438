<cn-widget-card>
    <ng-container class="title">Cash Calculator</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <ng-container class="content">
        <span class="input">
            <mat-form-field class="leads-input">
                <mat-label>Leads</mat-label>
                <input matInput name="leads" [(ngModel)]="widgetData['leads']" (change)="update()">
            </mat-form-field>
        </span>
        <span class="operator">x</span>
        <span class="input">
            <mat-form-field class="closing-ratio-input">
                <mat-label>Closing Ratio</mat-label>
                <input matInput name="closing-ratio" [(ngModel)]="widgetData['closing_ratio']" (change)="update()">
                <span matSuffix>%</span>
            </mat-form-field>
        </span>
        <span class="operator">x</span>
        <span class="input">
            <mat-form-field class="avg-sale-input">
                <mat-label>Avg. Sale</mat-label>
                <input matInput name="avg-sale" [(ngModel)]="widgetData['avg_sale']" (change)="update()">
                <span matPrefix>$</span>
            </mat-form-field>
        </span>
        <span class="operator">x</span>
        <span class="input">
            <mat-form-field class="sale-customer-input">
                <mat-label>Sales per Customer</mat-label>
                <input matInput name="sale-customer" [(ngModel)]="widgetData['sale_per_customer']" (change)="update()">
            </mat-form-field>
        </span>
        <span class="operator">=</span>
        <span class="input">
            <mat-form-field class="total-sales-input">
                <mat-label>Total Sales</mat-label>
                <input matInput disabled name="total-sales" [ngModel]="totalSales" (change)="update()">
                <span matPrefix>$</span>
            </mat-form-field>
        </span>
        <span class="operator">x</span>
        <span class="input">
            <mat-form-field class="net-margin-input">
                <mat-label>Net Margin</mat-label>
                <input matInput name="net-margin" [(ngModel)]="widgetData['net_margin']" (change)="update()">
                <span matSuffix>%</span>
            </mat-form-field>
        </span>
        <span class="operator">=</span>
        <span class="input">
            <mat-form-field class="profit-input">
                <mat-label>Profit</mat-label>
                <input matInput disabled name="profit" [ngModel]="profit" (change)="update()">
                <span matPrefix>$</span>
            </mat-form-field>
        </span>
        <div class="link"><a routerLink="/tools/cash-calculator">Click here for more</a></div>
    </ng-container>
</cn-widget-card>