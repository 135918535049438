import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';
import { ThinkDailyModule } from '../think-daily';

import { BulletinBoardWidgetComponent } from './bulletin-board-widget/bulletin-board-widget.component';
import { CashCalculatorWidgetComponent } from './cash-calculator-widget/cash-calculator-widget.component';
import { CommunityDashboardWidgetComponent } from './community-dashboard-widget/community-dashboard-widget.component';
import { LeadsAdlWidgetComponent } from './leads-adl-widget/leads-adl-widget.component';
import { PersonalGoalWidgetComponent } from './personal-goal-widget/personal-goal-widget.component';
import { ProductionBacklogWidgetComponent } from './production-backlog-widget/production-backlog-widget.component';
import { SalesBacklogWidgetComponent } from './sales-backlog-widget/sales-backlog-widget.component';
import { SpreadWidgetComponent } from './spread-widget/spread-widget.component';
import { StaircaseGoalsWidgetComponent } from './staircase-goals-widget/staircase-goals-widget.component';
import { TeamStrengthWidgetComponent } from './team-strength-widget/team-strength-widget.component';
import { ThinkDailyWidgetComponent } from './think-daily-widget/think-daily-widget.component';
import { VisualAmbitionWidgetComponent } from './visual-ambition-widget/visual-ambition-widget.component';
import { WidgetCardComponent } from './widget-card/widget-card.component';

@NgModule({
    declarations: [
        BulletinBoardWidgetComponent,
        CashCalculatorWidgetComponent,
        CommunityDashboardWidgetComponent,
        LeadsAdlWidgetComponent,
        PersonalGoalWidgetComponent,
        ProductionBacklogWidgetComponent,
        SalesBacklogWidgetComponent,
        SpreadWidgetComponent,
        StaircaseGoalsWidgetComponent,
        TeamStrengthWidgetComponent,
        ThinkDailyWidgetComponent,
        VisualAmbitionWidgetComponent,
        WidgetCardComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ThinkDailyModule
    ],
    exports: [
        BulletinBoardWidgetComponent,
        CashCalculatorWidgetComponent,
        CommunityDashboardWidgetComponent,
        LeadsAdlWidgetComponent,
        PersonalGoalWidgetComponent,
        ProductionBacklogWidgetComponent,
        SalesBacklogWidgetComponent,
        SpreadWidgetComponent,
        StaircaseGoalsWidgetComponent,
        TeamStrengthWidgetComponent,
        ThinkDailyWidgetComponent,
        VisualAmbitionWidgetComponent
    ]
})
export class DashboardWidgetsModule { }