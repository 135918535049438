<mat-dialog-content>
    <div class="playlist-wrapper">
        <mat-drawer-container>
            <mat-drawer-content>
                <div class="media-background flex-column">
                    <div class="media-wrapper flex-row flex-center-center" *ngIf="activeFile">
                        <div class="video-wrapper flex-row flex-center-center">
                            <div class="video" *ngIf="!showCourseCompletion; else courseCompletion">
                                <video id="video-player" class="video-js" #target autoplay="true" preload="auto"></video>
                            </div>

                            <ng-template #courseCompletion>
                                <div class="course-completion">
                                    <h5>You've completed a {{ 'COURSE' | language | async | lowercase }}!</h5>
                                    <ng-container *ngIf="firstTimeCourseComplete; else completedMessage">
                                        <span>Please take a minute to let us know how you enjoyed it.</span>
                                        <div class="stars flex-row flex-center-center">
                                            <mat-icon class="star" *ngFor="let i of [1, 2, 3, 4, 5]" (click)="setRating(i)">
                                                <ng-container *ngIf="i <= courseRating">star</ng-container>
                                                <ng-container *ngIf="i > courseRating">star_border</ng-container>
                                            </mat-icon>
                                        </div>
                                        <mat-form-field class="light comments" [class.hidden]="courseRating === 0" appearance="outline">
                                            <mat-label>Additional Comments?</mat-label>
                                            <textarea matInput name="comments" [(ngModel)]="courseComments"></textarea>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-template #completedMessage>
                                        <p *ngIf="!hideLibraryButton">To continue, go back to {{ 'YOUR_LIBRARY' | language | async | lowercase }} to watch another {{ 'COURSE' | language | async | lowercase }}.</p>
                                        <p *ngIf="hideLibraryButton">To continue, click Close to go back and watch another {{ 'COURSE' | language | async | lowercase }}.</p>
                                    </ng-template>
                                    <div class="actions flex-column flex-row-gt-xs">
                                        <button mat-raised-button *ngIf="!hideLibraryButton" (click)="close(['/library'])">Go to {{ 'YOUR_LIBRARY' | language | async | titlecase }}</button>
                                        <button mat-raised-button *ngIf="hideLibraryButton" (click)="close()">Close</button>
                                        <button mat-raised-button *ngIf="showDashboardButton$ | async" (click)="close(['/'])">Go to Dashboard</button>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="close-playlist" (click)="close()">
                        <mat-icon>close</mat-icon>
                    </div>
                    <div class="media-footer flex-row flex-space-between-center" *ngIf="course && activeContent && screenSize > ScreenSize.sm">
                        <div class="info-container">
                            <ng-container *ngTemplateOutlet="contentInfo"></ng-container>
                        </div>
                        <div class="footer-actions flex-column">
                            <mat-slide-toggle *ngIf="mediaContent.length > 1" class="autoplay-toggle" color="accent" [(ngModel)]="autoplay">Autoplay</mat-slide-toggle>
                            <button mat-button (click)="drawer.toggle()" class="drawer-trigger link">
                                <span *ngIf="!drawer.opened; else closeDrawer" class="flex-row flex-center-center">
                                    <mat-icon>bookmark_border</mat-icon><span>Sidebar</span>
                                </span>
                                <ng-template #closeDrawer>
                                    <span>
                                        <mat-icon>bookmark</mat-icon>
                                        <mat-icon>close</mat-icon>
                                    </span>
                                </ng-template>
                            </button>
                        </div>
                    </div>
                    <ng-template #contentInfo>
                        <div class="content-info flex-row">
                            <div class="thumbnail-wrapper flex-hide flex-show-gt-xs">
                                <img class="thumbnail" [src]="course.thumbnail">
                            </div>
                            <div class="title-wrapper">
                                <div class="title" title="{{ course.title + ': ' + activeContent.title }}">{{ course.title + ': ' + activeContent.title }}</div>
                                <div class="link" *ngIf="!standalone" (click)="close(['/library', 'course', course.id])"><u>{{ 'COURSE' | language | async | titlecase }} Overview</u></div>
                            </div>
                            <div class="subject flex-hide flex-show-gt-xs" [style.background-color]="course.color">{{ course.subject_title }}</div>
                        </div>
                    </ng-template>
                </div>
            </mat-drawer-content>
            <mat-drawer #drawer [opened]="false" [mode]="sidebarMode" [position]="'end'">
                <mat-tab-group>
                    <mat-tab label="Course Playlist">
                        <div>
                            <div *ngIf="course" class="course-header flex-row">
                                <div class="thumbnail-wrapper">
                                    <img class="thumbnail" [src]="course.thumbnail">
                                </div>
                                <div class="title-wrapper">
                                    <div class="title" title="{{ course.title }}">{{ course.title }}</div>
                                    <div *ngIf="course.duration">{{ course.duration | duration: 'h m s' }}</div>
                                </div>
                            </div>
                            <div class="toggle-wrapper" *ngIf="mediaContent.length > 1">
                                <mat-slide-toggle class="autoplay-toggle" color="accent" [(ngModel)]="autoplay">Autoplay</mat-slide-toggle>
                            </div>
                            <div class="playlist-content-wrapper">
                                <div class="playlist-item flex-row flex-start-center" *ngFor="let item of mediaContent; let i = index" (click)="setContent(i, true)">
                                    <div class="icon" [class.white-back]="item.is_completed || item.id === activeContent.id">
                                        <mat-icon>{{ item.id === activeContent.id ? 'more_horiz' : item.is_completed ? 'done' : '' }}</mat-icon>
                                    </div>
                                    <div class="title-wrapper">
                                        <div class="title" title="{{ i + 1 }}. {{ item.title }}">{{ i + 1 }}. {{ item.title }}</div>
                                        <div>{{ (item.duration || 0) | duration: 'h m s' }}</div>
                                    </div>
                                </div>
                                <mat-divider [vertical]="true"></mat-divider>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Comments" *ngIf="!standalone">
                        <div *ngIf="course" class="comments-wrapper">
                            <cn-comments [courseId]="course.id" (currentComment)="getComment($event)"></cn-comments>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-drawer>
        </mat-drawer-container>
    </div>
</mat-dialog-content>