import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StandaloneLinkService } from './standalone-link.service';
import { Course } from '../models';

@Injectable({
    providedIn: 'root'
})
export class StandaloneLinkResolver  {
    constructor(
        private standaloneLinkService: StandaloneLinkService,
        private router: Router
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Course[] | undefined> {
        return this.standaloneLinkService.getCourses(route.queryParams.t).pipe(
            tap((courses: Course[]) => {
                if (!courses || courses.length === 0) {
                    this.router.navigate(['/']);
                }
            })
        );
    }
}