import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Auth } from '../../root-store';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(
        private store: Store<Auth.State>,
        private router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canAccess(state.url);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.canAccess(`/${route.path}`);
    }

    private canAccess(redirectUrl: string): Observable<boolean> {
        return this.store.select(Auth.selectAuth).pipe(
            filter((auth: Auth.State) => !auth.is_loading),
            map((auth: Auth.State) => {
                if (!auth.is_logged_in) {
                    this.router.navigate(['/login'], { queryParams: { redirectTo: redirectUrl } });
                }

                return auth.is_logged_in;
            })
        );
    }
}
