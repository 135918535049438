import { Component, OnInit } from '@angular/core';

import { WidgetService } from '../../core';
import { WidgetData } from '../../core/models';

@Component({
    selector: 'cn-staircase-goals-widget',
    templateUrl: 'staircase-goals-widget.component.html',
    styleUrls: ['staircase-goals-widget.component.css']
})
export class StaircaseGoalsWidgetComponent implements OnInit {
    public frontGoals: any[] = [];

    constructor(
        private widgetService: WidgetService
    ) {}

    public ngOnInit(): void {
        this.widgetService.getData('staircase-goals')
            .subscribe((data: WidgetData) => {
                if (data.front_staircase_goals) {
                    this.frontGoals = data.front_staircase_goals
                        .slice(0, 3)
                        .map((g: any) => g.name);
                }
            });
    }
}