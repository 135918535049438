import { ActionTypes, LanguageActions } from './language.actions';
import { State, initialState } from './language.state';

export function reducer(state: State = initialState, action: LanguageActions): State {
    switch (action.type) {
        case ActionTypes.GET_TOKENS_SUCCESS: {
            return { ...action.payload };
        }
        default: {
            return { ...state };
        }
    }
}