import { Component, Input, OnInit } from '@angular/core';

import { ScaleType } from '@swimlane/ngx-charts';

import { WidgetService } from '../../core';
import { SalesBacklog } from '../../student-tools/models';

@Component({
    selector: 'cn-sales-backlog-widget',
    templateUrl: 'sales-backlog-widget.component.html',
    styleUrls: ['sales-backlog-widget.component.css']
})
export class SalesBacklogWidgetComponent implements OnInit {
    public calculatedColumns = [false, false, false, false, false];
    public colors = { name: 'Sales Backlog', selectable: false, group: ScaleType.Linear, domain: ['#06631C', '#FFA400', '#E9190F', '#000'] };
    @Input() public data: { data: SalesBacklog[]; labels?: string[] };
    public displayedColumns = ['Month', 'Year', 'Sales A', 'Sales B', 'Sales C', 'Sales D'];
    public pending = false;
    @Input() public showLink = false;

    constructor(
        private widgetService: WidgetService
    ) {}

    public ngOnInit(): void {
        if (!this.data) {
            this.data = { data: [] };
            this.pending = true;
            this.widgetService.getData('sales-backlog')
                .subscribe((data: SalesBacklog[] | { data: SalesBacklog[]; labels: string[] }) => {
                    if (data.hasOwnProperty('data')) {
                        this.data = data as { data: SalesBacklog[]; labels: string[] };
                    } else {
                        this.data.data = (data as SalesBacklog[]).length ? (data as SalesBacklog[]) : [];
                        this.data.labels = [];
                    }

                    this.pending = false;
                });
        }
    }
}