<cn-widget-card>
    <ng-container class="title">Your Spread</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <div #content class="content">
        <div class="chart-wrapper" [class.extra-padding]="content.clientWidth < 399" *ngIf="!pending; else loading">
            <cn-table-chart [chartHeight]="300" [chartOnly]="true" [calculate]="getSpread" [calculatedColumns]="calculatedColumns" [colorScheme]="colors" [dataObject]="data!" [displayedColumns]="displayedSpreadColumns" [dollars]="true"></cn-table-chart>
        </div>
        <a *ngIf="showLink" routerLink="/tools/spread">Add/Edit Spread Data</a>
        <ng-template #loading>
            Loading your data... <mat-spinner diameter="25"></mat-spinner>
        </ng-template>
    </div>
</cn-widget-card>