<cn-widget-card>
    <ng-container class="title">My Personal Goal</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <ng-container class="content">
        <div>
            I will make
            <mat-form-field class="dollars-input">
                <input matInput name="dollars" [(ngModel)]="widgetData['dollars']" (change)="update()">
                <span matPrefix>$</span>
            </mat-form-field>
            per year, working
            <mat-form-field class="hours-input">
                <input matInput name="hours" [(ngModel)]="widgetData['hours']" (change)="update()">
            </mat-form-field>
            hours per week, and be happy doing it!
        </div>
    </ng-container>
</cn-widget-card>