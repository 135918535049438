<cn-widget-card>
    <ng-container class="title">ADL/Leads Run</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <div class="content">
        <div class="chart-wrapper" *ngIf="!pending; else loading">
            <cn-table-chart [chartHeight]="261" [chartOnly]="true" [calculatedColumns]="calculatedColumns" [colorScheme]="colors" [dataObject]="data!" [displayedColumns]="displayedAdlColumns" [dollars]="false" [switchToRight]="3"></cn-table-chart>
        </div>
        <a *ngIf="showLink" routerLink="/tools/adl">Add/Edit ADL/Leads Run Data</a>
        <ng-template #loading>
            Loading your data... <mat-spinner diameter="25"></mat-spinner>
        </ng-template>
    </div>
</cn-widget-card>