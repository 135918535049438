import { Component, Input, OnInit } from '@angular/core';

import { ScaleType } from '@swimlane/ngx-charts';

import { WidgetService } from '../../core';
import { ProductionBacklog } from '../../student-tools/models';

@Component({
    selector: 'cn-production-backlog-widget',
    templateUrl: 'production-backlog-widget.component.html',
    styleUrls: ['production-backlog-widget.component.css']
})
export class ProductionBacklogWidgetComponent implements OnInit {
    public calculatedColumns = [false, false, false, false, false];
    public colors = { name: 'Production Backlog', selectable: false, group: ScaleType.Linear, domain: ['#06631C', '#FFA400', '#E9190F', '#000'] };
    @Input() public data: { data: ProductionBacklog[]; labels?: string[] } = { data: [] };
    public displayedColumns = ['Month', 'Year', 'Production A', 'Production B', 'Production C', 'Production D'];
    public pending = false;
    @Input() public showLink = true;

    constructor(
        private widgetService: WidgetService
    ) {}

    public ngOnInit(): void {
        if (!this.data) {
            this.data = { data: [] };
            this.pending = true;
            this.widgetService.getData('production-backlog')
                .subscribe((data: ProductionBacklog[] | { data: ProductionBacklog[]; labels: string[] }) => {
                    if (data.hasOwnProperty('data')) {
                        this.data = data as { data: ProductionBacklog[]; labels: string[] };
                    } else {
                        this.data.data = (data as ProductionBacklog[]).length ? (data as ProductionBacklog[]) : [];
                        this.data.labels = [];
                    }
                    this.pending = false;
                });
        }
    }
}