<mat-card appearance="outlined" class="item">
    <img mat-card-image class="header-img" [src]="header.replace('http://', 'https://')">
    <mat-card-content>
        <div class="title" [innerHtml]="title"></div>
        <div class="content" [innerHtml]="getContent() | truncateWords: 25"></div>
        <div class="date">
            <div>{{ date | date: 'MMM dd' }}</div>
        </div>
        <div class="link" (click)="readMessage()">Read More</div>
    </mat-card-content>
</mat-card>