import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { School } from '../models';
import { Auth } from '../../root-store';

@Injectable({
    providedIn: 'root'
})
export class SchoolGuard  {
    constructor(
        private store: Store<Auth.State>,
        private router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canAccess(route.data);
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.canAccess(route.data);
    }

    private canAccess(data: any): Observable<boolean> {
        return this.store.select(Auth.selectAuth).pipe(
            filter((auth: Auth.State) => !!auth.school),
            take(1),
            map((auth: Auth.State) => auth.school),
            map((school: School) => {
                // If there is no school, return true (should never happen)
                if (!school) {
                    return true;
                }

                if (data.exclude) {
                    // Route is excluding specific schools, so check if the current school is excluded
                    for (const exclude of data.exclude) {
                        if (exclude.id === school.id) {
                            // School is excluded, navigate to the redirect for that school
                            this.router.navigate([exclude.redirect]);

                            return false;
                        }
                    }

                    return true;
                } else if (data.include) {
                    // Route is including specific schools so check if the current school is included
                    for (const include of data.include) {
                        if (include.id === school.id) {
                            // School is included, so navigation can proceed
                            return true;
                        }
                    }

                    // School was not included, navigate to the redirect
                    this.router.navigate([data.redirect]);

                    return false;
                } else {
                    // Route is not including or excluding any schools, allow navigation
                    return true;
                }
            })
        );
    }
}