<div class="background">
    <div class="wrapper">
        <div class="modal-wrapper flex-column flex-center-center">
            <div class="modal">
                <h2>Page Not Found</h2>
                <p>We're sorry, but we couldn't find the page you are looking for.</p>
                <p>Please <a (click)="back()">go back</a> and try again.</p>
            </div>
        </div>
    </div>
</div>