import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Auth, Theme } from '../../root-store';
import { SchoolFeatures } from '../../core/models';

@Component({
    selector: 'cn-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {
    public avatar$: Observable<string>;
    public color$: Observable<string>;
    public isLoggedIn$: Observable<boolean>;
    public logo$: Observable<string>;
    public showGroupsLink$: Observable<boolean>;

    constructor(
        private store: Store<Auth.State>
    ) {
        this.avatar$ = this.store.select(Auth.selectUserAvatar).pipe(
            map((avatar: string) => avatar || 'assets/default-avatar.png')
        );
        this.isLoggedIn$ = this.store.select(Auth.selectIsLoggedIn);

        this.logo$ = combineLatest([
            this.store.select(Theme.selectLightLogo),
            this.store.select(Theme.selectNetworkLogo)
        ]).pipe(
            map(([lightLogo, networkLogo]: [string, string]) => networkLogo || lightLogo)
        );

        this.color$ = this.store.select(Theme.selectHeaderColor);

        this.showGroupsLink$ = this.store.select(Auth.selectSchoolFeatures).pipe(
            map((features: SchoolFeatures) => features.show_groups_link)
        );
    }
}
