import { InjectionToken, inject, Inject } from '@angular/core';

import { ConnectionStatus, ConnectionType, Network, NetworkPlugin } from '@capacitor/network';

import { Config, IConfig } from '../config/config.model';
import { Navigator } from '../navigator.service';

export interface INetworkInformationService {
    connection: Promise<ConnectionType>;
    hasInternet: Promise<boolean>;
}

export class WebNetworkInformationService implements INetworkInformationService {
    public connection = Promise.resolve('wifi' as ConnectionType);
    public hasInternet = Promise.resolve(true);
}

export class MobileNetworkInformationService implements INetworkInformationService {
    constructor(
        @Inject('Network') private network: NetworkPlugin
    ) {}

    public get connection(): Promise<ConnectionType> {
        return this.network.getStatus().then((status: ConnectionStatus) => status.connectionType);
    }

    public get hasInternet(): Promise<boolean> {
        return this.network.getStatus().then((status: ConnectionStatus) => status.connected);
    }
}

export class DesktopNetworkInformationService implements INetworkInformationService {
    public connection = Promise.resolve('wifi' as ConnectionType);

    constructor(
        @Inject('Navigator') private navigator: any
    ) {}

    public get hasInternet(): Promise<boolean> {
        return Promise.resolve(this.navigator.onLine);
    }
}

export function networkInformationServiceFactory(config: IConfig, navigator: Navigator, network: NetworkPlugin): INetworkInformationService {
    let service: INetworkInformationService;

    if (config.isMobile) {
        service = new MobileNetworkInformationService(network);
    } else if (config.isDesktop) {
        service = new DesktopNetworkInformationService(navigator);
    } else {
        service = new WebNetworkInformationService();
    }

    return service;
}

export const NetworkInformationService = new InjectionToken<INetworkInformationService>('NetworkInformationService', { // eslint-disable-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    providedIn: 'root',
    factory: () => networkInformationServiceFactory(inject(Config), inject(Navigator), Network)
});