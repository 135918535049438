import { Injectable } from '@angular/core';

declare let introJs: any;

@Injectable({
    providedIn: 'root'
})
export class IntroService {
    public hideHints(): void {
        introJs().hideHints();
    }

    public showHints(): void {
        introJs().showHints();
    }

    public start(): void {
        introJs().start();
    }
}