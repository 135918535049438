import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

// eslint-disable-next-line no-shadow
export enum ScreenSize {
    xs = 0,
    sm,
    md,
    lg,
    xl
}

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService {
    public screenSize: BehaviorSubject<ScreenSize> = new BehaviorSubject<ScreenSize>(ScreenSize.lg);

    constructor(
        private media: BreakpointObserver
    ) {
        this.media.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe((data: BreakpointState) => {
            if (data.breakpoints[Breakpoints.XSmall]) {
                this.screenSize.next(ScreenSize.xs);
            } else if (data.breakpoints[Breakpoints.Small]) {
                this.screenSize.next(ScreenSize.sm);
            } else if (data.breakpoints[Breakpoints.Medium]) {
                this.screenSize.next(ScreenSize.md);
            } else if (data.breakpoints[Breakpoints.Large]) {
                this.screenSize.next(ScreenSize.lg);
            } else if (data.breakpoints[Breakpoints.XLarge]) {
                this.screenSize.next(ScreenSize.xl);
            }
        })
    }
}