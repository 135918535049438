class WebStorageUtility {
    public static generateStorageKey(key: string): string {
        return key;
    }

    public static get(storage: Storage, key: string): any {
        const storageKey = WebStorageUtility.generateStorageKey(key);
        const value = storage.getItem(storageKey);

        return WebStorageUtility.getGettable(value || '');
    }

    public static remove(storage: Storage, key: string): void {
        const storageKey = WebStorageUtility.generateStorageKey(key);
        storage.removeItem(storageKey);
    }

    public static set(storage: Storage, key: string, value: any): void {
        const storageKey = WebStorageUtility.generateStorageKey(key);
        storage.setItem(storageKey, WebStorageUtility.getSettable(value));
    }

    private static getGettable(value: string): any {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    private static getSettable(value: any): string {
        return typeof value === 'string' ? value : JSON.stringify(value);
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
const WebStorage = (webStorage: Storage, key: string | undefined) => (target: Object, propertyName: string): void => {
        const storageKey = key || propertyName;

        Object.defineProperty(target, propertyName, {
            get: () => WebStorageUtility.get(webStorage, storageKey),
            set: (value: any) => WebStorageUtility.set(webStorage, storageKey, value)
        });
    };

// eslint-disable-next-line @typescript-eslint/naming-convention
export function LocalStorage(key?: string): (target: Object, propertyName: string) => void {
    return WebStorage(localStorage, key);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function SessionStorage(key?: string): (target: Object, propertyName: string) => void {
    return WebStorage(sessionStorage, key);
}