import { Component, Input } from '@angular/core';

import { CourseService } from '../../core';

@Component({
    selector: 'cn-download-card',
    templateUrl: './download-card.component.html',
    styleUrls: ['./download-card.component.css']
})
export class DownloadCardComponent {
    @Input() public content: number;
    @Input() public course: number;
    @Input() public size: number;
    @Input() public thumbnail: string;
    @Input() public title: string;
    @Input() public type: string;
    @Input() public url: string;

    constructor(
        private courseService: CourseService
    ) {}

    public getFileSizeDisplay(bytes: number): string {
        const mB = bytes / (1024 * 1024);
        const mBString = mB.toPrecision(2);

        return `${mBString}MB`;
    }

    public getFileTypeDisplay(mimeType: string): string {
        const suffix = mimeType.split('/').pop();

        return suffix ? suffix.toUpperCase() : '';
    }

    public updateCompletion(): void {
        if (this.course && this.content) {
            this.courseService.updateContentCompletion(this.course, this.content, 100).subscribe();
        }
    }
}
