/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CategoryService, CourseService } from '../../core';
import { Category } from '../../core/models';

import { CnLearnServiceModule } from '../../cn-learn/cn-learn-service.module';

import {
    ActionTypes,
    Complete,
    CompleteFailure,
    CompleteSuccess,
    Favorite,
    FavoriteFailure,
    FavoriteSuccess,
    FavoriteAsset,
    FavoriteAssetFailure,
    FavoriteAssetSuccess,
    GetCategoriesFailure,
    GetCategoriesSuccess,
    Unfavorite,
    UnfavoriteFailure,
    UnfavoriteSuccess,
    UnfavoriteAsset,
    UnfavoriteAssetFailure,
    UnfavoriteAssetSuccess,
    UpdateCompletion,
    UpdateCompletionFailure,
    UpdateCompletionSuccess
} from './category.actions';

@Injectable({
    providedIn: CnLearnServiceModule
})
export class CategoryEffects {
    constructor(
        private actions: Actions,
        private categoryService: CategoryService,
        private courseService: CourseService
    ) {}

    GetCategories: Observable<GetCategoriesSuccess | GetCategoriesFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.GET_CATEGORIES),
            switchMap(() => this.categoryService.getAll()),
            map((categories: Category[]) => new GetCategoriesSuccess(categories)),
            catchError((err: any) => of(new GetCategoriesFailure(err)))
        )
    );

    Complete: Observable<CompleteSuccess | CompleteFailure> = createEffect(() => {
        let initialAction: Complete;

        return this.actions.pipe(
            ofType(ActionTypes.COMPLETE),
            tap((action: Complete) => initialAction = action),
            switchMap((action: Complete) => this.categoryService.completeAsset(action.payload.category, action.payload.assetType, action.payload.assetId)),
            map(() => new CompleteSuccess(initialAction.payload)),
            catchError(() => of(new CompleteFailure()))
        );
    });

    Favorite: Observable<FavoriteSuccess | FavoriteFailure> = createEffect(() => {
        let initialAction: Favorite;

        return this.actions.pipe(
            ofType(ActionTypes.FAVORITE),
            tap((action: Favorite) => initialAction = action),
            switchMap((action: Favorite) => this.categoryService.favoriteCategory(action.payload.category)),
            map(() => new FavoriteSuccess()),
            catchError(() => of(new FavoriteFailure(initialAction.payload)))
        );
    });

    FavoriteAsset: Observable<FavoriteAssetSuccess | FavoriteAssetFailure> = createEffect(() => {
        let initialAction: FavoriteAsset;

        return this.actions.pipe(
            ofType(ActionTypes.FAVORITE_ASSET),
            tap((action: FavoriteAsset) => initialAction = action),
            switchMap((action: FavoriteAsset) => this.courseService.favoriteCourse(action.payload.courseId)),
            map(() => new FavoriteAssetSuccess()),
            catchError(() => of(new FavoriteAssetFailure(initialAction.payload)))
        );
    });

    Unfavorite: Observable<UnfavoriteSuccess | UnfavoriteFailure> = createEffect(() => {
        let initialAction: Unfavorite;

        return this.actions.pipe(
            ofType(ActionTypes.UNFAVORITE),
            tap((action: Unfavorite) => initialAction = action),
            switchMap((action: Unfavorite) => this.categoryService.unfavoriteCategory(action.payload.category)),
            map(() => new UnfavoriteSuccess()),
            catchError(() => of(new UnfavoriteFailure(initialAction.payload)))
        );
    });

    UnfavoriteAsset: Observable<UnfavoriteAssetSuccess | UnfavoriteAssetFailure> = createEffect(() => {
        let initialAction: UnfavoriteAsset;

        return this.actions.pipe(
            ofType(ActionTypes.UNFAVORITE_ASSET),
            tap((action: UnfavoriteAsset) => initialAction = action),
            switchMap((action: UnfavoriteAsset) => this.courseService.unfavoriteCourse(action.payload.courseId)),
            map(() => new UnfavoriteAssetSuccess()),
            catchError(() => of(new UnfavoriteAssetFailure(initialAction.payload)))
        );
    });

    UpdateCompletion: Observable<UpdateCompletionSuccess | UpdateCompletionFailure> = createEffect(() => {
        let initialAction: UpdateCompletion;

        return this.actions.pipe(
            ofType(ActionTypes.UPDATE_COMPLETION),
            tap((action: UpdateCompletion) => initialAction = action),
            switchMap((action: UpdateCompletion) => this.courseService.updateContentCompletion(action.payload.courseId, action.payload.contentId, action.payload.completion)),
            map(() => new UpdateCompletionSuccess(initialAction.payload)),
            catchError(() => of(new UpdateCompletionFailure()))
        );
    });
}