<table mat-table [dataSource]="dataSource">
    <ng-container [matColumnDef]="'column'">
        <td mat-cell *matCellDef="let element">
            <ng-container *ngTemplateOutlet="forItemTemplate; context: {$implicit: element}"></ng-container>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let element; columns: ['column'];" class="element-row">
    </tr>
</table>
<mat-paginator *ngIf="pagination" [pageIndex]="currentPage" [pageSize]="currentSize" [pageSizeOptions]="pageSizes" (page)="pageChange.emit($event)"></mat-paginator>