import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { Category, CategoryChapter, CategoryLevel } from '../../core/models';

import { State } from './category.state';

export const selectCategoryState = createFeatureSelector<State>('Category');

export const selectLoaded = createSelector(selectCategoryState, (state: State) => state.loaded);
export const selectCategories = createSelector(selectCategoryState, (state: State) => state.categories);
export const selectCategory = (categoryId: number): MemoizedSelector<object, Category | undefined> => createSelector(selectCategories, (categories: Category[]) => categories.find((c: Category) => c.id === categoryId));

export const selectNotStartedCategories = createSelector(selectCategories, (categories: Category[]) => categories.filter((c: Category) => {
    // If no chapters, cannot be started
    if (!c.chapters) {
        return true;
    }

    // If any chapters have completed items, it's started
    const completedItem = c.chapters.find((chapter: CategoryChapter) => chapter.items.find((item) => item.completed));
    if (completedItem) {
        return false;
    }

    return true;
}));
export const selectInProgressCategories = createSelector(selectCategories, (categories: Category[]) => categories.filter((c: Category) => {
    // If no chapters, cannot be started
    if (!c.chapters) {
        return false;
    }

    // If any chapters have completed items, it's started
    const completedItem = c.chapters.find((chapter: CategoryChapter) => chapter.items.find((item) => item.completed));
    if (completedItem) {
        return true;
    }

    return false;
}));
export const selectCompletedCategories = createSelector(selectCategories, (categories: Category[]) => categories.filter((c: Category) => c.chapters && !c.chapters.find((chapter: CategoryChapter) => !chapter.completed)));
export const selectFavoriteCategories = createSelector(selectCategories, (categories: Category[]) => categories.filter((c: Category) => c.favorite));

export const selectFirstCompanyLogo = createSelector(selectCategories, (categories: Category[]) => {
    const firstWithCompany = categories.find((c: Category) => c.tbs_association && c.tbs_company);
    if (firstWithCompany) {
        return `https://s3.amazonaws.com/cdn.treehouseinternetgroup.com/img/logos/${firstWithCompany.tbs_association}/large/${firstWithCompany.tbs_company}.png`;
    }

    return '';
});

export const selectLevels = createSelector(selectCategories, (categories: Category[]) => {
    const levels: CategoryLevel[] = categories
        .map((c: Category) => ({ level: c.level, title: c.level_title || '', image: c.level_image || '' }))
        .filter((level, index, self) => level.level > 0 && self.findIndex((l) => l.level === level.level) === index)
        .sort((a, b) => a.level - b.level);

    return levels;
});