import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { EFFECTS } from './root.effects';
import { REDUCERS } from './root.reducer';

@NgModule({
    imports: [
        CommonModule,

        StoreModule.forRoot(REDUCERS, { runtimeChecks: { strictStateImmutability: false } }),
        EffectsModule.forRoot(EFFECTS),
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer })
    ]
})
export class RootStoreModule {}