import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Category, CategoryGoal, CategoryResource } from '../models';
import { Config, IConfig } from '../config/config.model';

import { Auth } from '../../root-store';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public addChapterFeedback(categoryId: number, chapterId: number, rating: number, comments: string): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.post<void>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}/chapters/${chapterId}/feedback`, { rating, comments }))
        );
    }

    public completeAsset(categoryId: number, assetType: 'course' | 'quiz', assetId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<void>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}/assets`, { type: assetType, asset: assetId }))
        );
    }

    public favoriteCategory(categoryId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<void>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}/favorite`, { favorite: true }))
        );
    }

    public getAll(): Observable<Category[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Category[]>(`${this.apiUrl}/schools/${schoolId}/categories`))
        );
    }

    public getCertificate(categoryId: number): Observable<string> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<{ url: string }>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}/certificate`)) ,
            map((data: { url: string }) => data.url)
        );
    }

    public getGoals(categoryId: number): Observable<CategoryGoal[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<CategoryGoal[]>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}/goals`))
        );
    }

    public getOne(categoryId: number): Observable<Category> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Category>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}`))
        );
    }

    public getResources(categoryId: number): Observable<CategoryResource[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<CategoryResource[]>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}/resources`))
        );
    }

    public unfavoriteCategory(categoryId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<void>(`${this.apiUrl}/schools/${schoolId}/categories/${categoryId}/favorite`, { favorite: false }))
        );
    }
}