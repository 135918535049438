import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { Role, School, SchoolFeatures, TbsUser, User } from '../../core/models';

import { State } from './auth.state';

export const selectAuth = createFeatureSelector<State>('Auth');

export const selectIdToken = createSelector(selectAuth, (state: State) => state.id_token);
export const selectAccessToken = createSelector(selectAuth, (state: State) => state.access_token);
export const selectRefreshToken = createSelector(selectAuth, (state: State) => state.refresh_token);
export const selectTbsAccessToken = createSelector(selectAuth, (state: State) => state.tbs_access_token);

export const selectIsLoading = createSelector(selectAuth, (state: State) => state.is_loading);
export const selectIsLoggedIn = createSelector(selectAuth, (state: State) => state.is_logged_in);
export const selectError = createSelector(selectAuth, ( state: State) => state.error);

export const selectSchools = createSelector(selectAuth, (state: State) => state.schools || []);
export const selectSchool = createSelector(selectAuth, (state: State) => state.school);
export const selectSchoolId = createSelector(selectSchool, (school: School | null) => school ? school.id : 0);
export const selectSchoolTitle = createSelector(selectSchool, (school: School | null) => school ? school.title : '');
export const selectSchoolDarkLogo = createSelector(selectSchool, (school: School | null) => school ? school.dark_logo : '');
export const selectSchoolFeatures = createSelector(selectSchool, (school: School | null) => school && school.features ? school.features : {} as SchoolFeatures);

export const selectUser = createSelector(selectAuth, (state: State) => state.user);
export const selectUserAvatar = createSelector(selectUser, (user: User | null) => user ? user.avatar : '');
export const selectUserId = createSelector(selectUser, (user: User | null) => user ? user.id : 0);
export const selectUserSub = createSelector(selectUser, (user: User | null) => user ? user.sub : '');
export const selectUserFirstName = createSelector(selectUser, (user: User | null) => user ? user.first_name : '');
export const selectUserLastName = createSelector(selectUser, (user: User | null) => user ? user.last_name : '');
export const selectUserEmail = createSelector(selectUser, (user: User | null) => user ? user.email : '');
export const selectUserTbsId = createSelector(selectUser, (user: User | null) => user ? user.tbs_id : undefined);

export const selectTbsUser = createSelector(selectAuth, (state: State) => state.tbs_user);
export const selectTbsUserFirstName = createSelector(selectTbsUser, (user: TbsUser | null) => user ? user.first_name : undefined);
export const selectTbsUserLastName = createSelector(selectTbsUser, (user: TbsUser | null) => user ? user.last_name : undefined);
export const selectTbsUserEmail = createSelector(selectTbsUser, (user: TbsUser | null) => user ? user.email : undefined);
export const selectTbsUserAvatar = createSelector(selectTbsUser, (user: TbsUser | null) => user ? user.avatar : undefined);

export const selectRoles = createSelector(selectAuth, (state: State) => state.roles);
export const selectAvailableDealers = (role?: string, network?: number): MemoizedSelector<State, number[]> => createSelector(selectRoles, selectSchoolId, (roles: Role[] | null, schoolId: number) => {
    if (!roles) {
        return [];
    }

    roles = roles.filter((r: Role) => r.school === schoolId);

    if (role) {
        roles = roles.filter((r: Role) => r.name === role);
    }

    if (network) {
        roles = roles.filter((r: Role) => r.network === network);
    }

    return roles.map((r: Role) => r.dealer) as number[];
});
export const selectAvailableNetworks = (role: string): MemoizedSelector<State, number[]> => createSelector(selectRoles, selectSchoolId, (roles: Role[] | null, schoolId: number) => {
    if (!roles) {
        return [];
    }

    roles = roles.filter((r: Role) => r.school === schoolId);

    if (role) {
        roles = roles.filter((r: Role) => r.name === role);
    }

    return roles.map((r: Role) => r.network) as number[];
});