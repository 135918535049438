import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'columnSplit',
    pure: true
})
export class ColumnSplitPipe implements PipeTransform {
    public transform(input: any[], columns: number = 2): any[][] {
        const splitArray: any[] = [];
        input.forEach((item: any, index: number) => {
            if (splitArray[index % columns]) {
                splitArray[index % columns].push(item);
            } else {
                splitArray.push([item]);
            }
        });

        return splitArray;
    }
}