import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { CourseService } from './course.service';
import { AccessService } from '../access/access.service';

@Injectable({
    providedIn: 'root'
})
export class CourseGuard  {

    constructor(
        private courseService: CourseService,
        private accessService: AccessService,
        private router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const courseId: number = route.params.courseId;

        return this.accessService.canAccessStore().pipe(
            switchMap((canAccess: boolean) => {
                if (canAccess) {
                    return of(true);
                }

                return this.courseService.userOwnsCourse(courseId);
            }),
            tap((ownsCourse: boolean) => {
                if (!ownsCourse) {
                    this.router.navigate(['/library']);
                }
            })
        );
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}
