import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Language } from '../../root-store';

@Pipe({
    name: 'language'
})
export class LanguagePipe implements PipeTransform {
    constructor(
        private store: Store<Language.State>
    ) {}

    public transform(input: string): Observable<string> {
        return this.store.select(Language.selectToken(input));
    }
}