import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { WidgetService } from '../../core';
import { VisualAmbitionSet, VisualAmbitionSetGroup } from '../../student-tools/models';

import { VisualAmbitionCreationDialogComponent } from '../../shared';

interface VisualAmbitionSetWithKey {
    data: VisualAmbitionSet;
    name: string;
}

@Component({
    selector: 'cn-visual-ambition',
    templateUrl: 'visual-ambition-widget.component.html',
    styleUrls: ['visual-ambition-widget.component.css']
})
export class VisualAmbitionWidgetComponent implements OnInit {
    public data: VisualAmbitionSetWithKey[];
    public loaded = false;

    constructor(
        private widgetService: WidgetService,
        private dialog: MatDialog,
        private router: Router
    ) { }

    public createPoster(): void {
        const dialogRef = this.dialog.open(VisualAmbitionCreationDialogComponent);
        dialogRef.afterClosed().subscribe((name: string) => {
            if (name) {
                this.router.navigate(['/tools', 'visual-ambition', name])
                    .catch((err: any) => {
                        // Fail silently
                    });
            }
        });
    }

    public ngOnInit(): void {
        this.loaded = false;
        this.widgetService.getData('visual-ambition')
            .subscribe((data: VisualAmbitionSetGroup) => {
                this.data = Object.keys(data)
                    .filter((key: string) => typeof data[key].deleted === 'undefined' || data[key].deleted !== true)
                    .map((key: string) => ({ name: key, data: data[key] })) // Get array from object's keys
                    .sort((a: VisualAmbitionSetWithKey, b: VisualAmbitionSetWithKey) => { // Sort data sets based on last updated date (most recent first), then alphabetically (A-Z)
                        if (a.data.updated_on < b.data.updated_on) {
                            return 1;
                        } else if (a.data.updated_on > b.data.updated_on) {
                            return -1;
                        }

                        if (a.name < b.name) {
                            return -1;
                        } else if (a.data.updated_on > b.data.updated_on) {
                            return 1;
                        }

                        return 0;
                    })
                    .slice(0, 2); // Get only most recent 2 data sets

                this.loaded = true;
            });
    }
}