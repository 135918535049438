import { Action } from '@ngrx/store';

import { Course, CnPlaylist, UserPlaylist } from '../../core/models';

export enum ActionTypes {
    GET_CN_PLAYLISTS = '[Playlist] Get CN Playlists',
    GET_CN_PLAYLISTS_SUCCESS = '[Playlist] Get CN Playlists Success',
    GET_CN_PLAYLISTS_FAILURE = '[Playlist] Get CN Playlists Failure',

    LOG_CN_PLAYLIST_USAGE = '[Playlist] Log CN Playlist Usage',
    LOG_CN_PLAYLIST_USAGE_SUCCESS = '[Playlist] Log CN Playlist Usage Success',
    LOG_CN_PLAYLIST_USAGE_FAILURE = '[Playlist] Log CN Playlist Usage Failure',

    GET_USER_PLAYLISTS = '[Playlist] Get User Playlists',
    GET_USER_PLAYLISTS_SUCCESS = '[Playlist] Get User Playlists Success',
    GET_USER_PLAYLISTS_FAILURE = '[Playlist] Get User Playlists Failure',

    CREATE_USER_PLAYLIST = '[Playlist] Create User Playlist',
    CREATE_USER_PLAYLIST_SUCCESS = '[Playlist] Create User Playlist Success',
    CREATE_USER_PLAYLIST_FAILURE = '[Playlist] Create User Playlist Failure',

    EDIT_USER_PLAYLIST = '[Playlist] Edit User Playlist',
    EDIT_USER_PLAYLIST_SUCCESS = '[Playlist] Edit User Playlist Success',
    EDIT_USER_PLAYLIST_FAILURE = '[Playlist] Edit User Playlist Failure',

    DELETE_USER_PLAYLIST = '[Playlist] Delete User Playlist',
    DELETE_USER_PLAYLIST_SUCCESS = '[Playlist] Delete User Playlist Success',
    DELETE_USER_PLAYLIST_FAILURE = '[Playlist] Delete User Playlist Failure',

    LOG_USER_PLAYLIST_USAGE = '[Playlist] Log User Playlist Usage',
    LOG_USER_PLAYLIST_USAGE_SUCCESS = '[Playlist] Log User Playlist Usage Success',
    LOG_USER_PLAYLIST_USAGE_FAILURE = '[Playlist] Log User Playlist Usage Failure'
}

export class GetCnPlaylists implements Action {
    public readonly type = ActionTypes.GET_CN_PLAYLISTS;
}

export class GetCnPlaylistsSuccess implements Action {
    public readonly type = ActionTypes.GET_CN_PLAYLISTS_SUCCESS;

    constructor(public payload: { playlists: CnPlaylist[] }) {}
}

export class GetCnPlaylistsFailure implements Action {
    public readonly type = ActionTypes.GET_CN_PLAYLISTS_FAILURE;

    constructor(public payload: any) {}
}

export class LogCnPlaylistUsage implements Action {
    public readonly type = ActionTypes.LOG_CN_PLAYLIST_USAGE;

    constructor(public payload: { playlist: number; course: number }) {}
}

export class LogCnPlaylistUsageSuccess implements Action {
    public readonly type = ActionTypes.LOG_CN_PLAYLIST_USAGE_SUCCESS;

    constructor(public payload: { playlist: number; course: number }) {}
}

export class LogCnPlaylistUsageFailure implements Action {
    public readonly type = ActionTypes.LOG_CN_PLAYLIST_USAGE_FAILURE;
}

export class GetUserPlaylists implements Action {
    public readonly type = ActionTypes.GET_USER_PLAYLISTS;
}

export class GetUserPlaylistsSuccess implements Action {
    public readonly type = ActionTypes.GET_USER_PLAYLISTS_SUCCESS;

    constructor(public payload: { playlists: UserPlaylist[] }) {}
}

export class GetUserPlaylistsFailure implements Action {
    public readonly type = ActionTypes.GET_USER_PLAYLISTS_FAILURE;

    constructor(public payload: any) {}
}

export class CreateUserPlaylist implements Action {
    public readonly type = ActionTypes.CREATE_USER_PLAYLIST;

    constructor(public payload: { playlist: Partial<UserPlaylist>; courses?: number[] }) {}
}

export class CreateUserPlaylistSuccess implements Action {
    public readonly type = ActionTypes.CREATE_USER_PLAYLIST_SUCCESS;

    constructor(public payload: UserPlaylist) {}
}

export class CreateUserPlaylistFailure implements Action {
    public readonly type = ActionTypes.CREATE_USER_PLAYLIST_FAILURE;
}

export class EditUserPlaylist implements Action {
    public readonly type = ActionTypes.EDIT_USER_PLAYLIST;

    constructor(public payload: { playlist: UserPlaylist; courses: Course[] }) {}
}

export class EditUserPlaylistSuccess implements Action {
    public readonly type = ActionTypes.EDIT_USER_PLAYLIST_SUCCESS;

    constructor(public payload: { playlist: UserPlaylist; courses: Course[] }) {}
}

export class EditUserPlaylistFailure implements Action {
    public readonly type = ActionTypes.EDIT_USER_PLAYLIST_FAILURE;
}

export class DeleteUserPlaylist implements Action {
    public readonly type = ActionTypes.DELETE_USER_PLAYLIST;

    constructor(public payload: { playlistId: number }) {}
}

export class DeleteUserPlaylistSuccess implements Action {
    public readonly type = ActionTypes.DELETE_USER_PLAYLIST_SUCCESS;

    constructor(public payload: { playlistId: number }) {}
}

export class DeleteUserPlaylistFailure implements Action {
    public readonly type = ActionTypes.DELETE_USER_PLAYLIST_FAILURE;
}

export class LogUserPlaylistUsage implements Action {
    public readonly type = ActionTypes.LOG_USER_PLAYLIST_USAGE;

    constructor(public payload: { playlist: number; course: number }) {}
}

export class LogUserPlaylistUsageSuccess implements Action {
    public readonly type = ActionTypes.LOG_USER_PLAYLIST_USAGE_SUCCESS;

    constructor(public payload: { playlist: number; course: number }) {}
}

export class LogUserPlaylistUsageFailure implements Action {
    public readonly type = ActionTypes.LOG_USER_PLAYLIST_USAGE_FAILURE;
}

export type PlaylistActions =
    | GetCnPlaylists
    | GetCnPlaylistsSuccess
    | GetCnPlaylistsFailure
    | LogCnPlaylistUsage
    | GetUserPlaylists
    | GetUserPlaylistsSuccess
    | GetUserPlaylistsFailure
    | CreateUserPlaylist
    | CreateUserPlaylistSuccess
    | CreateUserPlaylistFailure
    | EditUserPlaylist
    | EditUserPlaylistSuccess
    | EditUserPlaylistFailure
    | DeleteUserPlaylist
    | DeleteUserPlaylistSuccess
    | DeleteUserPlaylistFailure
    | LogUserPlaylistUsage
    ;