/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';

import * as moment from 'moment';

declare let safari: any;
declare let window: any;

export interface DateStreakSummary {
    currentStreak: number;
    longestStreak: number;
    streaks: number[];
    todayInStreak: boolean;
    withinCurrentStreak: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    public binarySearch(items: any[], value: any): number {
        let firstIndex = 0;
        let lastIndex = items.length - 1;
        let middleIndex = Math.floor((lastIndex + firstIndex) / 2);

        while (items[middleIndex] !== value && firstIndex < lastIndex) {
            if (value < items[middleIndex]) {
                lastIndex = middleIndex - 1;
            } else if (value > items[middleIndex]) {
                firstIndex = middleIndex + 1;
            }
            middleIndex = Math.floor((lastIndex + firstIndex) / 2);
        }

        return items[middleIndex] === value ? middleIndex : -1;
    }

    // Copied from npm package date-streaks https://github.com/jonsamp/date-streaks
    public getDateStreak(dates: any[]): DateStreakSummary {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');

        dates = dates
            .filter((date: any) => moment(date).isValid())
            .sort((a: any, b: any) => (
                (moment(moment(a).startOf('day')).format('X') as any) -
                (moment(moment(b).startOf('day')).format('X') as any)
            ));

        const result = dates.reduce((acc: any, date: any, index: number) => {
            const first = moment(date).startOf('day');
            const second = dates[index + 1] ? moment(dates[index + 1]).startOf('day') : first;
            const diff = second.diff(first, 'days');
            const isToday = acc.isToday || moment(date).diff(today) === 0;
            const isYesterday = acc.isYesterday || moment(date).diff(yesterday) === 0;
            const isInFuture = acc.isInFuture || moment(today).diff(date) < 0;

            if (diff === 0) {
                if (isToday) {
                    acc.todayInStreak = true;
                }
            } else {
                if (diff === 1) {
                    ++acc.streaks[acc.streaks.length - 1];
                } else {
                    acc.streaks.push(1);
                }
            }

            return {
                ...acc,
                longestStreak: Math.max(...acc.streaks),
                withinCurrentStreak: acc.isToday || acc.isYesterday || acc.isInFuture || isToday || isYesterday || isInFuture,
                currentStreak: isToday || isYesterday || isInFuture ? acc.streaks[acc.streaks.length - 1] : 0,
                isInFuture,
                isYesterday,
                isToday
            };
        }, {
            currentStreak: 0,
            longestStreak: 0,
            streaks: [1],
            todayInStreak: false,
            withinCurrentStreak: false,
            isInFuture: false,
            isToday: false,
            isYesterday: false
        });

        const { isToday: isTodayExtra, isYesterday: isYesterdayExtra, isInFuture: isInFutureExtra, ...rest } = result;

        return rest;
    }

    public isLightColor(color: string): boolean {

        // Variables for red, green, blue values
        let r: any;
        let g: any;
        let b: any;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            // If RGB --> store the red, green, blue values in separate variables
            const values = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            if (values) {
                r = values[1];
                g = values[2];
                b = values[3];
            }
        } else {
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            const values = +(`0x${  color.slice(1).replace((color.length < 5 && /./g) as any, '$&$&')}`);

            r = values >> 16;
            g = values >> 8 & 255;
            b = values & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        return hsp > 127.5;
    }

    public isChrome(): boolean {
        const isChromium = window.chrome;
        const winNav = window.navigator;
        const vendorName = winNav.vendor;
        const isOpera = typeof window.opr !== 'undefined';
        const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
        const isIosChrome = winNav.userAgent.match('CriOS');

        if (isIosChrome) {
            return true;
        } else if (isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && !isOpera && !isIEedge) {
            return true;
        }

        return false;
    }

    public isSafari(): boolean {
        const isDesktopSafari = (/constructor/i.test(window.HTMLElement) || ((p: any) => p.toString() === '[object SafariRemoteNotification]')(!window.safari || safari.pushNotification));
        const isMobileSafari = /iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent);

        return isDesktopSafari || isMobileSafari;
    }

    public parseColor(input: string): { r: number; g: number; b: number } {
        // Format: #FFF
        let m: any = input.match(/^#([0-9a-f]{3})$/i);
        if (m) {
            m = m[1];

            // In three-character format, each value is multiplied by 0x11 to give an
            // Even scale from 0x00 to 0xff
            return {
                r: parseInt(m.charAt(0), 16) * 0x11,
                g: parseInt(m.charAt(1), 16) * 0x11,
                b: parseInt(m.charAt(2), 16) * 0x11
            };
        }

        // Format: #FFFFFF
        m = input.match(/^#([0-9a-f]{6})$/i);
        if (m) {
            m = m[1];

            return {
                r: parseInt(m.substr(0, 2), 16),
                g: parseInt(m.substr(2, 2), 16),
                b: parseInt(m.substr(4, 2), 16)
            };
        }

        // Format: rgb(0, 0, 0)
        m = input.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
        if (m) {
            return {
                r: m[1],
                g: m[2],
                b: m[3]
            };
        }

        return { r: 0, g: 0, b: 0 };
    }

    public shadeBlend(p: number, c0: any, c1?: any): any {
        /* eslint-disable */
        let n = p < 0 ? p * -1 : p;
        let u = Math.round;
        let w = parseInt;
        if (c0.length > 7) {
            const f = c0.split(',');
            const t = (c1 ? c1 : p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(',');
            const R = w(f[0].slice(4));
            const G = w(f[1]);
            const B = w(f[2]);

            return 'rgb(' + (u((w(t[0].slice(4)) - R) * n) + R) + ',' + (u((w(t[1]) - G) * n) + G) + ',' + (u((w(t[2]) - B) * n) + B) + ')';
        } else {
            const f = w(c0.slice(1), 16), t = w((c1 ? c1 : p < 0 ? '#000000' : '#FFFFFF').slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF;

            return '#' + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1);
        }
        /* eslint-enable */
    }
}
