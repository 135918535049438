import { CnPlaylist, UserPlaylist } from '../../core/models';

export interface State {
    cn_playlists: CnPlaylist[];
    cn_loaded: boolean;
    user_playlists: UserPlaylist[];
    user_loaded: boolean;
};

export const initialState: State = {
    cn_playlists: [],
    cn_loaded: false,
    user_playlists: [],
    user_loaded: false
};