import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ThinkDaily } from '../../core/models';
import { MessageViewerComponent } from '../../shared';

import { ThinkDailyService } from '../../think-daily';

@Component({
    selector: 'cn-think-daily-widget',
    templateUrl: './think-daily-widget.component.html',
    styleUrls: ['./think-daily-widget.component.css']
})
export class ThinkDailyWidgetComponent implements OnInit {
    public business = true;
    public businessThinkDailies: ThinkDaily[] = [];
    public canAccess = false;
    public thinkDailies: ThinkDaily[] = [];

    constructor(
        private dialog: MatDialog,
        private thinkDailyService: ThinkDailyService
    ) { }

    public getContent(content: string): string {
        const tmp = document.createElement('div');
        tmp.innerHTML = content;

        return tmp.textContent || tmp.innerText || '';
    }

    public ngOnInit(): void {
        this.thinkDailyService.getThinkDaily()
            .subscribe((thinkDailies: ThinkDaily[]) => {
                this.thinkDailies = thinkDailies.slice(0, 2);
            });

        this.thinkDailyService.getThinkDailyBusiness()
            .subscribe((thinkDailies: ThinkDaily[]) => {
                this.businessThinkDailies = thinkDailies.slice(0, 2);
            });
    }

    public readMessage(item: ThinkDaily): void {
        this.dialog.open(MessageViewerComponent, {
            data: { message: item.content, title: item.title }
        });
    }
}
