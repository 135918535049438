import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    public transform(input: number, format: string = 'h:m:s'): string | null {
        if (!input) {
            return null;
        }
        const seconds = Math.floor(input % 60);
        const minutes = Math.floor(input / 60) % 60;
        const hours = Math.floor(input / 3600);

        switch (format) {
            case 'hours min':
                return hours ? hours === 1 ? `1 hour ${minutes} min` : `${hours} hours ${minutes} min` : `${minutes} min`;
            case 'h m':
                return hours ? `${hours}hr ${minutes}m` : `${minutes}m`;
            case 'h m s':
                return hours ? `${hours}h ${minutes}m ${seconds}s` : minutes ? `${minutes}m ${seconds}s` : `${seconds}s`;
            default:
                return hours ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
    }

}
