import { Action } from '@ngrx/store';

export enum ActionTypes {
    GET_CATEGORIES = '[CN Learn Category] Get Categories',
    GET_CATEGORIES_SUCCESS = '[CN Learn Category] Get Categories Success',
    GET_CATEGORIES_FAILURE = '[CN Learn Category] Get Categories Failure',
    COMPLETE = '[CN Learn Category] Complete',
    COMPLETE_SUCCESS = '[CN Learn Category] Complete Success',
    COMPLETE_FAILURE = '[CN Learn Category] Complete Failure',
    FAVORITE = '[CN Learn Category] Favorite',
    FAVORITE_SUCCESS = '[CN Learn Category] Favorite Success',
    FAVORITE_FAILURE = '[CN Learn Category] Favorite Failure',
    FAVORITE_ASSET = '[CN Learn Category] Favorite Asset',
    FAVORITE_ASSET_SUCCESS = '[CN Learn Category] Favorite Asset Success',
    FAVORITE_ASSET_FAILURE = '[CN Learn Category] Favorite Asset Failure',
    UNFAVORITE = '[CN Learn Category] Unfavorite',
    UNFAVORITE_SUCCESS = '[CN Learn Category] Unfavorite Success',
    UNFAVORITE_FAILURE = '[CN Learn Category] Unfavorite Failure',
    UNFAVORITE_ASSET = '[CN Learn Category] Unfavorite Asset',
    UNFAVORITE_ASSET_SUCCESS = '[CN Learn Category] Unfavorite Asset Success',
    UNFAVORITE_ASSET_FAILURE = '[CN Learn Category] Unfavorite Asset Failure',
    UPDATE_COMPLETION = '[CN Learn Category] Update Completion',
    UPDATE_COMPLETION_SUCCESS = '[CN Learn Category] Update Completion Success',
    UPDATE_COMPLETION_FAILURE = '[CN Learn Category] Update Completion Failure',
}

export class GetCategories implements Action {
    public readonly type = ActionTypes.GET_CATEGORIES;
}

export class GetCategoriesSuccess implements Action {
    public readonly type = ActionTypes.GET_CATEGORIES_SUCCESS;

    constructor(public payload: any) {}
}

export class GetCategoriesFailure implements Action {
    public readonly type = ActionTypes.GET_CATEGORIES_FAILURE;

    constructor(public payload: any) {}
}

export class Complete implements Action {
    public readonly type = ActionTypes.COMPLETE;

    constructor(public payload: { category: number; chapterIndex: number; assetIndex: number; assetId: number; assetType: 'course' | 'quiz' }) {}
}

export class CompleteSuccess implements Action {
    public readonly type = ActionTypes.COMPLETE_SUCCESS;

    constructor(public payload: { category: number; chapterIndex: number; assetIndex: number; assetId: number; assetType: 'course' | 'quiz' }) {}
}

export class CompleteFailure implements Action {
    public readonly type = ActionTypes.COMPLETE_FAILURE;
}

export class Favorite implements Action {
    public readonly type = ActionTypes.FAVORITE;

    constructor(public payload: { category: number }) {}
}

export class FavoriteSuccess implements Action {
    public readonly type = ActionTypes.FAVORITE_SUCCESS;
}

export class FavoriteFailure implements Action {
    public readonly type = ActionTypes.FAVORITE_FAILURE;

    constructor(public payload: { category: number }) {}
}

export class FavoriteAsset implements Action {
    public readonly type = ActionTypes.FAVORITE_ASSET;

    constructor(public payload: { category: number; chapterIndex: number; courseId: number; assetIndex: number }) {}
}

export class FavoriteAssetSuccess implements Action {
    public readonly type = ActionTypes.FAVORITE_ASSET_SUCCESS;
}

export class FavoriteAssetFailure implements Action {
    public readonly type = ActionTypes.FAVORITE_ASSET_FAILURE;

    constructor(public payload: { category: number; chapterIndex: number; courseId: number; assetIndex: number }) {}
}

export class Unfavorite implements Action {
    public readonly type = ActionTypes.UNFAVORITE;

    constructor(public payload: { category: number }) {}
}

export class UnfavoriteSuccess implements Action {
    public readonly type = ActionTypes.UNFAVORITE_SUCCESS;
}

export class UnfavoriteFailure implements Action {
    public readonly type = ActionTypes.UNFAVORITE_FAILURE;

    constructor(public payload: { category: number }) {}
}

export class UnfavoriteAsset implements Action {
    public readonly type = ActionTypes.UNFAVORITE_ASSET;

    constructor(public payload: { category: number; chapterIndex: number; courseId: number; assetIndex: number }) {}
}

export class UnfavoriteAssetSuccess implements Action {
    public readonly type = ActionTypes.UNFAVORITE_ASSET_SUCCESS;
}

export class UnfavoriteAssetFailure implements Action {
    public readonly type = ActionTypes.UNFAVORITE_ASSET_FAILURE;

    constructor(public payload: { category: number; chapterIndex: number; courseId: number; assetIndex: number }) {}
}

export class UpdateCompletion implements Action {
    public readonly type = ActionTypes.UPDATE_COMPLETION;

    constructor(public payload: { category: number; chapterIndex: number; courseId: number; assetIndex: number; contentId: number; completion: number }) {}
}

export class UpdateCompletionSuccess implements Action {
    public readonly type = ActionTypes.UPDATE_COMPLETION_SUCCESS;

    constructor(public payload: { category: number; chapterIndex: number; courseId: number; assetIndex: number; contentId: number; completion: number }) {}
}

export class UpdateCompletionFailure implements Action {
    public readonly type = ActionTypes.UPDATE_COMPLETION_FAILURE;
}

export type CategoryActions =
    | GetCategories
    | GetCategoriesSuccess
    | GetCategoriesFailure
    | Complete
    | CompleteSuccess
    | CompleteFailure
    | Favorite
    | FavoriteSuccess
    | FavoriteFailure
    | FavoriteAsset
    | FavoriteAssetSuccess
    | FavoriteAssetFailure
    | Unfavorite
    | UnfavoriteSuccess
    | UnfavoriteFailure
    | UnfavoriteAsset
    | UnfavoriteAssetSuccess
    | UnfavoriteAssetFailure
    | UpdateCompletion
    | UpdateCompletionSuccess
    | UpdateCompletionFailure
    ;