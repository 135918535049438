import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';

import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Auth, Playlist } from '../../root-store';

import { AccessService, IPushNotificationService, IVersionService, PushNotificationService, ResponsiveService, ScreenSize, SubjectService, VersionService, Window } from '../../core';
import { CnPlaylist, SchoolFeatures, Subject, UserPlaylist } from '../../core/models';

import { DynamicWidgetLoaderService } from '../../dashboard-widgets/services';

@Component({
    selector: 'cn-standard-layout',
    templateUrl: './standard-layout.component.html',
    styleUrls: ['./standard-layout.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class StandardLayoutComponent implements OnDestroy, OnInit {
    public darkLogo$: Observable<string>;
    public email$: Observable<string>;
    public firstName$: Observable<string>;
    public lastName$: Observable<string>;
    public minimized = false;
    public playlistSubjects: Subject[] = [];
    public ScreenSize = ScreenSize; // Make enum available in template
    public screenSize: ScreenSize;
    public showDashboard$: Observable<boolean>;
    public showDownloads = false;
    public showPlaylists$: Observable<boolean>;
    public showStore: boolean;
    public showSubUsers: boolean;
    public showThinkDaily$: Observable<boolean>;
    @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;
    public userPlaylists$: Observable<UserPlaylist[]>;
    public version = '';
    public widgetComponents: { component: Type<any>; id: number }[] = [];

    private _sidebarOpened = false; // Boolean to handle toggling sidebar on smaller screens only
    private playlistSubscription: Subscription;

    constructor(
        private store: Store<Auth.State>,
        private responsiveService: ResponsiveService,
        private widgetLoaderService: DynamicWidgetLoaderService,
        private accessService: AccessService,
        private subjectService: SubjectService,
        @Inject(PushNotificationService) private pushNotification: IPushNotificationService,
        @Inject(VersionService) private versionService: IVersionService,
        @Inject(Window) private window: Window,
        private router: Router
    ) {
        this.darkLogo$ = this.store.select(Auth.selectSchoolDarkLogo);
        this.email$ = this.store.select(Auth.selectUserEmail);
        this.firstName$ = this.store.select(Auth.selectUserFirstName);
        this.lastName$ = this.store.select(Auth.selectUserLastName);
        this.showThinkDaily$ = this.store.select(Auth.selectSchoolFeatures).pipe(map((features: SchoolFeatures) => features.use_think_daily));
        this.showPlaylists$ = this.store.select(Auth.selectSchoolFeatures).pipe(map((features: SchoolFeatures) => features.use_playlists));
        this.showDashboard$ = this.store.select(Auth.selectSchoolId).pipe(map((schoolId: number) => schoolId !== 3)); // Hide the dashboard for Pop of the Day
        this.userPlaylists$ = this.store.select(Playlist.selectUserPlaylists).pipe(map((playlists: UserPlaylist[]) => [...playlists].sort((a: UserPlaylist, b: UserPlaylist) => b.updated_on < a.updated_on ? -1 : 1).slice(0, 3)));
    }

    public get sidebarMode(): 'over' | 'side' {
        if (this.screenSize > ScreenSize.sm && this.minimized) {
            return 'side';
        } else {
            return 'over';
        }
    }

    public get sidebarOpened(): boolean {
        if (this.screenSize > ScreenSize.sm) {
            return true;
        }

        return this._sidebarOpened;
    }

    public closeSidenav(): void {
        this.window.scrollTo(0, 0);

        if (this.screenSize <= ScreenSize.sm) {
            this.unlockScrolling(this.sidenav);
            this.sidenav.close();
        }
    }

    public handleMouseOver(entering: boolean): void {
        if (this.screenSize > ScreenSize.sm) {
            this.minimized = !entering;
        }
    }

    public lockScrolling(sidenav: any): void {
        disableBodyScroll(sidenav._elementRef.nativeElement);
    }

    public logout(): void {
        this.store.dispatch(new Auth.Actions.Logout({ redirectUrl: '/' }));
    }

    public ngOnDestroy(): void {
        clearAllBodyScrollLocks();

        if (this.playlistSubscription) {
            this.playlistSubscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.responsiveService.screenSize.subscribe((size: ScreenSize) => {
            this.minimized = size > ScreenSize.sm;
            this.screenSize = size;
        });

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.window.scrollTo(0, 0);
            }
        });

        this.accessService.canAccessDownloads()
            .subscribe((canAccessDownloads: boolean) => {
                this.showDownloads = canAccessDownloads;
            });

        this.accessService.canAccessStore()
            .subscribe(
                (canAccessStore: boolean) => {
                    this.showStore = canAccessStore;
                },
                (err: any) => {
                    this.showStore = false;
                }
            );

        this.accessService.canAccessSubUsers()
            .subscribe(
                (canAccessSubUsers: boolean) => {
                    this.showSubUsers = canAccessSubUsers;
                },
                (err: any) => {
                    this.showSubUsers = false;
                }
            );


        this.widgetLoaderService.getComponents()
            .subscribe((components: { component: Type<any>; id: number }[]) => {
                this.widgetComponents = components.slice(0, 4);
            });

        this.pushNotification.init();

        this.versionService.getVersion()
            .then((version: string) => {
                this.version = version;
            });

        this.playlistSubscription = this.showPlaylists$.pipe(
            switchMap((showPlaylists: boolean) =>
                showPlaylists ?
                    combineLatest([
                        this.subjectService.getAll(),
                        this.store.select(Playlist.selectCnPlaylists),
                        this.store.select(Playlist.selectUserPlaylists)
                    ]) :
                    of([undefined, undefined, undefined])
            )
        ).subscribe({
            next: ([subjects, cnPlaylists, userPlaylists]: [Subject[], CnPlaylist[], UserPlaylist[]] | undefined[]) => {
                if (typeof subjects === 'undefined' && typeof cnPlaylists === 'undefined' && typeof userPlaylists === 'undefined') {
                    return;
                }

                const playlistSubjectIds = cnPlaylists!.map((p: CnPlaylist) => p.subject);
                this.playlistSubjects = subjects!
                    .filter((s: Subject) => playlistSubjectIds.indexOf(s.id) > -1)
                    .sort((a: Subject, b: Subject) => a.title < b.title ? -1 : 1);
            }
        });
    }

    public unlockScrolling(sidenav: any): void {
        enableBodyScroll(sidenav._elementRef.nativeElement);
    }
}
