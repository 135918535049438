import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';
import { ThinkDailyRoutingModule } from './think-daily-routing.module';
import { ThinkDailyServiceModule } from './think-daily-service.module';

import { ThinkDailyItemComponent } from './think-daily-item/think-daily-item.component';
import { ThinkDailyComponent } from './think-daily/think-daily.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,

        ThinkDailyRoutingModule,
        ThinkDailyServiceModule
    ],
    declarations: [
        ThinkDailyComponent,
        ThinkDailyItemComponent
    ],
    exports: [
        ThinkDailyItemComponent
    ]
})
export class ThinkDailyModule {}
