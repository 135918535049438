/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { LanguageTokenService } from '../../core';
import { LanguageToken } from '../../core/models';

import {
    ActionTypes,
    GetTokensFailure,
    GetTokensSuccess
} from './language.actions';

@Injectable({
    providedIn: 'root'
})
export class LanguageEffects {
    constructor(
        private actions: Actions,
        private languageTokenService: LanguageTokenService
    ) {}

    GetTokens: Observable<GetTokensSuccess> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.GET_TOKENS),
            switchMap(() => this.languageTokenService.getAll()),
            map((response: LanguageToken[]) => {
                const tokens = response.reduce((acc: any, t: LanguageToken) => {
                    acc[t.token] = t.value || t.default_value;

                    return acc;
                }, {});

                return new GetTokensSuccess(tokens);
            }),
            catchError((err: any) => of(new GetTokensFailure(err)))
        )
    );
}