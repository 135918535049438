import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MessageService } from '../../core';

import { MessageViewerComponent } from '../../shared/message-viewer/message-viewer.component';
import { Message } from '../models/message.model';

@Component({
    selector: 'cn-bulletin-board',
    templateUrl: './bulletin-board-widget.component.html',
    styleUrls: ['./bulletin-board-widget.component.css']
})
export class BulletinBoardWidgetComponent implements OnInit {
    @Input() public maxLength = 100;
    public messages: Message[];
    public pending = false;

    constructor(
        private dialog: MatDialog,
        private messageService: MessageService
    ) { }

    public ngOnInit(): void {
        this.pending = true;

        this.messageService.getAll()
            .subscribe((messages: Message[]) => {
                this.messages = messages.slice(0, 3);

                this.pending = false;
            });
    }

    public readMessage(item: Message): void {
        this.dialog.open(MessageViewerComponent, {
            data: { message: item.message }
        });
    }
}