import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ForItemDirective } from '../for-item/for-item.directive';

@Component({
    selector: 'cn-paginated-list',
    templateUrl: './paginated-list.component.html',
    styleUrls: ['./paginated-list.component.css']
})
export class PaginatedListComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() public currentPage = 0;
    @Input() public currentSize = 5;
    @Input() public data: any;
    public dataSource: MatTableDataSource<any>;
    @ContentChild(ForItemDirective, { read: TemplateRef }) public forItemTemplate: TemplateRef<any>;
    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Input() public pageSizes = [5, 10, 25, 50, 100];
    @Input() public pagination = true;
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    public ngAfterViewInit(): void {
        if (this.pagination) {
            this.dataSource.paginator = this.paginator;
        }
    }

    public ngOnChanges(): void {
        this.dataSource = new MatTableDataSource(this.data);

        if (this.pagination) {
            this.dataSource.paginator = this.paginator;
        }
    }

    public ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.data);
    }
}