import { Theme } from '../../core/models';

export type State = Theme;

export const initialState: State = {
    auth_background: '',
    dark_logo: '',
    light_logo: '',
    favicon: '',
    header_color: '',
    hide_register_sidebar: true,
    login_button_background: '',
    login_panel_background: '',
    require_company_info: false,
    require_registration_code: false,
    show_registration: false,
    support_email: '',
    title: '',
    use_tbs_login: false,
    wrap_courses: false,
    loaded: false
};