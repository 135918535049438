<div class="wait-for-theme-wrapper">
    <div *ngIf="!(course.wrapped && (wrapCourses$ | async)); else wrappedView" class="wrapper">
        <div class="course flex-column flex-start-center" (click)="handleNavigation()">
            <div class="thumbnail-wrapper">
                <img class="thumbnail" [src]="course.thumbnail">
                <div class="watched-indicator" *ngIf="owned && course.completed_content && course.completed_content === course.total_content">Watched</div>
                <div *ngIf="allowFavorites" class="favorite" [class.empty]="!course.favorite">
                    <mat-icon class="favorite-button" (click)="$event.stopPropagation(); toggleFavorite()">{{ course.favorite ? 'star' : 'star_border' }}</mat-icon>
                </div>
                <div *ngIf="showPlaylists$ | async" class="playlist">
                    <mat-icon class="playlist-button" (click)="$event.stopPropagation()" [matMenuTriggerFor]="playlistMenu">add_circle</mat-icon>
                </div> 
                <mat-menu #playlistMenu="matMenu">
                    <button mat-menu-item *ngIf="getValidPlaylists(playlists$ | async).length > 0" [matMenuTriggerFor]="existingMenu">Add to...</button>
                    <button mat-menu-item (click)="createPlaylistWithCourse()">Create New {{ 'PLAYLIST' | language | async | titlecase }}</button>
                </mat-menu>
                <mat-menu #existingMenu="matMenu">
                    <button mat-menu-item *ngFor="let playlist of getValidPlaylists(playlists$ | async)" (click)="addCourseToPlaylist(playlist)">{{ playlist.title }}</button>
                </mat-menu>
            </div>
            <div class="title" title="{{ course.title }}">{{ course.title | truncate:35 }}</div>
            <div *ngIf="showCourseIds$ | async" class="id">
                #{{ course.id }}
            </div>
            <div *ngIf="owned && course.total_content! > 1" class="progress">Progress: {{ course.completed_content }}/{{ course.total_content }}</div>
            <div class="flex-row flex-center-center">
                <div class="subject" [style.background-color]="course.color">{{ course.subject_title }}</div>
            </div>
            <div class="watched-date" *ngIf="owned && course.completed_content && course.completed_content === course.total_content">Watched on {{ course.recent_activity | date:'M/d/YYYY' }}</div>
        </div>
    </div>
    <ng-template #wrappedView>
        <div class="wrapper flex-row flex-center-center">
            <div (click)="unwrap()" class="wrapped flex-column flex-space-between-center">
                <img src="assets/mystery-envelope.svg">
                <div class="released-on">Released: {{ course.created_on | date: "MMM d" }}</div>
                <button mat-button class="unwrap" (click)="unwrap()">Free Content</button>
            </div>
        </div>
    </ng-template>
</div>