import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';

import { ContentCardWideComponent } from './content-card-wide/content-card-wide.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { CourseDisplayComponent } from './course-display/course-display.component';
import { CourseRatingDialogComponent } from './course-rating-dialog/course-rating-dialog.component';
import { InlineAudioComponent } from './inline-audio/inline-audio.component';
import { CoursePlayerComponent } from './course-player/course-player.component';

@NgModule({
    declarations: [
        ContentCardWideComponent,
        CourseCardComponent,
        CourseDisplayComponent,
        CourseRatingDialogComponent,
        InlineAudioComponent,
        CoursePlayerComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        ContentCardWideComponent,
        CourseCardComponent,
        CourseDisplayComponent,
        CourseRatingDialogComponent,
        InlineAudioComponent,
        CoursePlayerComponent
    ]
})
export class DashboardCoursesModule { }
