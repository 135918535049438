import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';

@Directive({
    /* eslint-disable-next-line @angular-eslint/directive-selector */
    selector: 'a[routerBack]'
})
export class RouterBackDirective {
    constructor(
        private location: Location
    ) { }

    @HostListener('click') public onClick(): void {
        this.location.back();
    }
}