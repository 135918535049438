import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';

import { ForItemDirective } from '../for-item/for-item.directive';

@Component({
    selector: 'cn-slide-container',
    templateUrl: './slide-container.component.html',
    styleUrls: ['./slide-container.component.css']
})
export class SlideContainerComponent {
    @Input() public displaySlides = 1;
    @Input() public fixedWidth = false;
    @ContentChild(ForItemDirective, { read: TemplateRef }) public forItemTemplate: TemplateRef<any>;
    public lastContainerWidth: number;
    @ViewChild('oneSlide') public oneSlide: any;
    public scrollPosition = 0;
    @ViewChild('slideContainer') public slideContainer: any;
    @Input() public slideData: any[] = [];

    public isDisabled(left: boolean): boolean {
        if (left) {
            return this.scrollPosition === 0;
        } else {
            return this.scrollPosition === this.slideData.length - this.displaySlides;
        }
    }
    public scroll(slidesForward: number): void {
        this.slideContainer.nativeElement.scrollLeft += slidesForward * this.oneSlide.nativeElement.clientWidth;
        this.scrollPosition += slidesForward;
    }
}
