import { Component } from '@angular/core';

@Component({
    templateUrl: 'not-found.component.html',
    styleUrls: ['not-found.component.css']
})
export class NotFoundComponent {
    public back(): void {
        window.history.go(-1);
    }
}