import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'range'
})
export class RangePipe implements PipeTransform {
    public transform(_input: any, start: number, end: number, step: number = 1): any {
        const range: number[] = [];
        for (let i = start; i <= end; i += step) {
            range.push(i);
        }

        return range;
    }
}