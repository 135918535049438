import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { Auth, Theme } from './root-store';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {
    constructor(
        private store: Store<Auth.State>
    ) {}

    public async init(): Promise<void> {
        let refreshToken = localStorage.getItem('refresh_token');

        if (!refreshToken) {
            // Iterate through keys in local storage to see if there's a refresh token from the old login system
            for (const key in localStorage) {
                if (localStorage.hasOwnProperty(key)) {
                    // Matches ex. CognitoIdentityServiceProvider.23g88li8qoncr9ma9dbgm30pnt.ad4236b9-4ae8-4153-84e5-722c634277b5.refreshToken
                    if (key.match(/CognitoIdentityServiceProvider\..*\.refreshToken/)) {
                        // We found a refresh token, get it and remove the old key
                        refreshToken = localStorage.getItem(key);
                        localStorage.removeItem(key);

                        // Check if there's a school object here as well
                        const schoolJson = localStorage.getItem('school');
                        if (schoolJson) {
                            try {
                                // Found a school object, parse it and set the key to just have the id
                                const school = JSON.parse(schoolJson);
                                localStorage.setItem('school', school.id);
                            } catch (e) {
                                // School failed to parse, just clear the key
                                localStorage.removeItem('school');
                            }
                        }
                        break;
                    }
                }
            }
        }

        this.store.dispatch(new Theme.Actions.GetTheme());

        if (refreshToken) {
            this.store.dispatch(new Auth.Actions.Authenticate({ refresh_token: refreshToken }));

            return new Promise<any>((resolve: any, reject: any) => {
                this.store.select(Auth.selectIsLoading).pipe(filter((isLoading: boolean) => isLoading), take(1)).subscribe(() => {
                    resolve();
                });
            });
        } else {

            return Promise.resolve();
        }
    }
}