import { Component, OnInit } from '@angular/core';

import { WidgetService } from '../../core';
import { WidgetData } from '../../core/models';

@Component({
    selector: 'cn-community-dashboard-widget',
    templateUrl: 'community-dashboard-widget.component.html',
    styleUrls: ['community-dashboard-widget.component.css']
})
export class CommunityDashboardWidgetComponent implements OnInit {
    public issues: { issue: string }[];

    constructor(
        private widgetService: WidgetService
    ) {}

    public ngOnInit(): void {
        this.widgetService.getData('community-dashboard')
            .subscribe((data: WidgetData) => {
                const issues = data.biggest_issues ? data.biggest_issues.slice(0, 5) : [];
                this.issues = issues.length > 0 && typeof issues[0] === 'string' ?
                    issues.map((i: string) => ({ issue: i })) :
                    this.issues = issues;
            });
    }
}