import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { ColorPickerModule } from 'ngx-color-picker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';

import { MaterialModule } from '../material/material.module';

import { ColumnSplitPipe } from './column-split/column-split.pipe';
import { CommentsComponent } from './comments/comments.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DataTableComponent, DataTableExpandedDirective } from './data-table/data-table.component';
import { DownloadCardComponent } from './download-card/download-card.component';
import { DualAxisChartComponent } from './dual-axis-chart/dual-axis-chart.component';
import { DurationPipe } from './duration/duration.pipe';
import { ExpirationFormatterDirective } from './expiration-formatter/expiration-formatter.directive';
import { FlippingCardComponent } from './flipping-card/flipping-card.component';
import { ForItemDirective } from './for-item/for-item.directive';
import { ForbiddenNumberValidatorDirective } from './forbidden-number-validator/forbidden-number-validator.directive';
import { HintDirective } from './intro/hint.directive';
import { IntroDirective } from './intro/intro.directive';
import { LanguagePipe } from './language/language.pipe';
import { LoadingComponent } from './loading/loading.component';
import { MessageViewerComponent } from './message-viewer/message-viewer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaginatedListComponent } from './paginated-list/paginated-list.component';
import { RangePipe } from './range/range.pipe';
import { RouterBackDirective } from './router-back/router-back.directive';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SelectAllComponent } from './select-all/select-all.component';
import { SlideContainerComponent } from './slide-container/slide-container.component';
import { StickyDirective } from './sticky/sticky.directive';
import { TableChartComponent } from './table-chart/table-chart.component';
import { TitleCasePipe } from './title-case/title-case.pipe';
import { TruncateCharactersPipe } from './truncate/truncate-characters.pipe';
import { TruncateWordsPipe } from './truncate/truncate-words.pipe';
import { UnwrapCourseDialogComponent } from './unwrap-course-dialog/unwrap-course-dialog.component';
import { VisualAmbitionCreationDialogComponent } from './visual-ambition-creation-dialog/visual-ambition-creation-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        MomentModule,
        NgxChartsModule,
        QuillModule.forRoot(),
        RouterModule
    ],
    exports: [
        ColorPickerModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        MomentModule,
        NgxChartsModule,
        NgxExtendedPdfViewerModule,
        NgxMatSelectSearchModule,
        QuillModule,
        RouterModule,

        DataTableExpandedDirective,
        ExpirationFormatterDirective,
        ForbiddenNumberValidatorDirective,
        ForItemDirective,
        HintDirective,
        IntroDirective,
        RouterBackDirective,
        StickyDirective,

        CommentsComponent,
        DataTableComponent,
        DownloadCardComponent,
        DurationPipe,
        FlippingCardComponent,
        LoadingComponent,
        PaginatedListComponent,
        SearchBarComponent,
        SelectAllComponent,
        SlideContainerComponent,
        TableChartComponent,

        ColumnSplitPipe,
        LanguagePipe,
        RangePipe,
        TruncateCharactersPipe,
        TruncateWordsPipe
    ],
    declarations: [
        ColumnSplitPipe,
        CommentsComponent,
        ConfirmationDialogComponent,
        DataTableComponent,
        DataTableExpandedDirective,
        DownloadCardComponent,
        DualAxisChartComponent,
        DurationPipe,
        ExpirationFormatterDirective,
        FlippingCardComponent,
        ForbiddenNumberValidatorDirective,
        ForItemDirective,
        HintDirective,
        IntroDirective,
        LanguagePipe,
        LoadingComponent,
        MessageViewerComponent,
        NotFoundComponent,
        PaginatedListComponent,
        RangePipe,
        RouterBackDirective,
        SearchBarComponent,
        SelectAllComponent,
        SlideContainerComponent,
        StickyDirective,
        TableChartComponent,
        TitleCasePipe,
        TruncateCharactersPipe,
        TruncateWordsPipe,
        UnwrapCourseDialogComponent,
        VisualAmbitionCreationDialogComponent
    ],
    providers: [
        DurationPipe
    ]
})
export class SharedModule { }
