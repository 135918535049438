import { routerReducer } from '@ngrx/router-store';

import { reducer as authReducer } from './auth';
import { reducer as categoryReducer } from './category';
import { reducer as languageReducer } from './language';
import { reducer as playlistReducer } from './playlist';
import { reducer as themeReducer } from './theme';

export const REDUCERS = {
    Auth: authReducer,
    Category: categoryReducer,
    Language: languageReducer,
    Playlist: playlistReducer,
    Router: routerReducer,
    Theme: themeReducer
};