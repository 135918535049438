import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Config, IConfig } from '../config/config.model';
import { Theme } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig,
        protected titleService: Title
    ) {
        this.apiUrl = config.apiUrl;
    }

    public getTheme(): Observable<Theme> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                if (!state.is_logged_in || !state.school) {
                    return this.http.get<Theme>(`${this.apiUrl}/theme`);
                } else {
                    return this.http.get<Theme>(`${this.apiUrl}/schools/${state.school.id}/theme`);
                }
            })
        );
    }

    public setTitle(title: string): void {
        this.titleService.setTitle(title);
    }

    public updateTheme(data: FormData): Observable<{ [key: string]: string }> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<any>(`${this.apiUrl}/admin/schools/${schoolId}/theme`, data))
        );
    }
}