<h2 mat-dialog-title>Congratulations!</h2>
<mat-dialog-content>
    <span>You've completed {{ course.title }}! Please take a minute to let us know how you enjoyed it.</span>
    <div class="stars flex-row flex-center-center">
        <mat-icon class="star" *ngFor="let i of [1, 2, 3, 4, 5]" (click)="setRating(i)">
            <ng-container *ngIf="i <= rating">star</ng-container>
            <ng-container *ngIf="i > rating">star_border</ng-container>
        </mat-icon>
    </div>
    <mat-form-field>
        <mat-label>Additional Comments?</mat-label>
        <textarea matInput cdkTextareaAutosize [cdkAutosizeMinRows]="3" class="comments" name="comments" [(ngModel)]="comments"></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>No Thanks</button>
    <button mat-button [disabled]="rating === 0" (click)="submit()">Submit</button>
</mat-dialog-actions>