import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Config, IConfig } from '../config/config.model';
import { LoginResponse, User } from '../models';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public authenticate(refreshToken: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.apiUrl}/auth/authenticate`, { refresh_token: refreshToken }, { params: { no_auth: 'true' } });
    }

    public completeNewPassword(newPassword: string, sub: string, session: string): Observable<void> {
        return this.http.post(`${this.apiUrl}/auth/forgot-password/complete`, { sub, session, new_password: newPassword }, { params: { no_auth: 'true' } }).pipe(
            map(() => undefined)
        );
    }

    public confirmPassword(email: string, verificationCode: string, newPassword: string): Observable<void> {
        return this.http.post(`${this.apiUrl}/auth/forgot-password/confirm`, { email, verification_code: verificationCode, password: newPassword }, { params: { no_auth: 'true' } }).pipe(
            map(() => undefined)
        );
    }

    public forgotPassword(email: string): Observable<void> {
        return this.http.post(`${this.apiUrl}/auth/forgot-password`, { email }, { params: { no_auth: 'true' } }).pipe(
            map(() => undefined)
        );
    }

    public login(email: string, password: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.apiUrl}/auth/login`, { email, password }, { params: { no_auth: 'true' } });
    }

    public register(user: Partial<User>, password: string, registrationCode: string): Observable<User> {
        return this.http.post<User>(`${this.apiUrl}/auth/register`, { user, password, registration_code: registrationCode }, { params: { no_auth: 'true' } });
    }

    public tbsLogin(accessToken: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.apiUrl}/auth/tbs-authenticate`, { access_token: accessToken });
    }
}