import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Content, Course } from '../../core/models';
import { CoursePlayerComponent } from '../course-player/course-player.component';

@Component({
    selector: 'cn-content-card-wide',
    templateUrl: './content-card-wide.component.html',
    styleUrls: ['./content-card-wide.component.css']
})
export class ContentCardWideComponent {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public complete = new EventEmitter<void>();
    @Input() public content: Content;
    @Input() public contentList: Content[];
    @Input() public course: Course;

    constructor(
        private dialog: MatDialog
    ) { }

    public play(): void {
        let initialContentIndex: number;
        const dialogRef = this.dialog.open(CoursePlayerComponent, { disableClose: true, panelClass: 'fullscreen-modal' });
        dialogRef.componentInstance.activeContentIndex = initialContentIndex = this.contentList.findIndex((c: Content) => c.id === this.content.id);
        dialogRef.componentInstance.mediaContent = this.contentList;
        dialogRef.componentInstance.course = this.course;
        dialogRef.afterClosed().subscribe(() => {
            if (this.contentList[initialContentIndex].completion === 100) {
                this.complete.emit();
            }
        });
    }
}
