<div class="wrapper flex-column flex-start-center">
    <div class="icon">
        <img src="assets/open-envelope.svg">
    </div>
    <div class="added">Course Added To {{ ('YOUR_LIBRARY' | language | async)! | titlecase }}:</div>
    <div class="title">"{{ course.title }}"</div>
    <div class="flex-column flex-row.gt-xs flex-center-center">
        <button mat-button class="now" (click)="close(true)">
            <mat-icon>play_arrow</mat-icon> Watch Now
        </button>
        <button mat-button (click)="close(false)">Watch Later</button>
    </div>
</div>