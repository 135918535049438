import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ResponseInterceptor } from './response.interceptor';
import { TokenInterceptor } from './token.interceptor';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    exports: [
        HttpClientModule
    ],
    declarations: [],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
    ]
})
export class CoreModule { }
