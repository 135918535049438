import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
    public action: string;
    public name: string;

    constructor(
        private dialog: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        if (data) {
            this.name = data.name;
            this.action = data.action;
        }
    }

    public confirm(): void {
        this.dialog.close(true);
    }
}
