import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CourseService } from '../../core';
import { Course } from '../../core/models';

@Component({
    templateUrl: 'course-rating-dialog.component.html',
    styleUrls: ['course-rating-dialog.component.css']
})
export class CourseRatingDialogComponent {
    public comments: string;
    public course: Course;
    public rating = 0;

    constructor(
        private courseService: CourseService,
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<CourseRatingDialogComponent>,
        private snackbar: MatSnackBar
    ) {
        this.course = data.course;
    }

    public setRating(rating: number): void {
        this.rating = rating;
    }

    public submit(): void {
        this.courseService.updateRating(this.course.id, this.rating, this.comments)
            .subscribe(
                () => {
                    this.dialogRef.close();
                },
                (err: any) => {
                    this.snackbar.open('There was an error submitting your response. Please contact Jamie Hammer if this continues to happen.', undefined, { duration: 3000 });
                }
            );
    }
}