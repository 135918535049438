import { Component, OnInit } from '@angular/core';

import { WidgetService } from '../../core';
import { WidgetData } from '../../core/models';

@Component({
    selector: 'cn-personal-goal-widget',
    templateUrl: './personal-goal-widget.component.html',
    styleUrls: ['./personal-goal-widget.component.css']
})
export class PersonalGoalWidgetComponent implements OnInit {
    public widgetData: WidgetData;

    private widgetName = 'personal-goal';

    constructor(
        private widgetService: WidgetService
    ) { }

    public ngOnInit(): void {
        this.widgetData = { dollars: '0', hours: '0' };
        this.widgetService.getData(this.widgetName)
            .subscribe((data: WidgetData) => {
                this.widgetData = data;
            });
    }

    public update(): void {
        this.widgetService.setData(this.widgetName, this.widgetData).subscribe();
    }
}
