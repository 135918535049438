import { Injectable, Type } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as widgetComponents from '../../';
import { WidgetService } from '../../../core';
import { Widget } from '../../../core/models';

@Injectable({
    providedIn: 'root'
})
export class DynamicWidgetLoaderService {

    constructor(
        private widgetService: WidgetService
    ) { }

    public getComponents(): Observable<{ component: Type<any>; id: number }[]> {
        return this.widgetService.getAll()
            .pipe(map((widgets: Widget[]) => {
                const components: { component: Type<any>; id: number }[] = [];
                widgets.forEach((eachWidget: Widget) => {
                    const widgetComponent = Object.entries(widgetComponents).find((c: [string, any]) => c[0].toLowerCase().includes(eachWidget.name.replace('-', '')));
                    if (widgetComponent) {
                        components.push({ id: eachWidget.id, component: widgetComponent[1] });
                    }
                });

                return components;
            }));
    }
}
