import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { CnPlaylist, UserPlaylist } from '../../core/models';

import { State } from './playlist.state';

export const selectPlaylists = createFeatureSelector<State>('Playlist');

export const selectCnLoaded = createSelector(selectPlaylists, (state: State) => state.cn_loaded);
export const selectUserLoaded = createSelector(selectPlaylists, (state: State) => state.user_loaded);

export const selectCnPlaylists = createSelector(selectPlaylists, (state: State) => state.cn_playlists);
export const selectCnPlaylistsForSubjects = (subjects: number[]): MemoizedSelector<State, CnPlaylist[]> => createSelector(selectCnPlaylists, (playlists: CnPlaylist[]) => playlists.filter((p: CnPlaylist) => subjects.indexOf(p.subject) > -1));
export const selectCnPlaylist = (playlistId: number): MemoizedSelector<State, CnPlaylist | undefined> => createSelector(selectCnPlaylists, (playlists: CnPlaylist[]) => {
    if (!playlists) {
        return undefined;
    }

    return playlists.find((p: CnPlaylist) => p.id === playlistId);
});

export const selectUserPlaylists = createSelector(selectPlaylists, (state: State) => state.user_playlists);
export const selectUserPlaylist = (playlistId: number): MemoizedSelector<State, UserPlaylist | undefined> => createSelector(selectUserPlaylists, (playlists: UserPlaylist[]) => {
    if (!playlists) {
        return undefined;
    }

    return playlists.find((p: UserPlaylist) => p.id === playlistId);
});