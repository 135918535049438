<a target="_blank" [href]="url" (click)="updateCompletion()">
    <div class="download flex-row flex-space-between-center">
        <div class="title-wrapper">
            <div class="title" title="{{ title }}">{{ title }}</div>
            <div class="info" *ngIf="type && size">{{ getFileTypeDisplay(type) }} | {{ getFileSizeDisplay(size) }}</div>
        </div>
        <div class="flex-row">
            <img class="thumbnail" *ngIf="thumbnail" src="{{thumbnail}}">
                <mat-icon *ngIf="type === 'link'; else download">launch</mat-icon>
                <ng-template #download>
                    <mat-icon>get_app</mat-icon>
                </ng-template>
        </div>
    </div>
</a>
