<div *ngIf="!chartOnly" class="flex-row flex-space-between-center">
    <div class="table-title">{{ title }}</div>
    <div>
        <button mat-button class="sort-button" (click)="save('sort', 'scroll')">Add Row</button>
    </div>
</div>
<div class="duplicate-warning" *ngIf="duplicateMonths.length > 0 && !chartOnly">Warning: Duplicate Month{{ duplicateMonths }}</div>
<div *ngIf="!chartOnly" id="table-wrapper" class="table-wrapper">
    <mat-table [dataSource]="dataObject.data">
        <ng-container *ngFor="let field of displayedColumns; let i = index" matColumnDef="{{ field }}">
            <ng-container *ngIf="field === 'Month'">
                <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span class="mobile-label">Date:</span>
                    <mat-form-field>
                        <mat-label>Select Month</mat-label>
                        <mat-select name="month" [(ngModel)]="item.month" (selectionChange)="save()" required>
                            <mat-option *ngFor="let month of months; let j = index" value="{{ j }}">
                                {{ month }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container *ngIf="field === 'Year'">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <mat-form-field>
                        <mat-label>Select Year</mat-label>
                        <mat-select name="year" [(ngModel)]="item.year" (selectionChange)="save()" required>
                            <mat-option *ngFor="let year of yearArray" value="{{ year }}">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container *ngIf="field !== ('Month' || 'Year')">
                <mat-header-cell *matHeaderCellDef >
                    <ng-container *ngIf="!allowLabels">{{ field }}</ng-container>
                    <mat-form-field *ngIf="allowLabels">
                        <input matInput [name]="field" [placeholder]="field" [(ngModel)]="dataObject.labels![i]" (change)="save()">    
                    </mat-form-field>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span class="mobile-label">{{ field }}:</span>
                    <mat-form-field *ngIf="!calculatedColumns[i]">
                        <span matPrefix>{{ dollars ? '$' : '' }}</span>
                        <input matInput [(ngModel)]="item[getFieldName(field)]" (change)="save()" required>
                    </mat-form-field>
                    <mat-form-field *ngIf="calculatedColumns[i]">
                        <span matPrefix>{{ dollars ? '$' : '' }}</span>
                        <input matInput [value]="calculate(field, item) || '' " (change)="save()" readonly="readonly">
                    </mat-form-field>
                </mat-cell>
            </ng-container>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
<div class="chart-wrapper" [class.right-labeled]="yAxisLabelRight" *ngIf="chartData[0] && chartData[0].series.length > 0">
    <cn-dual-axis-chart [width]="chartWidth" [height]="chartHeight" [results]="chartData" [resultsRight]="chartDataRight" [scheme]="colorScheme" [xAxis]="true" [yAxis]="true" [yAxisRight]="yAxisLabel" [showYAxisLabel]="yAxisLabel" [yAxisLabel]="yAxisLabel" [yAxisLabelRight]="yAxisLabelRight" [yAxisTickFormatting]="yAxisTickFormatting"
        [legend]="true" [legendPosition]="legendPosition" [legendTitle]="''" [timeline]="!chartOnly">
    </cn-dual-axis-chart>
</div>