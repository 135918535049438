import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * This directive provides an easy way to use the Hint attributes provided by IntroJS.
 * See https://introjs.com/docs/intro/attributes/ for more information.
 *
 * intro: The text to show when this step is activated.
 * step: The priority/order to show this element.
 * tooltipClass: The CSS class to apply to the tooltip.
 * highlightClass: The CSS class to apply to the helperLayer.
 * position: The position of the intro pop-up, relative to the element.
 * scrollTo: The element to scroll to, 'element', or 'tooltip'.
 * disableInteraction: Whether or not to disable interaction with elements while highlighted
 */
@Directive({
    selector: '[intro]' // eslint-disable-line @angular-eslint/directive-selector
})
export class IntroDirective implements OnInit {
    @Input() public disableInteraction: boolean;
    @Input() public highlightClass: string;
    @Input() public intro: string;
    @Input() public position: string;
    @Input() public scrollTo: string;
    @Input() public step: number;
    @Input() public tooltipClass: string;

    constructor(
        private el: ElementRef
    ) {}

    public ngOnInit(): void {
        if (this.intro) {
            this.el.nativeElement.setAttribute('data-intro', this.intro);
        }

        if (this.step) {
            this.el.nativeElement.setAttribute('data-step', this.step);
        }

        if (this.tooltipClass) {
            this.el.nativeElement.setAttribute('data-tooltipclass', this.tooltipClass);
        }

        if (this.highlightClass) {
            this.el.nativeElement.setAttribute('data-highlightclass', this.highlightClass);
        }

        if (this.position) {
            this.el.nativeElement.setAttribute('data-position', this.position);
        }

        if (this.scrollTo) {
            this.el.nativeElement.setAttribute('data-scrollto', this.scrollTo);
        }

        if (this.disableInteraction) {
            this.el.nativeElement.setAttribute('data-disable-interaction', this.disableInteraction);
        }
    }
}