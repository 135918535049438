import { Category } from '../../core/models';

export interface State {
    loaded: boolean;
    categories: Category[];
}

export const initialState: State = {
    loaded: false,
    categories: []
};