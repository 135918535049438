import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'cn-select-all',
    templateUrl: './select-all.component.html',
    styleUrls: ['./select-all.component.css']
})
export class SelectAllComponent {
    @Input() public allText = 'All';
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Input() public circleTrigger = false;
    @Input() public grouped = false;
    @Input() public groups: string[] = [];
    @Input() public options: { color: string; title: string; [key: string]: any }[] | { color: string; title: string; [key: string]: any }[][];
    @Input() public placeholder = '';
    @Input() public selectAllFilter = [true];
    @Input() public selected: any[];
    @Output() public selectedChange = new EventEmitter<any[]>();


    public emitChange(): void {
        this.selectedChange.emit(this.selected);
    }

    public handleSelectAllChange(index: number): void {
        if (this.grouped) {
            this.options[index].forEach((option: { color: string; title: string; [key: string]: any }) => {
                const foundIndex = this.selected.findIndex((value: { color: string; title: string; [key: string]: any }) => value.title === option.title);
                if (foundIndex === -1 && this.selectAllFilter[index]) {
                    this.selected.push(option);
                } else if (foundIndex > -1 && !this.selectAllFilter[index]) {
                    this.options.splice(foundIndex, 1);
                }
                this.selected = this.selected.slice();
            });
        } else {
            this.selected = this.selectAllFilter[0] ? this.options : [];
        }
        this.emitChange();
    }
}
