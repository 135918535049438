import { Action } from '@ngrx/store';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
    GET_TOKENS = '[Language] Get Tokens',
    GET_TOKENS_SUCCESS = '[Language] Get Tokens Success',
    GET_TOKENS_FAILURE = '[Language] Get Tokens Failure'
}

export class GetTokens implements Action {
    public readonly type = ActionTypes.GET_TOKENS;
}

export class GetTokensSuccess implements Action {
    public readonly type = ActionTypes.GET_TOKENS_SUCCESS;

    constructor(public payload: any) {}
}

export class GetTokensFailure implements Action {
    public readonly type = ActionTypes.GET_TOKENS_SUCCESS;

    constructor(public payload: any) {}
}

export type LanguageActions =
    | GetTokens
    | GetTokensSuccess
    | GetTokensFailure
    ;