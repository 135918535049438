import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Config, IConfig } from '../config/config.model';
import { IntervalService } from '../interval.service';
import { INetworkInformationService, NetworkInformationService } from '../network-information/network-information.service';

/* eslint-disable no-console */

// eslint-disable-next-line no-shadow
enum LogPriority {
    DEBUG = 0,
    INFO,
    WARNING,
    ERROR
}

interface Log {
    message: string;
    priority: number;
    timestamp: number;
}

@Injectable({
    providedIn: 'root'
})
export class Logger {
    private logGroup: string;
    private logStream: string;
    private logToConsole: boolean;
    private logToServer: boolean;
    private logUrl: string;

    constructor(
        private http: HttpClient,
        private interval: IntervalService,
        @Inject(Config) config: IConfig,
        @Inject(NetworkInformationService) private checkInternetService: INetworkInformationService
    ) {
        this.logUrl = config.loggingUrl;
        this.logToConsole = config.isDev;
        this.logToServer = !config.isDev;
        this.logGroup = config.loggingGroup;
        this.logStream = config.loggingStream;
        this.interval.setInterval(10000, () => {
            this.sendLog();
        });
    }

    public debug(message: string | Object): void {
        if (this.logToServer) {
            this.storeLog(LogPriority.DEBUG, message, Date.now());
        }

        if (this.logToConsole) {
            console.log(message);
        }
    }

    public error(message: string | Object): void {
        if (this.logToServer) {
            this.storeLog(LogPriority.ERROR, message, Date.now());
        }

        if (this.logToConsole) {
            console.error(message);
        }
    }

    public info(message: string | Object): void {
        if (this.logToServer) {
            this.storeLog(LogPriority.INFO, message, Date.now());
        }

        if (this.logToConsole) {
            console.log(message);
        }
    }

    public warning(message: string | Object): void {
        if (this.logToServer) {
            this.storeLog(LogPriority.WARNING, message, Date.now());
        }

        if (this.logToConsole) {
            console.warn(message);
        }
    }

    private async sendLog(): Promise<void> {
        if (await this.checkInternetService.hasInternet) {
            const storedLogs = localStorage.getItem('logs');
            localStorage.removeItem('logs'); // Remove current logs since we have them stored
            if (storedLogs) {
                let logs: Log[] = JSON.parse(storedLogs);

                const payload = {
                    group: this.logGroup,
                    stream: this.logStream,
                    logs
                };

                this.http.post(this.logUrl, JSON.stringify(payload), { headers: new HttpHeaders({ 'Authorization': '507cd92f-6e81-467b-b163-30c34faec1c4', 'Content-Type': 'application/json' }), observe: 'response', responseType: 'json' }).pipe(
                    map((res: HttpResponse<any>) => {
                        if (res.status !== 200) {
                            throw new Error('invalid status code');
                        } else {
                            return res.body;
                        }
                    })
                ).subscribe(
                    () => {
                        // Empty handler to fire request
                    },
                    (error: any) => {
                        // Get any logs that were added since the request was made, merge with the list that was sent to the server, and store back in local storage to try again
                        const newStoredLogs = localStorage.getItem('logs');
                        if (newStoredLogs) {
                            const newLogs: Log[] = JSON.parse(newStoredLogs);
                            logs = logs.concat(newLogs);
                        }
                        localStorage.setItem('logs', JSON.stringify(logs));
                    }
                );
            }
        }
    }

    private storeLog(priority: LogPriority, message: string | Object, timestamp: number): void {
        const messageString = typeof message === 'string' ? message : JSON.stringify(message);

        const log: Log = {
            message: messageString,
            priority,
            timestamp
        };

        let storedLogs: Log[] = [];
        if (localStorage.getItem('logs')) {
            storedLogs = JSON.parse(localStorage.getItem('logs') || 'null');
        }
        storedLogs.push(log);

        localStorage.setItem('logs', JSON.stringify(storedLogs));
    }
}
