import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { LoadingComponent } from '../../shared/loading/loading.component';

@Injectable({
    providedIn: 'root'
})
export class LoadingService{
    constructor(
        private dialog: MatDialog
    ) {}

    public changeText(loading: MatDialogRef<LoadingComponent>, message: string): void {
        loading.componentInstance.updateMessage(message);
    }

    public async dismiss(loading: MatDialogRef<LoadingComponent>): Promise<void> {
        return new Promise((resolve: any, reject: any) => {
            loading.afterClosed().subscribe(resolve);
            loading.close();
        });
    }

    public showLoading(message?: string): MatDialogRef<LoadingComponent> {
        return this.dialog.open(LoadingComponent, {
            data: {
                message: message || 'Loading...'
            },
            disableClose: true
        });
    }
}