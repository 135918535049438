import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Widget, WidgetData, WidgetSettings } from '../models/widget.model';

import { Config, IConfig } from '../config/config.model';

@Injectable({
    providedIn: 'root'
})
export class WidgetService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public getAll(): Observable<Widget[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Widget[]>(`${this.apiUrl}/schools/${schoolId}/widgets`))
        );
    }

    public getData(widgetName: string): Observable<WidgetData> {
        return this.store.select(Auth.selectAuth).pipe(
            filter((auth: Auth.State) => !!auth.school),
            take(1),
            map((auth: Auth.State) => auth.school ? auth.school.id : 0),
            switchMap((schoolId: number) => this.http.get<WidgetData>(`${this.apiUrl}/schools/${schoolId}/widgets/${widgetName}`))
        );
    }

    public getLink(widgetName: string): Observable<WidgetData> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.post<WidgetData>(`${this.apiUrl}/schools/${schoolId}/widgets/${widgetName}/quiz-link`, { quizName: widgetName }))
        );
    }

    public getSettings(widgetName: string): Observable<WidgetSettings> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<WidgetSettings>(`${this.apiUrl}/schools/${schoolId}/widgets/${widgetName}/settings`))
        );
    }

    public setData(widgetName: string, data: WidgetData): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.post<undefined>(`${this.apiUrl}/schools/${schoolId}/widgets/${widgetName}`, data))
        );
    }

    public updateSettings(widgetName: string, settings: WidgetSettings): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<undefined>(`${this.apiUrl}/schools/${schoolId}/widgets/${widgetName}/settings`, settings))
        );
    }

    public visualAmbitionPrint(name: string): Observable<{ key: string; url: string }> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<{ key: string; url: string }>(`${this.apiUrl}/schools/${schoolId}/widgets/visual-ambition/print?name=${name}`))
        );
    }
}