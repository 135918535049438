<ngx-charts-chart [view]="[width + rightAxisSpacing, height]" [showLegend]="legend" [legendOptions]="legendOptions" [activeEntries]="activeEntries" [animations]="animations" (legendLabelClick)="onClick($event)" (legendLabelActivate)="onActivate($event)" (legendLabelDeactivate)="onDeactivate($event)">
    <svg:g [attr.transform]="transform" class="line-chart chart">
        <svg:g ngx-charts-x-axis *ngIf="xAxis" [xScale]="xScale" [dims]="dims" [showGridLines]="showGridLines" [showLabel]="showXAxisLabel" [labelText]="xAxisLabel" [tickFormatting]="xAxisTickFormatting" [ticks]="xAxisTicks" (dimensionsChanged)="updateXAxisHeight($event)">
        </svg:g>
        <svg:g ngx-charts-y-axis *ngIf="yAxis" [yOrient]="Orientation.Left" [yScale]="yScale" [dims]="dims" [showGridLines]="showGridLines" [showLabel]="showYAxisLabel" [labelText]="yAxisLabel" [tickFormatting]="yAxisTickFormatting" [ticks]="yAxisTicks" [referenceLines]="referenceLines" [showRefLines]="showRefLines"
            [showRefLabels]="showRefLabels" (dimensionsChanged)="updateYAxisWidth($event)">
        </svg:g>
        <svg:g ngx-charts-y-axis *ngIf="yAxisRight" [yOrient]="Orientation.Right" [yScale]="yScaleRight" [dims]="dims" [showGridLines]="showGridLines" [showLabel]="showYAxisLabel" [labelText]="yAxisLabelRight" [tickFormatting]="yAxisTickFormatting" [ticks]="yAxisTicks" [referenceLines]="referenceLines"
            [showRefLines]="showRefLines" [showRefLabels]="showRefLabels" (dimensionsChanged)="updateYAxisWidth($event)">
        </svg:g>
        <svg:g>
            <svg:g *ngFor="let series of results; trackBy:trackBy" [@animationState]="'active'">
                <svg:g ngx-charts-line-series [xScale]="xScale" [yScale]="yScale" [colors]="colors" [data]="series" [activeEntries]="activeEntries" [scaleType]="scaleType" [curve]="curve" [rangeFillOpacity]="rangeFillOpacity" [hasRange]="hasRange" [animations]="animations" />
            </svg:g>
            <svg:g *ngFor="let series of resultsRight" [@animationState]="'active'">
                <svg:g ngx-charts-line-series [xScale]="xScale" [yScale]="yScaleRight" [colors]="colors" [data]="series" [activeEntries]="activeEntries" [scaleType]="scaleType" [curve]="curve" [rangeFillOpacity]="rangeFillOpacity" [hasRange]="hasRange" [animations]="animations" />
            </svg:g>
            <svg:g *ngIf="!tooltipDisabled" (mouseleave)="hideCircles()">
                <svg:g ngx-charts-tooltip-area [dims]="dims" [xSet]="xSet" [xScale]="xScale" [yScale]="yScaleCombined" [results]="combinedSeries" [colors]="colors" [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="seriesTooltipTemplate" (hover)="updateHoveredVertical($event)" />
                <svg:g *ngFor="let series of combinedSeries">
                    <svg:g ngx-charts-circle-series [xScale]="xScale" [yScale]="yScaleCombined" [colors]="colors" [data]="series" [scaleType]="scaleType" [visibleValue]="hoveredVertical" [activeEntries]="activeEntries" [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate" (select)="onClick($event, series)"
                        (activate)="onActivate($event)" (deactivate)="onDeactivate($event)" />
                </svg:g>
                <ng-template #tooltipTemplate let-model="model">
                    <xhtml:div class="area-tooltip-container">
                        <span class="tooltip-label">{{ model.series }} • {{ model.name | date }}</span>
                        <span class="tooltip-val">{{ yAxisTickFormatting(model.value) }}</span>
                    </xhtml:div>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model">
                    <xhtml:div class="area-tooltip-container">
                        <xhtml:div *ngFor="let tooltipItem of model" class="tooltip-item">
                            <span class="tooltip-item-color" [style.background-color]="tooltipItem.color">
                            </span>
                            {{ tooltipItem.series }}: {{ yAxisTickFormatting(tooltipItem.value) }}
                        </xhtml:div>
                    </xhtml:div>
                </ng-template>
            </svg:g>
        </svg:g>
    </svg:g>
    <svg:g ngx-charts-timeline *ngIf="timeline && scaleType !== 'ordinal'" [attr.transform]="timelineTransform" [results]="combinedSeries" [view]="[timelineWidth, height]" [height]="timelineHeight" [scheme]="scheme" [customColors]="customColors" [scaleType]="$any(scaleType)" [legend]="legend"
        (onDomainChange)="updateDomain($event)">
        <svg:g *ngFor="let series of results; trackBy:trackBy">
            <svg:g ngx-charts-line-series [xScale]="timelineXScale" [yScale]="timelineYScale" [colors]="colors" [data]="series" [scaleType]="scaleType" [curve]="curve" [hasRange]="hasRange" [animations]="animations" />
        </svg:g>
        <svg:g *ngFor="let series of resultsRight; trackBy:trackBy">
            <svg:g ngx-charts-line-series [xScale]="timelineXScale" [yScale]="timelineYScaleRight" [colors]="colors" [data]="series" [scaleType]="scaleType" [curve]="curve" [hasRange]="hasRange" [animations]="animations" />
        </svg:g>
    </svg:g>
</ngx-charts-chart>