import { ActionTypes, ThemeActions } from './theme.actions';
import { State, initialState } from './theme.state';

export function reducer(state: State = initialState, action: ThemeActions): State {
    switch (action.type) {
        case ActionTypes.GET_THEME_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                loaded: true
            };
        }
        case ActionTypes.UPDATE_THEME_SUCCESS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return { ...state };
        }
    }
}