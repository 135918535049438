import { Inject, InjectionToken, NgZone, inject } from '@angular/core';

import { CapacitorOTAUpdater, CapacitorOTAUpdaterPlugin } from 'capacitor-ota-updater';

import { AppService, IAppService } from '../app/app.service';
import { Config, IConfig } from '../config/config.model';
import { LoadingService } from '../loading/loading.service';

export interface IVersionService {
    checkForUpdate(): void;
    getVersion(): Promise<string>;
}

export class WebVersionService implements IVersionService {
    public checkForUpdate(): void {
        // Do nothing
    }

    public async getVersion(): Promise<string> {
        return Promise.resolve('');
    }
}

export class MobileVersionService implements IVersionService {
    constructor(
        private loadingService: LoadingService,
        @Inject(AppService) private appService: IAppService,
        private otaUpdater: CapacitorOTAUpdaterPlugin,
        @Inject(Config) private config: IConfig,
        private ngZone: NgZone
    ) {}

    public checkForUpdate(): void {
        this.sync();
        document.addEventListener('resume', () => {
            this.ngZone.run(() => {
                this.sync();
            });
        });
    }

    public async getVersion(): Promise<string> {
        const buildVersion = await this.appService.getVersion();

        return `${buildVersion}`.trim();
    }

    private sync(): void {
        if (!this.config.isDev) {
            const loading = this.loadingService.showLoading('Checking for updates');
            this.otaUpdater.sync().then(() => {
                loading.close();
            });
        }
    }
}

export function versionServiceFactory(loadingService: LoadingService, app: IAppService, otaUpdater: CapacitorOTAUpdaterPlugin, config: IConfig, ngZone: NgZone): IVersionService {
    return config.isMobile ? new MobileVersionService(loadingService, app, otaUpdater, config, ngZone) : new WebVersionService();
}

export const VersionService = new InjectionToken<IVersionService>('VersionService', { // eslint-disable-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    providedIn: 'root',
    factory: () => versionServiceFactory(inject(LoadingService), inject(AppService), CapacitorOTAUpdater, inject(Config), inject(NgZone))
});