import { WidgetData } from '../../core/models';

export interface VisualAmbitionSet extends WidgetData {
    created_on: number;
    data: VisualAmbitionData;
    name: string;
    updated_on: number;
    year: string;
}

export interface VisualAmbitionSetGroup {
    [key: string]: VisualAmbitionSet;
}

export interface VisualAmbitionChange {
    date_complete?: string;
    in_progress?: string;
    name?: string;
}

export interface VisualAmbitionYearlyData {
    last_year?: string;
    this_year?: string;
}

export interface VisualAmbitionData extends WidgetData {
    accounting: {
        changes: VisualAmbitionChange[];
        manager: string;
    };
    appt_center: {
        changes: VisualAmbitionChange[];
        manager: string;
        num_reps: VisualAmbitionYearlyData;
    };
    company_info: {
        adl: string;
        appts_run: string;
        new_sales: string;
        sales_goal: string;
        service_sales: string;
    };
    marketing: {
        appts_run: VisualAmbitionYearlyData;
        budget: VisualAmbitionYearlyData;
        changes: VisualAmbitionChange[];
        cpl: VisualAmbitionYearlyData;
        manager: string;
    };
    office: {
        changes: VisualAmbitionChange[];
        manager: '';
    };
    production: {
        changes: VisualAmbitionChange[];
        dollar_per_team_week: VisualAmbitionYearlyData;
        inside_manager: string;
        num_crews: VisualAmbitionYearlyData;
        outside_manager: string;
    };
    sales: {
        adl: VisualAmbitionYearlyData;
        changes: VisualAmbitionChange[];
        daily_sales: VisualAmbitionYearlyData;
        manager: string;
        monthly_sales: VisualAmbitionYearlyData;
        num_sales_people: VisualAmbitionYearlyData;
        service_types: { last_year?: string; name?: string; this_year?: string }[];
        total_sales: VisualAmbitionYearlyData;
        weekly_sales: VisualAmbitionYearlyData;
    };
    service: {
        appts_run: VisualAmbitionYearlyData;
        avg_dollar_per_stop: VisualAmbitionYearlyData;
        changes: VisualAmbitionChange[];
        dollar_per_stop: VisualAmbitionYearlyData;
        manager: string;
        num_annual_maintenances: VisualAmbitionYearlyData;
        num_service_men: VisualAmbitionYearlyData;
        service_sales: VisualAmbitionYearlyData;
    };
}

export const DEFAULT_DATA: VisualAmbitionData = {
    accounting: { changes: [{}], manager: '' },
    appt_center: { changes: [{}], manager: '', num_reps: {} },
    marketing: { appts_run: {}, changes: [{}], cpl: {}, manager: '', budget: {} },
    office: { changes: [{}], manager: '' },
    production: { changes: [{}], dollar_per_team_week: {}, inside_manager: '', num_crews: {}, outside_manager: '' },
    sales: { adl: {}, changes: [{}], daily_sales: {}, manager: '', monthly_sales: {}, num_sales_people: {}, service_types: [{}], total_sales: {}, weekly_sales: {} },
    company_info: { adl: '', appts_run: '', new_sales: '', sales_goal: '', service_sales: '' },
    service: { appts_run: {}, avg_dollar_per_stop: {}, changes: [{}], dollar_per_stop: {}, manager: '', num_annual_maintenances: {}, num_service_men: {}, service_sales: {} }
};