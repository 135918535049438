<div class="wrapper">
    <div class="header">
        <div class="flex-column flex-row-reverse-gt-sm flex-space-between-start">
            <img class="logo" src="assets/think-daily-logo.svg">
            <div class="info">
                <h3>Think Daily</h3>
                <p>Start your day with Think Daily from Larry Janesky. His daily messages motivate, educate, inspire, and initiate questions that he wants you to answer. <strong>Over 55,000 subscribers THINK and participate each day.</strong></p>
                <div class="actions">
                    <a mat-button class="get-think-daily-button" target="_blank" href="https://thinkdaily.com">Get Think Daily</a>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="filter-bar flex-row flex-wrap flex-space-between-center">
            <h5>Think Daily Messages</h5>
            <div class="flex-row flex-start-center">
                <span class="extra-label mat-slide-toggle-table" (click)="showBusiness = false">Everyone</span>
                <mat-slide-toggle class="tdb-toggle" [(ngModel)]="showBusiness">Business People</mat-slide-toggle>
            </div>
        </div>
        <div class="think-dailies flex-row flex-wrap">
            <cn-think-daily-item *ngFor="let item of (showBusiness ? businessThinkDailies : thinkDailies)" 
                [header]="item.header" [title]="item.title" [content]="item.content" [date]="item.date">
            </cn-think-daily-item>
        </div>
    </div>
</div>