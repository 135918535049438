import { Role, School, User, TbsUser } from '../../core/models';

export interface State {
    access_token: string | null;
    error: any | null;
    exp: number | null;
    id_token: string | null;
    is_loading: boolean;
    is_logged_in: boolean;
    refresh_token: string | null;
    roles: Role[] | null;
    school: School | null;
    schools: School[] | null;
    tbs_access_token: string | null;
    tbs_user: TbsUser | null;
    user: User | null;
}

export const initialState: State = {
    access_token: null,
    error: null,
    exp: null,
    id_token: null,
    is_logged_in: false,
    is_loading: false,
    refresh_token: null,
    roles: null,
    school: null,
    schools: null,
    tbs_access_token: null,
    tbs_user: null,
    user: null
};