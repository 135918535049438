import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
    selector: '[cnSticky]'
})
export class StickyDirective implements OnDestroy, OnInit {
    private _className = 'sticky';
    private _minY = 100;

    constructor(
        private element: ElementRef
    ) { }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('stickyClass') public set className(name: string) {
        this._className = name;
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('stickyMin') public set minY(y: number) {
        this._minY = y;
    }

    public handleScrollEvent(e: any): void {
        if (e.target.scrollingElement.scrollTop > this._minY) {
            this.element.nativeElement.classList.add(this._className);
        } else {
            this.element.nativeElement.classList.remove(this._className);
        }
    }

    public ngOnDestroy(): void {
        document.removeEventListener('scroll', this.handleScrollEvent.bind(this));
    }

    public ngOnInit(): void {
        document.addEventListener('scroll', this.handleScrollEvent.bind(this));
    }
}
