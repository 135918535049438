import { Component, OnInit } from '@angular/core';

import { WidgetService } from '../../core';
import { WidgetData } from '../../core/models';

@Component({
    selector: 'cn-cash-calculator-widget',
    templateUrl: './cash-calculator-widget.component.html',
    styleUrls: ['./cash-calculator-widget.component.css']
})
export class CashCalculatorWidgetComponent implements OnInit {
    public widgetData: WidgetData;

    private widgetName = 'cash-calculator';

    constructor(
        private widgetService: WidgetService
    ) { }

    public get profit(): number {
        const netMargin = this.parseNumber(this.widgetData.net_margin) / 100 || 0;

        return this.totalSales * netMargin;
    }

    public get totalSales(): number {
        const leads = this.parseNumber(this.widgetData.leads) || 0;
        const closingRatio = this.parseNumber(this.widgetData.closing_ratio) / 100 || 0;
        const avgSale = this.parseNumber(this.widgetData.avg_sale) || 0;
        const salePerCustomer = this.parseNumber(this.widgetData.sale_per_customer) || 0;

        return leads * closingRatio * avgSale * salePerCustomer;
    }

    public ngOnInit(): void {
        this.widgetData = { leads: '0', closing_ratio: '0', avg_sale: '0', sale_per_customer: '1', net_margin: '0' };
        this.widgetService.getData(this.widgetName)
            .subscribe((data: WidgetData) => {
                this.widgetData.leads = data.leads || '0';
                this.widgetData.closing_ratio = data.closing_ratio || '0';
                this.widgetData.avg_sale = data.avg_sale || '0';
                this.widgetData.sale_per_customer = data.sale_per_customer || '1';
                this.widgetData.net_margin = data.net_margin || '0';
            });
    }

    public update(): void {
        this.widgetService.setData(this.widgetName, this.widgetData).subscribe();
    }

    private parseNumber(input: string): number {
        input = input.replace(/[^\.0-9]/ig, '');

        return +input;
    }
}
