import { Action } from '@ngrx/store';
import { Theme } from '../../core/models';

export enum ActionTypes {
    GET_THEME = '[Theme] Get Theme',
    GET_THEME_SUCCESS = '[Theme] Get Theme Success',
    GET_THEME_FAILURE = '[Theme] Get Theme Failure',
    UPDATE_THEME = '[Theme] Update Theme',
    UPDATE_THEME_SUCCESS = '[Theme] Update Theme Success',
    UPDATE_THEME_FAILURE = '[Theme] Update Theme Failure'
}

export class GetTheme implements Action {
    public readonly type = ActionTypes.GET_THEME;
}

export class GetThemeSuccess implements Action {
    public readonly type = ActionTypes.GET_THEME_SUCCESS;

    constructor(public payload: Theme) { }
}

export class GetThemeFailure implements Action {
    public readonly type = ActionTypes.GET_THEME_FAILURE;

    constructor(public payload: any) { }
}

export class UpdateTheme implements Action {
    public readonly type = ActionTypes.UPDATE_THEME;

    constructor(public payload: { [key: string]: any }) { }
}

export class UpdateThemeSuccess implements Action {
    public readonly type = ActionTypes.UPDATE_THEME_SUCCESS;

    constructor(public payload: Partial<Theme>) {}
}

export class UpdateThemeFailure implements Action {
    public readonly type = ActionTypes.UPDATE_THEME_FAILURE;
}

export type ThemeActions =
    | GetTheme
    | GetThemeSuccess
    | GetThemeFailure
    | UpdateTheme
    | UpdateThemeSuccess
    | UpdateThemeFailure
    ;