import { inject, Inject, InjectionToken } from '@angular/core';

import { App, AppInfo, AppPlugin, AppState } from '@capacitor/app';

import { Config, IConfig } from '../config/config.model';

type StateChangeHandler = (isActive: boolean) => void;

export interface IAppService {
    getId(): Promise<string>;
    getVersion(): Promise<string>;
    onAppStateChange(handler: StateChangeHandler): void;
}

export class WebAppService implements IAppService {
    public async getId(): Promise<string> {
        return 'web';
    }

    public async getVersion(): Promise<string> {
        return 'web';
    }

    public onAppStateChange(handler: StateChangeHandler): void {
        return;
    }
}

export class MobileAppService implements IAppService {
    private handler: StateChangeHandler;

    constructor(
        @Inject('App') private app: AppPlugin
    ) {
        this.app.addListener('appStateChange', (state: AppState) => this.handler ? this.handler(state.isActive) : undefined);
    }

    public async getId(): Promise<string> {
        return this.app.getInfo().then((info: AppInfo) => info.id);
    }

    public async getVersion(): Promise<string> {
        return this.app.getInfo().then((info: AppInfo) => info.version);
    }

    public onAppStateChange(handler: StateChangeHandler): void {
        this.handler = handler;
    }
}

export function appServiceFactory(config: IConfig, app: AppPlugin): IAppService {
    return config.isMobile ? new MobileAppService(app) : new WebAppService();
}

export const AppService = new InjectionToken<IAppService>('AppService', {
    providedIn: 'root',
    factory: () => appServiceFactory(inject(Config), App)
});