<div class="wrapper">
    <div *ngIf="!pending; else loading">
        <cn-slide-container [slideData]="messages.slice(0, 3)" [displaySlides]="1" [fixedWidth]="true">
            <div *cnForItem="let message" class="slide flex-column flex-center-start">
                <div *ngIf="message.title" class="title" [innerHTML]="message.title | truncateWords:10"></div>
                <div class="message" [innerHTML]="message.message | truncateWords:maxLength" (click)="readMessage(message)"></div>
            </div>
        </cn-slide-container>
    </div>
    <ng-template #loading>
        Loading... <mat-spinner diameter="25"></mat-spinner>
    </ng-template>
</div>