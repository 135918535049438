import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, SchoolGuard } from '../core';
import { NotFoundComponent } from '../shared/not-found/not-found.component';

import { HeaderOnlyLayoutComponent } from './header-only-layout/header-only-layout.component';
import { StandardLayoutComponent } from './standard-layout/standard-layout.component';

const routes: Routes = [
    // Routes without specified layout
    { path: 'admin', loadChildren: async() => import('../admin/admin.module').then((m: any) => m.AdminModule) },
    { path: 'quizzes', loadChildren: async() => import('../quizzes/quizzes.module').then((m: any) => m.QuizzesModule) },
    { path: 'learn', loadChildren: async() => import('../cn-learn/cn-learn.module').then((m: any) => m.CnLearnModule) },
    { path: 'svg', loadChildren: async() => import('../svg/svg.module').then((m: any) => m.SvgModule) },

    // Routes with specified layouts
    {
        path: '',
        component: StandardLayoutComponent,
        canActivateChild: [AuthGuard, SchoolGuard],
        data: {
            exclude: [{ id: 12, redirect: '/learn' }, { id: 13, redirect: '/svg' }]
        },
        children: [
            { path: '', loadChildren: async() => import('../dashboard/dashboard.module').then((m: any) => m.DashboardModule) },
            { path: 'documents', loadChildren: async() => import('../documents/documents.module').then((m: any) => m.DocumentsModule) },
            { path: 'library', loadChildren: async() => import('../course-library/course-library.module').then((m: any) => m.CourseLibraryModule) },
            { path: 'marketplace', loadChildren: async() => import('../marketplace/marketplace.module').then((m: any) => m.MarketplaceModule) },
            { path: 'playlists', loadChildren: async() => import('../playlist/playlist.module').then((m: any) => m.PlaylistModule) },
            { path: 'profile', loadChildren: async() => import('../profile/profile.module').then((m: any) => m.ProfileModule) },
            // { path: 'social', loadChildren: async() => import('../forums/forums.module').then((m: any) => m.ForumsModule) },
            { path: 'sub-users', loadChildren: async() => import('../sub-users/sub-users.module').then((m: any) => m.SubUsersModule) },
            { path: 'tools', loadChildren: async() => import('../student-tools/student-tools.module').then((m: any) => m.StudentToolsModule) },
            { path: '', loadChildren: async() => import('../think-daily/think-daily.module').then((m: any) => m.ThinkDailyModule) }
        ]
    },
    {
        path: '',
        component: HeaderOnlyLayoutComponent,
        children: [
            { path: 'reports', canLoad: [AuthGuard, SchoolGuard], data: { exclude: [{ id: 12, redirect: '/learn' }] }, loadChildren: async() => import('../reports/reports.module').then((m: any) => m.ReportsModule) },
            { path: '', loadChildren: async() => import('../standalone/standalone.module').then((m: any) => m.StandaloneModule) },
            { path: '', loadChildren: async() => import('../auth/auth.module').then((m: any) => m.AuthModule) }
        ]
    },

    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutsRoutingModule { }
