import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { AnalyticsService, IVersionService, VersionService } from './core';

@Component({
    selector: 'cn-app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
    constructor(
        private analytics: AnalyticsService,
        @Inject(VersionService) private versionService: IVersionService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.router.events.pipe(
            filter((event: any) => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.analytics.record('page_view', { url: event.urlAfterRedirects });
        });

        // Check for live updates
        this.versionService.checkForUpdate();
    }
}
