import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ThinkDaily } from '../../../core/models';

import { ThinkDailyServiceModule } from '../../think-daily-service.module';

@Injectable({
    providedIn: ThinkDailyServiceModule
})
export class ThinkDailyService {
    constructor(
        private http: HttpClient
    ) {}

    public getThinkDaily(): Observable<ThinkDaily[]> {
        return this.http.get<ThinkDaily[]>(`https://www.larryjanesky.com/think_daily_feed.php?type=td`).pipe(
            map((thinkDailies: ThinkDaily[]) => thinkDailies.map((td: any) => {
                    let date = new Date(td.date);
                    if (Number.isNaN(date.getMonth())) {
                        const arr = td.date.split(/[- :]/);
                        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
                    }

                    td.date = date.getTime();

                    return td;
                })
            )
        );
    }

    public getThinkDailyBusiness(): Observable<ThinkDaily[]> {
        return this.http.get<ThinkDaily[]>(`https://www.larryjanesky.com/think_daily_feed.php?type=tdb`).pipe(
            map((thinkDailies: ThinkDaily[]) => thinkDailies.map((td: any) => {
                    let date = new Date(td.date);
                    if (Number.isNaN(date.getMonth())) {
                        const arr = td.date.split(/[- :]/);
                        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
                    }

                    td.date = date.getTime();

                    return td;
                })
            )
        );
    }
}
