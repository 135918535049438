<cn-widget-card>
    <ng-container class="title">Community Dashboard</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <ng-container class="content">
        <h5>Biggest Issues To Fix</h5>
        <ul class="issues">
            <li *ngFor="let issue of issues; let i = index">{{ i + 1 }}. {{ issue.issue }}</li>
        </ul>
        <div class="link">
            <a routerLink="/tools/community-dashboard">View Community Dashboard</a>
        </div>
    </ng-container>
</cn-widget-card>