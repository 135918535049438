import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Content, ContentCompletionResult, ContentNote, Course, CourseLearningGoal, ForumPost } from '../../core/models';
import { Config, IConfig } from '../config/config.model';

import { Auth } from '../../root-store';

@Injectable({
    providedIn: 'root'
})
export class CourseService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public createComment(text: string, courseId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.post<undefined>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/comments`, { text }))
        );
    }

    public createContentNote(courseId: number, contentId: number, note: string, timestamp: number): Observable<ContentNote> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.post<ContentNote>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/content/${contentId}/notes`, { note, timestamp }))
        );
    }

    public deleteContentNote(courseId: number, contentId: number, noteId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.delete<void>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/content/${contentId}/notes/${noteId}`))
        );
    }

    public favoriteCourse(courseId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<void>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/favorite`, { favorite: true }))
        );
    }

    public getAll(): Observable<Course[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Course[]>(`${this.apiUrl}/schools/${schoolId}/courses`))
        );
    }

    public getAllComments(courseId: number): Observable<ForumPost[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<ForumPost[]>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/comments`))
        );
    }

    public getAllUserDownloads(): Observable<Content[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Content[]>(`${this.apiUrl}/schools/${schoolId}/courses/downloads`))
        );
    }

    public getCourseContent(courseId: number): Observable<Content[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Content[]>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/content`))
        );
    }

    public getCourseContentNotes(courseId: number, contentId: number): Observable<ContentNote[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<ContentNote[]>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/content/${contentId}/notes`))
        );
    }

    public getCourseDownloads(courseId: number): Observable<Content[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Content[]>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/downloads`))
        );
    }

    public getCourseLearningGoals(courseId: number): Observable<CourseLearningGoal[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<CourseLearningGoal[]>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/goals`))
        );
    }

    public getNextUp(): Observable<Content[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Content[]>(`${this.apiUrl}/schools/${schoolId}/courses/next`))
        );
    }

    public getOne(courseId: number): Observable<Course> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Course>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}`))
        );
    }

    public getOwned(): Observable<Course[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Course[]>(`${this.apiUrl}/schools/${schoolId}/courses/owned`))
        );
    }

    public getUnowned(): Observable<Course[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Course[]>(`${this.apiUrl}/schools/${schoolId}/courses/unowned`))
        );
    }

    public purchaseCourse(courseId: number): Observable<void> {
        // TODO cash billing
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.post<undefined>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/purchase`, { type: 'token' }))
        );
    }

    public unfavoriteCourse(courseId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<void>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/favorite`, { favorite: false }))
        );
    }

    public unwrapCourse(courseId: number): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<undefined>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/unwrap`, {}))
        );
    }

    public updateContentCompletion(courseId: number, contentId: number, completion: number): Observable<ContentCompletionResult> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<ContentCompletionResult>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/content/${contentId}`, { completion }))
        );
    }

    public updateRating(courseId: number, rating: number, comments: string): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<undefined>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/rating`, { rating, comments }))
        );
    }

    public updateWatchData(courseId: number, contentId: number, uuid: number, data: TimeRanges): Observable<void> {
        const timeData: number[][] = [];
        for (let i = 0; i < data.length; ++i) {
            timeData.push([data.start(i), data.end(i)]);
        }

        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<void>(`${this.apiUrl}/schools/${schoolId}/courses/${courseId}/content/${contentId}/analytics`, { uuid, data: timeData }))
        );
    }

    public userOwnsCourse(courseId: number): Observable<boolean> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<boolean>(`${this.apiUrl}/schools/${schoolId}/courses/owned/${courseId}`))
        );
    }
}
