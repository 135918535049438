<cn-widget-card>
    <ng-container class="title">Staircase Goals</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <ng-container class="content">
        <ul class="goals">
            <li class="goal" *ngFor="let goal of frontGoals; let i = index">
                <span>{{ i + 1 }}.</span> {{ goal }}
            </li>
        </ul>
        <a routerLink="/tools/staircase-goals">Click here to revisit your goals</a>
    </ng-container>
</cn-widget-card>