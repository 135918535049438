<div class="course-wrapper">
    <ng-container *ngIf="grid; else slide">
        <div class="owned-header flex-column flex-row-gt-xs flex-space-between-start">
            <div>
                <ng-content select=".owned-list-header"></ng-content>
            </div>
            <div *ngIf="showFilters" class="filters flex-column-reverse flex-row-gt-xs flex-end-center">
                <mat-spinner *ngIf="filterPending" diameter="25"></mat-spinner>
                <div *ngIf="allowFavorites">
                    <div *ngTemplateOutlet="favoriteFilter"></div>
                </div>
                <div *ngTemplateOutlet="subjectFilters"></div>
                <div class="order-select">
                    <div *ngTemplateOutlet="orderSelect"></div>
                </div>
            </div>
        </div>
        <div class="courses flex-row flex-wrap flex-start-center">
            <div class="course-container flex-row flex-center-start" *ngFor="let item of paginatedCourses; trackBy: trackById" [style.max-width]="100 / columns + '%'">
                <cn-course-card class="course" [course]="item" [owned]="owned" [allowFavorites]="allowFavorites" [autoPlay]="autoPlay" [standalone]="standalone" [hideLibraryButton]="hideLibraryButton" (clicked)="fireCourseClicked(item.id)" (finishedPlaying)="fireFinishedPlaying(item.id)"></cn-course-card>
            </div>
            <div *ngIf="canAccessStore && displayCourses.length === 0">
                <div class="more-courses-wrapper flex-row flex-center-center">
                    <div class="more-courses flex-column flex-center-center">
                        <div>
                            {{ 'COURSES' | language | async | titlecase }} available in <a class="link" routerLink="/marketplace">Marketplace</a>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!canAccessStore && displayCourses.length === 0">
                <div class="more-courses-wrapper flex-row flex-center-center">
                    <div class="more-courses flex-column flex-center-center">
                        <div>
                            You have no {{ 'COURSES' | language | async | lowercase }}!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-paginator *ngIf="pageSize < displayCourses.length" [pageSize]="pageSize" [length]="displayCourses.length" [pageIndex]="pageIndex" (page)="handlePageChange($event)"></mat-paginator>
    </ng-container>
    <ng-template #slide>
        <cn-slide-container [slideData]="displayCourses" [displaySlides]="columns">
            <div class="header flex-column flex-row-gt-xs flex-space-between-start">
                <div>
                    <ng-content select=".course-list-header"></ng-content>
                </div>
                <div *ngIf="showFilters" class="filters flex-column flex-row-gt-sm flex-end-center">
                    <div *ngTemplateOutlet="subjectFilters"></div>
                    <div class="order-select">
                        <div *ngTemplateOutlet="orderSelect"></div>
                    </div>
                </div>
            </div>
            <div class="empty-placeholder">
                <div class="more-courses-wrapper flex-row flex-center-center">
                    <div class="more-courses flex-column flex-center-center">
                        <div *ngIf="canAccessStore">{{ 'COURSES' | language | async | titlecase }} available in <a class="link" routerLink="/marketplace">Marketplace!</a></div>
                        <div *ngIf="!canAccessStore">You should start receiving {{ 'COURSES' | language | async | lowercase }} shortly!</div>
                    </div>
                </div>
            </div>
            <cn-course-card *cnForItem="let item" class="slide" [course]="item" [owned]="owned" [allowFavorites]="allowFavorites" [hideLibraryButton]="hideLibraryButton" (clicked)="fireCourseClicked(item.id)" (finishedPlaying)="fireFinishedPlaying(item.id)"></cn-course-card>
        </cn-slide-container>
    </ng-template>
    <ng-template #favoriteFilter>
        <mat-checkbox name="favorite" [(ngModel)]="showOnlyFavorites" (change)="filterCourses()">Only show favorites</mat-checkbox>
    </ng-template>
    <ng-template #subjectFilters>
        <div *ngFor="let subjectType of subjectTypes">
            <div class="subject-filter">
                <cn-select-all [allText]="'All ' + subjectType.title" [circleTrigger]="true" [options]="subjectsByType[subjectType.id]" [placeholder]="subjectType.title" [(selected)]="selectedSubjects[subjectType.id]" (selectedChange)="filterCourses()"></cn-select-all>
            </div>
        </div>
    </ng-template>
    <ng-template #orderSelect>
        <mat-form-field appearance="outline">
            <mat-label>Order By</mat-label>
            <mat-select name="order" [(ngModel)]="order" (selectionChange)="sortCourses()">
                <mat-option *ngFor="let eachOrder of orderOptions" [value]="eachOrder">{{ eachOrder }}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>
</div>