<div class="wrapper">
    <cn-header class="header"></cn-header>
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sidenav class="sidenav" [class.minimized]="minimized" [mode]="sidebarMode" [opened]="screenSize > ScreenSize.sm" [fixedInViewport]="true" [fixedTopGap]="60" [disableClose]="screenSize > ScreenSize.sm" (mouseenter)="handleMouseOver(true)" (mouseleave)="handleMouseOver(false)" (closedStart)="unlockScrolling(sidenav)">
            <div class="sidenav-wrapper flex-column flex-space-between-start">
                <div>
                    <div class="links">
                        <a class="link flex-row flex-start-center" *ngIf="showDashboard$ | async" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="closeSidenav()">
                            <div class="icon">
                                <img src="assets/dashboard-icon.svg">
                            </div>
                            <span *ngIf="!minimized">Dashboard</span>
                        </a>
                        <a class="link flex-row flex-start-center" routerLink="/library" routerLinkActive="active" (click)="closeSidenav()">
                            <div class="icon">
                                <img src="assets/library-icon.svg">
                            </div>
                            <span *ngIf="!minimized">{{ 'YOUR_LIBRARY' | language | async | titlecase }}</span>
                        </a>
                        <a class="link flex-row flex-start-center" *ngIf="showPlaylists$ | async" routerLink="/playlists" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="closeSidenav()">
                            <div class="icon playlist-icon">
                                <mat-icon>playlist_play</mat-icon>
                            </div>
                            <span *ngIf="!minimized">{{ 'PLAYLISTS' | language | async | titlecase }}</span>
                        </a>
                        <div class="sublinks" *ngIf="playlistSubjects.length > 0" [@detailExpand]="minimized ? 'collapsed' : 'expanded'">
                            <a class="link" *ngFor="let subject of playlistSubjects" [routerLink]="['/playlists', subject.id]" routerLinkActive="active">{{ subject.title }}</a>
                        </div>
                        <a class="link flex-row flex-start-center" *ngIf="showPlaylists$ | async" routerLink="/playlists/my-playlists" routerLinkActive="active" (click)="closeSidenav()">
                            <div class="icon">
                                <mat-icon>queue_music</mat-icon>
                            </div>
                            <span *ngIf="!minimized">My {{ 'PLAYLISTS' | language | async | titlecase }}</span>
                        </a>
                        <div class="sublinks" *ngIf="(showPlaylists$ | async)" [@detailExpand]="minimized ? 'collapsed' : 'expanded'">
                            <div class="subheader">Recent {{ 'PLAYLISTS' | language | async | titlecase }}:</div>
                            <a class="link" *ngFor="let playlist of userPlaylists$ | async" routerLink="/playlists/my-playlists" [queryParams]="{ list: playlist.id }">{{ playlist.title }}</a>
                        </div>
                        <a class="link flex-row flex-start-center" *ngIf="widgetComponents.length > 0" routerLink="/tools" routerLinkActive="active"(click)="closeSidenav()">
                            <div class="icon">
                                <img src="assets/tools-icon.svg">
                            </div>
                            <span *ngIf="!minimized">Tools</span>
                        </a>
                        <a class="link flex-row flex-start-center" *ngIf="showStore" routerLink="/marketplace" routerLinkActive="active" (click)="closeSidenav()">
                            <div class="icon">
                                <img src="assets/store-icon.svg">
                            </div>
                            <span *ngIf="!minimized">Marketplace</span>
                        </a>
                        <a class="link flex-row flex-start-center" *ngIf="showThinkDaily$ | async" routerLink="/think-daily" routerLinkActive="active" (click)="closeSidenav()">
                            <div class="icon">
                                <img src="assets/think-daily-icon.svg">
                            </div>
                            <span *ngIf="!minimized">Think Daily</span>
                        </a>
                        <a class="link flex-row flex-start-center" *ngIf="showDownloads" routerLink="/documents" routerLinkActive="active" (click)="closeSidenav()">
                            <div class="icon">
                                <img src="assets/documents-icon.svg">
                            </div>
                            <span *ngIf="!minimized">Documents</span>
                        </a>
                        <a class="link flex-row flex-start-center" *ngIf="showSubUsers" routerLink="/sub-users" routerLinkActive="active" (click)="closeSidenav()">
                            <div class="icon">
                                <mat-icon>supervisor_account</mat-icon>
                            </div>
                            <span *ngIf="!minimized">{{ 'SUB_USERS' | language | async | titlecase }}</span>
                        </a>
                        <a class="link flex-row flex-start-center" (click)="logout()">
                            <div class="icon">
                                <mat-icon>exit_to_app</mat-icon>
                            </div>
                            <span *ngIf="!minimized">Log Out</span>
                        </a>
                    </div>
                    <div class="logo" [class.hidden]="minimized">
                        <img [src]="darkLogo$ | async">
                    </div>
                    <div *ngIf="!minimized" class="user-info">
                        <div class="user-name">{{ firstName$ | async }} {{ lastName$ | async }}</div>
                        <div class="user-email">{{ email$ | async }}</div>
                    </div>
                </div>
                <div *ngIf="!minimized" class="version">
                    {{ version }}
                </div>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="sidenav-content" id="sidenav-content">
            <div class="main">
                <router-outlet></router-outlet>
            </div>
            <cn-inline-audio></cn-inline-audio>
            <cn-footer></cn-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <button mat-icon-button class="open-menu-button" *ngIf="!sidenav.opened" (click)="sidenav.toggle(); lockScrolling(sidenav)">
        <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button class="close-menu-button" *ngIf="sidenav.opened && screenSize < ScreenSize.md" (click)="sidenav.toggle(); unlockScrolling(sidenav)">
        <mat-icon>close</mat-icon>
    </button>
</div>