import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LegendPosition, Color } from '@swimlane/ngx-charts';

@Component({
    selector: 'cn-table-chart',
    templateUrl: './table-chart.component.html',
    styleUrls: ['./table-chart.component.css']
})
export class TableChartComponent implements OnInit {
    @Input() public allowLabels = false;
    @Input() public calculate: (columnName: string, rowData: any) => string; // Use columnName as written in displayedColumns
    @Input() public calculatedColumns: boolean[];
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public change = new EventEmitter<void>();
    public chartData: { name: string; series: { name: string; value?: string | number }[] }[] = [];
    public chartDataRight: { name: string; series: { name: string; value?: string | number }[] }[] = [];
    @Input() public chartHeight: number;
    @Input() public chartOnly = false;
    @Input() public chartWidth: number;
    @Input() public colorScheme: string | Color = '';
    @Input() public dataObject: { data: any[]; labels?: string[] } = { data: [] };
    @Input() public displayedColumns: string[] = []; // Use display case (usually Title Case)
    @Input() public dollars: boolean; // Both axes as dollars or numbers
    public duplicateMonths: string[] = [];
    public legendPosition: LegendPosition;
    @Input() public limits: undefined | (undefined | number | number[])[] = undefined;
    public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    @Input() public switchToRight?: number;
    @Input() public title: string;
    @Input() public yAxisLabel: string;
    @Input() public yAxisLabelRight: string;
    public yearArray: number[] = [];

    constructor() {
        this.yAxisTickFormatting = this.yAxisTickFormatting.bind(this);
    }

    public get currentYear(): number {
        return new Date().getFullYear();
    }

    public getDuplicateMonths(): void {
        this.duplicateMonths = [];
        const tempSortedData = [...this.dataObject.data];
        tempSortedData.sort((a: any, b: any) => {
            if (a.year && b.year && a.year !== b.year) {
                return b.year - a.year;
            } else if (a.month && b.month) {
                return b.month - a.month;
            } else {
                return 1;
            }
        });
        tempSortedData.forEach((rowData: any, index: number) => {
            if (tempSortedData[index + 1] && rowData.year === tempSortedData[index + 1].year && rowData.month === tempSortedData[index + 1].month && rowData.month) {
                this.duplicateMonths.push(` ${this.months[rowData.month]} ${rowData.year}`);
            }
        });
    }

    public getFieldName(field: string): string {
        return field.replace(' ', '_').toLowerCase();
    }

    public labelDate(rowData: any): any {
        if (rowData.month && rowData.year) {
            const monthString = rowData.month > 8 ? `${+rowData.month + 1}` : `0${+rowData.month + 1}`;
            const dateString = `${rowData.year}-${monthString}-02`; // Idiosyncracy of ngx-charts, goes to previous date in display

            return new Date(dateString);
        }
    }

    public ngOnInit(): void {
        this.legendPosition = LegendPosition.Below;

        this.sortData();
        for (let i = 0; i < 11; i++) {
            this.yearArray.push(this.currentYear - i);
        }
        this.displayedColumns.forEach((columnName: string, index: number) => {
            if (index > 1) {
                if (index < (this.switchToRight || this.displayedColumns.length)) {
                    this.chartData.push({ name: columnName, series: [] });
                } else {
                    this.chartDataRight.push({ name: columnName, series: [] });
                }
            }
        });
        this.populateChart();
    }

    public populateChart(): void {
        this.chartData.forEach((columnData: any, index: number) => {
            columnData.name = this.dataObject.labels ? this.dataObject.labels[index + 2] || this.displayedColumns[index + 2] : this.displayedColumns[index + 2];
            columnData.series = [];
        });
        this.chartDataRight.forEach((columnData: any, index: number) => {
            columnData.name = this.dataObject.labels ? this.dataObject.labels[index + (this.switchToRight || 0)] || this.displayedColumns[index + (this.switchToRight || 0)] : this.displayedColumns[index + (this.switchToRight || 0)];
            columnData.series = [];
        });
        this.dataObject.data.forEach((rowData: any) => {
            if (rowData.month && rowData.year) {
                this.displayedColumns.forEach((columnName: string, index: number) => {
                    const rowValue = this.calculatedColumns[index] && this.calculate(columnName, rowData) ? this.calculate(columnName, rowData) : rowData[columnName.replace(' ', '_').toLowerCase()];
                    if (index > 1 && (rowValue || rowValue === 0)) { // Ignore month and year fields, leave out empty cells
                        if (index < (this.switchToRight || this.displayedColumns.length) && this.chartData[index - 2]) { // Supports multiple series for left and right axes; input switchToRight and pass right values after left
                            this.chartData[index - 2].series.unshift({ name: this.labelDate(rowData), value: +`${rowValue}`.replace(/[^0-9]/g, '') });
                        } else if (this.switchToRight) { // Unreachable if switchToRight unset, but typescript doesn't know that
                            this.chartDataRight[index - this.switchToRight].series.unshift({ name: this.labelDate(rowData), value: +`${rowValue}`.replace(/[^0-9]/g, '') });
                        }
                    }
                });
            }
        });
        if (this.switchToRight) { // Label y axes with corresponding fields
            this.yAxisLabel = this.yAxisLabel || this.displayedColumns.slice(2, this.switchToRight).join(', ');
            this.yAxisLabelRight = this.yAxisLabelRight || this.displayedColumns.slice(this.switchToRight).join(', ');
        } else if (!this.dollars) {
            this.yAxisLabel = this.yAxisLabel || this.displayedColumns.slice(2).join(', ');
        }
        this.chartData = [...this.chartData];
        this.chartDataRight = [...this.chartDataRight];
    }

    public save(sorting?: 'sort', scrolling?: 'scroll'): void {
        this.limitFields();

        if (sorting) {
            this.sortData();
            if (scrolling) {
                this.scrollToTop();
            }
        }
        this.populateChart();
        this.getDuplicateMonths();

        this.change.emit();
    }

    public scrollToTop(): void {
        const tableBig = document.getElementById('table-wrapper');
        // Container will not be null, but typescript doesn't know that
        if (tableBig) {
            tableBig.scrollTop = 0;
        }
    }

    public sortData(): void {
        this.dataObject.data.sort((a: any, b: any) => {
            if (a.year && b.year && a.year !== b.year) {
                return b.year - a.year;
            } else if (a.month && b.month) {
                return b.month - a.month;
            } else {
                return 1;
            }
        });

        if (this.dataObject.data.length === 0 || this.dataObject.data[0].month) {
            this.dataObject.data.unshift({});
        }
        this.dataObject.data = [...this.dataObject.data];
    }

    public yAxisTickFormatting(value: any): any {
        return this.dollars ? `$${value.toLocaleString()}` : `${value.toLocaleString()}`;
    }

    public yLeftAxisScale(min: any, max: any): any {
        return { min: `${min}`, max: `${max}` };
    }

    public yRightAxisScale(min: any, max: any): any {
        return { min: `${min}`, max: `${max}` };
    }

    private limitFields(): void {
        if (!this.limits) {
            return;
        }

        this.dataObject.data.forEach((data: any) => {
            this.displayedColumns.forEach((column: string, index: number) => {
                const field = this.getFieldName(column);
                // If there's no limit for this field or this row doesn't have a value for this field, do nothing
                if (!this.limits || !this.limits[index] || !data[field]) {
                    return;
                }

                let min = 0;
                let max = 0;
                if (typeof this.limits[index] === 'number') {
                    // Limit field is just a maximum
                    max = this.limits[index] as number;
                } else if (this.limits[index] instanceof Array) {
                    // Limit field is both minimum and maximum

                    // @ts-expect-error
                    min = this.limits[index]![0];
                    // @ts-expect-error
                    max = this.limits[index]![1];
                }

                // Clamp field to be between the min and the max
                data[field] = Math.min(Math.max(+data[field], min), max).toString();
            });
        });
    }
}
