import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Config, IConfig } from '../config/config.model';
import { TbsCompany, TbsUser } from '../models';

@Injectable({
    providedIn: 'root'
})
export class TbsService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public getCompanies(): Observable<TbsCompany[]> {
        return this.http.get<TbsCompany[]>(`${this.apiUrl}/tbs/companies`);
    }

    public getTbsUser(tbsId: number): Observable<TbsUser> {
        return this.http.get<TbsUser>(`${this.apiUrl}/tbs/user-info/${tbsId}`);
    }

    public refreshToken(token: string, date: string): Observable<{ access_token: string; date: string }> {
        return this.http.post<{ access_token: string; date: string }>(`${this.apiUrl}/tbs/refresh`, { access_token: token, date });
    }
}