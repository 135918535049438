import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';

import { Auth } from '../root-store';
import { AuthService } from './auth/auth.service';
import { LoginResponse } from './models';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private refreshTokenCache: Observable<string> | null = null;

    constructor(
        private store: Store<Auth.State>,
        private auth: AuthService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((auth: Auth.State) => {
                // If this request needs authentication and the token is going to expire soon, attempt to refresh it using the token in the store
                if (!request.params.has('no_auth') && auth.id_token && auth.refresh_token && auth.exp && (auth.exp * 1000 <= Date.now() + 360000)) {
                    if (!this.refreshTokenCache) {
                        // Cache the request so any new requests don't make another call to the endpoint
                        this.refreshTokenCache = this.auth.authenticate(auth.refresh_token).pipe(
                            map((response: LoginResponse) => {
                                this.store.dispatch(new Auth.Actions.RefreshToken({ access_token: response.access_token, exp: response.exp, id_token: response.id_token }));
                                this.refreshTokenCache = null;

                                return response.id_token;
                            }),
                            catchError((err: any) => {
                                this.store.dispatch(new Auth.Actions.Logout({ redirectUrl: '/login' }));

                                return throwError(err);
                            }),
                            publishReplay(1),
                            refCount()
                        );
                    }

                    return this.refreshTokenCache;
                } else {
                    return of(auth.id_token);
                }
            }),
            switchMap((token: string) => {
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: token
                        }
                    });
                } else {
                    request = request.clone();
                }

                return next.handle(request);
            })
        );
    }
}