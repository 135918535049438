import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import videojs from 'video.js';

import { AnalyticsService, CourseService, EventService, TimeoutService } from '../../core';
import { Content, ContentCompletionResult, ContentFile, Course } from '../../core/models';

@Component({
    selector: 'cn-inline-audio',
    templateUrl: 'inline-audio.component.html',
    styleUrls: ['inline-audio.component.css']
})
export class InlineAudioComponent implements OnInit, OnDestroy {
    public activeContentFile: ContentFile;
    public activeContentIndex = 0;
    @ViewChild('bar') public bar: ElementRef;
    public content: Content[];
    public course: Course;
    public initialized = false;
    public opened = false;
    public player: videojs.Player;
    @ViewChild('target') public target: ElementRef;
    public totalTime = '00:00';

    private autoplay = true;
    private firstTimeWatching: boolean;
    private hasCompleted = false;
    private lastScrubInput = 0;
    private videoIdentifier: number;

    constructor(
        private courseService: CourseService,
        private analytics: AnalyticsService,
        private timeout: TimeoutService,
        private events: EventService
    ) {}

    public get activeContent(): Content {
        return this.content[this.activeContentIndex];
    }

    public get currentTime(): string {
        if (!this.player) {
            return '00:00';
        }

        const seconds = Math.floor(this.player.currentTime());

        return this.getTimeString(seconds);
    }

    public get isPlaying(): boolean {
        if (!this.player) {
            return false;
        }

        return !this.player.paused();
    }

    public get scrubPercentage(): number {
        if (!this.player) {
            return 0;
        }

        return this.player.currentTime() / this.player.duration() * 100;
    }
    public set scrubPercentage(percentage: number) {
        if (!this.player) {
            return;
        }

        if (percentage === this.lastScrubInput) {
            return;
        }
        this.lastScrubInput = percentage;

        this.player.currentTime((percentage / 100) * this.player.duration());
    }

    public get volume(): number {
        if (!this.player) {
            return 1;
        }

        return this.player.volume() * 100;
    }
    public set volume(volume: number) {
        if (!this.player) {
            return;
        }

        this.player.volume(volume / 100);
    }

    public next(): void {
        if (!this.player) {
            return;
        }

        if (this.activeContentIndex < this.content.length - 1) {
            this.setContent(this.activeContentIndex + 1);
        }
    }

    public initialize(index: number): void {
        this.player = videojs(this.target.nativeElement, { autoplay: true });
        this.player.on(['pause', 'seeked'], () => this.handleMediaPaused());
        this.player.on('loadedmetadata', () => this.handleMediaLoaded());
        this.player.on('ended', () => this.handleMediaEnded());

        this.setContent(index);
    }

    public ngOnDestroy(): void {
        clearAllBodyScrollLocks();
    }

    public ngOnInit(): void {
        this.events.subscribe('inlineAudioCourse', (data: { content: Content[]; course: Course; index: number }) => {
            this.course = data.course;
            this.content = data.content;
            this.activeContentIndex = data.index;
            this.initialized = true;

            this.setContent(data.index);

            this.timeout.setTimeout(() => {
                this.initialize(data.index);
            }, 100);
        });

        this.events.subscribe('inlineAudioStop', () => {
            this.pause();
            if (this.player) {
                this.player.dispose();
            }
            this.initialized = false;
        });
    }

    public pause(): void {
        if (!this.player) {
            return;
        }

        this.player.pause();
    }

    public play(): void {
        if (!this.player) {
            return;
        }

        this.player.play();
    }

    public previous(): void {
        if (!this.player) {
            return;
        }

        if (this.player.currentTime() > 3) {
            this.player.currentTime(0);
        } else if (this.activeContentIndex > 0) {
            this.setContent(this.activeContentIndex - 1);
        }
    }

    public setContent(index: number, update?: boolean): void {
        if (update) {
            this.updateIfNeeded();
        }

        if (!this.activeContent || !this.activeContent.files) {
            return;
        }
        this.activeContentIndex = index;
        this.videoIdentifier = Date.now();

        if (this.activeContent) {
            this.activeContentFile = this.activeContent.files
                .sort((a: ContentFile, b: ContentFile) => {
                    if (a.content_type.indexOf('audio') > -1) {
                        return -1;
                    } else if (b.content_type.indexOf('audio') > -1) {
                        return 1;
                    }

                    return (a.resolution || '') < (b.resolution || '') ? -1 : 1;
                })[0];

            this.hasCompleted = false;
            this.firstTimeWatching = !this.activeContent.is_completed;

            if (this.player) {
                this.player.src({ type: this.activeContentFile?.content_type || '', src: this.activeContentFile?.url || '' });
            }
        }
    }

    public toggleOpen(): void {
        if (this.opened) {
            this.opened = false;
            enableBodyScroll(this.bar.nativeElement);
        } else {
            this.opened = true;
            disableBodyScroll(this.bar.nativeElement);
        }
    }

    public updateContentCompletion(contentId: number, completion: number): void {
        this.courseService.updateContentCompletion(this.course.id, contentId, completion)
            .subscribe((result: ContentCompletionResult) => {
                const mediaContent = this.content.find((c: Content) => c.id === contentId);
                if (mediaContent) {
                    mediaContent.completion = completion;
                }

                if (completion === 100 && !this.hasCompleted) {
                    this.hasCompleted = true;
                    this.sendCompletionEvent();
                }
            });

            this.courseService.updateWatchData(this.course.id, contentId, this.videoIdentifier, this.player.played()).subscribe();
    }

    private updateIfNeeded(): void {
        if (this.player && this.activeContent && this.activeContent.id) {
            const percentage = this.scrubPercentage;
            this.updateContentCompletion(this.activeContent.id, percentage);
        }
    }

    private getTimeString(allSeconds: number): string {
        const allMinutes = Math.floor(allSeconds / 60);
        const hours = Math.floor(allMinutes / 60);
        const minutes = allMinutes % 60;
        const seconds = allSeconds % 60;

        let timeString = '';

        if (hours > 0) {
            if (hours < 10) {
                timeString += '0';
            }
            timeString += `${hours}:`;
        }

        if (minutes < 10) {
            timeString += '0';
        }
        timeString += `${minutes}:`;

        if (seconds < 10) {
            timeString += '0';
        }
        timeString += `${seconds}`;

        return timeString;
    }

    private handleMediaEnded(): void {
        if (this.activeContent && this.activeContent.id) {
            this.updateContentCompletion(this.activeContent.id, 100);
        }

        if (this.content.length > this.activeContentIndex + 1) {
            if (this.autoplay) {
                this.setContent(this.activeContentIndex + 1);
            }
        }
    }

    private handleMediaLoaded(): void {
        const duration = this.player.duration();

        // Start from last saved location, or from the start if completion is 100
        if (this.activeContent && (!this.activeContent.completion || this.activeContent.completion < 100)) {
            const currentTime = (duration * ((this.activeContent.completion || 0) / 100)) / 1000;
            this.player.currentTime(currentTime - 10); // Ten seconds earlier than previous position
        }

        const totalTime = Math.floor(duration);
        this.totalTime = this.getTimeString(totalTime);
    }

    private handleMediaPaused(): void {
        this.updateIfNeeded();
    }

    private sendCompletionEvent(): void {
        const attributes: any = {
            content: this.activeContent ? this.activeContent.id : 0,
            media_type: 'audio',
            first_time: this.firstTimeWatching
        };
        this.analytics.record('content_complete', attributes);
    }
}