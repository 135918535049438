import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild } from '@angular/core';

@Component({
    selector: 'cn-flipping-card',
    templateUrl: './flipping-card.component.html',
    styleUrls: ['./flipping-card.component.css'],
    animations: [
        trigger('flip', [
            state('front', style({ transform: 'rotateY(0)' })),
            state('back', style({ transform: 'rotateY(180deg)' })),
            transition('* => *', animate('400ms ease-in-out'))
        ])
    ]
})
export class FlippingCardComponent {
    @ViewChild('back', { static: true }) public back: any;
    @ViewChild('front', { static: true }) public front: any;
    public state = 'front';

    public get height(): string {
        if (this.state === 'front') {
            return `${this.front.nativeElement.clientHeight}px`;
        }

        return `${this.back.nativeElement.clientHeight}px`;
    }

    public toggle(): void {
        this.state = this.state === 'front' ? 'back' : 'front';
    }
}