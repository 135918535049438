/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Actions, createEffect, concatLatestFrom, ofType } from '@ngrx/effects';

import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AnalyticsService, AuthService, EventService, SchoolService, TbsService } from '../../core';
import { LoginResponse, School, SchoolFeatures, TbsUser } from '../../core/models';

import * as moment from 'moment';

import {
    ActionTypes,
    Authenticate,
    AuthenticateFailure,
    AuthenticateSuccess,
    Login,
    LoginFailure,
    LoginSuccess,
    Logout,
    SetSchool,
    TbsAuthenticate,
    TbsAuthenticateFailure,
    TbsAuthenticateSuccess,
    UpdateSchool,
    UpdateSchoolFailure,
    UpdateSchoolSuccess,
    GetTbsUser,
    GetTbsUserFailure,
    GetTbsUserSuccess
} from './auth.actions';
import { selectSchoolFeatures, selectUserTbsId } from './auth.selectors';

import { Actions as LanguageActions } from '../language';
import { Actions as PlaylistActions } from '../playlist';
import { Actions as ThemeActions } from '../theme';

@Injectable({
    providedIn: 'root'
})
export class AuthEffects {
    constructor(
        private actions: Actions,
        private store: Store,
        private auth: AuthService,
        private schoolService: SchoolService,
        private tbsService: TbsService,
        private eventService: EventService,
        private analytics: AnalyticsService,
        private snackbar: MatSnackBar,
        private router: Router
    ) {}

    Login: Observable<LoginSuccess | LoginFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.LOGIN),
            map((action: Login) => action.payload),
            switchMap((payload: { email: string; password: string; redirectUrl: string }) => this.auth.login(payload.email, payload.password).pipe(
                    map((response: LoginResponse) => new LoginSuccess({ response, redirectUrl: payload.redirectUrl })),
                    catchError((err: any) => of(new LoginFailure({ error: err.error.error })))
                )
            )
        )
    );

    LoginSuccess: Observable<SetSchool | { type: string }> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.LOGIN_SUCCESS),
            map((action: LoginSuccess) => {
                // If the user needs to reset their password, navigate to the forgot password screen
                if (action.payload.response.reset_password) {
                    this.router.navigate(['/forgot-password'], { queryParams: { email: action.payload.response.email, challenge: 1, sub: action.payload.response.sub, session: action.payload.response.session, redirectTo: action.payload.redirectUrl } });

                    return { type: 'Empty' };
                }

                localStorage.setItem('refresh_token', action.payload.response.refresh_token);

                if (action.payload.response.tbs_access_token) {
                    localStorage.setItem('tbs_access_token', action.payload.response.tbs_access_token);

                    const date = moment().format('DD-MM-YYYY');
                    localStorage.setItem('tbs_access_token_date', date);
                }

                if (action.payload.response.schools.length > 1) {
                    this.router.navigate(['/schools'], { queryParams: { redirectTo: action.payload.redirectUrl }});

                    return { type: 'Empty' };
                } else {
                    return new SetSchool({ school: action.payload.response.schools[0].id, redirectUrl: action.payload.redirectUrl });
                }
            })
        )
    );

    LoginFailure: Observable<LoginFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.LOGIN_FAILURE),
            tap((action: LoginFailure) => {
                switch (action.payload.error.code) {
                    case 'NotAuthorizedException':
                    case 'UserNotFoundException':
                        this.snackbar.open('Invalid email or password.', undefined, { duration: 3000 });
                        break;
                    case 'NoSchoolsException':
                        this.snackbar.open('Your account seems to be set up improperly. Please contact Software Support at (800) 640-1500 or softwaresupport@contractornation.com.', undefined, { duration: 3000 });
                        break;
                    default:
                        this.snackbar.open('Error while signing in.', undefined, { duration: 3000 });
                        break;
                }
            })
        )
    , { dispatch: false });

    SetSchool: Observable<ThemeActions.GetTheme | LanguageActions.GetTokens | GetTbsUser> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.SET_SCHOOL),
            concatLatestFrom((action: SetSchool) => [
                this.store.select(selectSchoolFeatures),
                this.store.select(selectUserTbsId)
            ]),
            switchMap(([action, features, tbsId]: [SetSchool, SchoolFeatures, number]) => {
                localStorage.setItem('school', action.payload.school.toString());
                if (action.payload.redirectUrl) {
                    this.router.navigate([action.payload.redirectUrl]);
                }

                this.analytics.record('login');

                const toReturn: any[] = [
                    new ThemeActions.GetTheme(),
                    new LanguageActions.GetTokens()
                ];

                if (features.use_tbs_login && tbsId) {
                    toReturn.push(new GetTbsUser({ tbs_id: tbsId }));
                }

                if (features.use_playlists) {
                    toReturn.push(new PlaylistActions.GetCnPlaylists());
                    toReturn.push(new PlaylistActions.GetUserPlaylists());
                }

                return toReturn;
            })
        )
    );

    Logout: Observable<Logout> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.LOGOUT),
            tap((action: Logout) => {
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('school');

                this.eventService.emit('logout');
                this.router.navigate(['/login'], { queryParams: { redirectTo: action.payload.redirectUrl } });
            })
        )
    , { dispatch: false });

    Authenticate: Observable<any> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.AUTHENTICATE),
            map((action: Authenticate) => action.payload),
            switchMap((payload: { refresh_token: string }) => this.auth.authenticate(payload.refresh_token)),
            map((response: LoginResponse) => new AuthenticateSuccess({ response })),
            catchError((err: any) => of(new AuthenticateFailure({ error: err })))
        )
    );

    AuthenticateSuccess: Observable<SetSchool | { type: string }> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.AUTHENTICATE_SUCCESS),
            map((action: AuthenticateSuccess) => {
                localStorage.setItem('refresh_token', action.payload.response.refresh_token);

                const storedSchool = JSON.parse(localStorage.getItem('school') || '0');
                if (storedSchool && action.payload.response.schools.findIndex((s: School) => s.id === storedSchool) > -1) {
                    return new SetSchool({ school: storedSchool });
                } else if (storedSchool) {
                    localStorage.removeItem('school');
                }

                if (action.payload.response.schools.length > 1) {
                    this.router.navigate(['/schools'], { queryParams: { redirectTo: action.payload.redirectUrl } });

                    return { type: 'Empty' };
                } else {
                    return new SetSchool({ school: action.payload.response.schools[0].id, redirectUrl: action.payload.redirectUrl });
                }
            })
        )
    );

    AuthenticateFailure: Observable<Logout> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.AUTHENTICATE_FAILURE),
            map(() => new Logout({ redirectUrl: '/' }))
        )
    );

    TbsAuthenticate: Observable<TbsAuthenticateSuccess | TbsAuthenticateFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.TBS_AUTHENTICATE),
            map((action: TbsAuthenticate) => action.payload),
            switchMap((payload: { access_token: string; redirectUrl?: string }) => this.auth.tbsLogin(payload.access_token).pipe(
                map((response: LoginResponse) => new TbsAuthenticateSuccess({ response, redirectUrl: payload.redirectUrl })),
                catchError((err: any) => of(new TbsAuthenticateFailure({ error: err.error.error })))
            ))
        )
    );

    TbsAuthenticateSuccess: Observable<SetSchool | { type: string }> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.TBS_AUTHENTICATE_SUCCESS),
            map((action: TbsAuthenticateSuccess) => {
                localStorage.setItem('refresh_token', action.payload.response.refresh_token);

                if (action.payload.response.tbs_access_token) {
                    localStorage.setItem('tbs_access_token', action.payload.response.tbs_access_token);

                    const date = moment().format('DD-MM-YYYY');
                    localStorage.setItem('tbs_access_token_date', date);
                }

                const storedSchool = JSON.parse(localStorage.getItem('school') || '0');
                if (storedSchool && action.payload.response.schools.findIndex((s: School) => s.id === storedSchool) > -1) {
                    return new SetSchool({ school: storedSchool, redirectUrl: action.payload.redirectUrl });
                } else if (storedSchool) {
                    localStorage.removeItem('school');
                }

                if (action.payload.response.schools.length > 1) {
                    this.router.navigate(['/schools'], { queryParams: { redirectTo: action.payload.redirectUrl } });

                    return { type: 'Empty' };
                } else {
                    return new SetSchool({ school: action.payload.response.schools[0].id, redirectUrl: action.payload.redirectUrl });
                }
            })
        )
    );

    TbsAuthenticateFailure: Observable<Logout> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.TBS_AUTHENTICATE_FAILURE),
            map(() => new Logout({ redirectUrl: '/' }))
        )
    );

    UpdateSchool: Observable<UpdateSchoolSuccess | UpdateSchoolFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.UPDATE_SCHOOL),
            switchMap((action: UpdateSchool) => {
                const observables: Observable<any>[] = [
                    this.schoolService.updateOne(action.payload.school).pipe(map(() => action.payload.school))
                ];

                if (action.payload.dark_logo) {
                    const formData = new FormData();
                    formData.append('logo', action.payload.dark_logo);
                    observables.push(this.schoolService.updateDarkLogo(action.payload.school.id || 0, formData));
                } else {
                    observables.push(of(''));
                }

                if (action.payload.light_logo) {
                    const formData = new FormData();
                    formData.append('logo', action.payload.light_logo);
                    observables.push(this.schoolService.updateLightLogo(action.payload.school.id || 0, formData));
                } else {
                    observables.push(of(''));
                }

                if (action.payload.default_network_logo) {
                    const formData = new FormData();
                    formData.append('logo', action.payload.default_network_logo);
                    observables.push(this.schoolService.updateDefaultNetworkLogo(action.payload.school.id || 0, formData));
                } else {
                    observables.push(of(''));
                }

                if (action.payload.favicon) {
                    const formData = new FormData();
                    formData.append('favicon', action.payload.favicon);
                    observables.push(this.schoolService.updateFavicon(action.payload.school.id || 0, formData));
                } else {
                    observables.push(of(''));
                }

                if (action.payload.auth_background) {
                    const formData = new FormData();
                    formData.append('background', action.payload.auth_background);
                    observables.push(this.schoolService.updateAuthBackground(action.payload.school.id || 0, formData));
                } else {
                    observables.push(of(''));
                }

                return forkJoin(observables);
            }),
            map(([school, dark_logo, light_logo, default_network_logo, favicon, auth_background]: [Partial<School>, string, string, string, string, string]) => new UpdateSchoolSuccess({ school, dark_logo, light_logo, default_network_logo, favicon, auth_background })),
            catchError((err: any) => of(new UpdateSchoolFailure(err)))
        )
    );

    UpdateSchoolSuccess: Observable<UpdateSchoolSuccess> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.UPDATE_SCHOOL_SUCCESS),
            tap(() => this.snackbar.open('School updated', undefined, { duration: 3000 }))
        )
    , { dispatch: false });

    UpdateSchoolFailure: Observable<UpdateSchoolFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.UPDATE_SCHOOL_FAILURE),
            tap(() => this.snackbar.open('Error updating school', undefined, { duration: 3000 }))
        )
    , { dispatch: false });

    GetTbsUser: Observable<GetTbsUserSuccess | GetTbsUserFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.GET_TBS_USER),
            map((action: GetTbsUser) => action.payload),
            switchMap((payload: { tbs_id: number }) => this.tbsService.getTbsUser(payload.tbs_id).pipe(
                map((response: TbsUser) => new GetTbsUserSuccess({ ...response })),
                catchError((err: any) => of(new GetTbsUserFailure({ error: err.error.error })))
            ))
        )
    );
}