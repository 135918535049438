/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ThemeService } from '../../core';
import { Theme } from '../../core/models';

import { ActionTypes, GetThemeFailure, GetThemeSuccess, UpdateTheme, UpdateThemeFailure, UpdateThemeSuccess } from './theme.actions';

@Injectable({
    providedIn: 'root'
})
export class ThemeEffects {
    constructor(
        private actions: Actions,
        private themeService: ThemeService
    ) {}

    GetTheme: Observable<GetThemeSuccess | GetThemeFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.GET_THEME),
            switchMap(() => this.themeService.getTheme()),
            map((response: Theme) => new GetThemeSuccess(response)),
            catchError((err: any) => of(new GetThemeFailure(err)))
        )
    );

    GetThemeSuccess: Observable<GetThemeSuccess> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.GET_THEME_SUCCESS),
            tap((action: GetThemeSuccess) => {
                this.themeService.setTitle(action.payload.title);

                if (action.payload.favicon) {
                    const favicon = document.getElementById('app-icon') as HTMLLinkElement;
                    if (favicon) {
                        favicon.href = action.payload.favicon;
                    }
                }
            })
        )
    , { dispatch: false });

    UpdateTheme: Observable<UpdateThemeSuccess | UpdateThemeFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.UPDATE_THEME),
            switchMap((action: UpdateTheme) => {
                const formData = new FormData();

                for (const key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        formData.set(key, action.payload[key]);
                    }
                }

                return this.themeService.updateTheme(formData)
            }),
            map((theme: any) => new UpdateThemeSuccess(theme)),
            catchError(() => of(new UpdateThemeFailure()))
        )
    );
}