import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { CourseService } from '../../core';
import { ForumPost } from '../../core/models';

@Component({
    selector: 'cn-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnChanges {
    public comments: ForumPost[] = [];
    @Input() public courseId: number;
    @Output() public currentComment = new EventEmitter<string>();
    public haveCommentsLoaded = false;
    public isPosting = false;
    public newCommentText = '';
    @Input() public reload: boolean; // Programmatically reload comments from parent component
    public userAvatar$: Observable<string>;
    public userId$: Observable<number>;

    constructor(
        private store: Store<Auth.State>,
        private courseService: CourseService
    ) {
        this.userAvatar$ = this.store.select(Auth.selectUserAvatar).pipe(map((avatar: string | undefined) => avatar || ''));
        this.userId$ = this.store.select(Auth.selectUserId).pipe(map((id: number | undefined) => id || 0));
    }

    public addComment(): void {
        this.isPosting = true;
        this.courseService.createComment(this.newCommentText, this.courseId)
            .subscribe(
                () => {
                    this.newCommentText = '';
                    this.startComment(); // So that parent knows comment has been posted
                    this.loadComments();
                    this.isPosting = false;
                },
                (err: any) => {
                    this.isPosting = false;
                }
            );
    }

    public ngOnChanges(): void {
        this.loadComments();
    }

    public startComment(): void {
        this.currentComment.emit(this.newCommentText);
    }

    private loadComments(): void {
        this.comments = [];
        this.haveCommentsLoaded = false;
        this.courseService.getAllComments(this.courseId)
            .subscribe((comments: ForumPost[]) => {
                this.comments = comments.map((c: ForumPost) => {
                    c.text = (c.text || '').replace(/\n/ig, '<br />');

                    return c;
                });
                this.haveCommentsLoaded = true;
            });
    }
}
