import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
    selector: '[cnExpirationFormatter]'
})
export class ExpirationFormatterDirective implements OnInit {
    private el: HTMLInputElement;

    constructor(
        private elementRef: ElementRef
    ) {
        this.el = this.elementRef.nativeElement;
    }

    public ngOnInit(): void {
        this.el.value = this.transform(this.el.value);
    }

    @HostListener('input', ['$event.target.value'])
    public onBlur(value: string): void {
        this.el.value = this.transform(value);
    }

    private transform(value: string): string {
        if (value.length <= 2) {
            return value;
        }

        if (value[2] === '/') {
            return value;
        } else {
            const part1 = value.slice(0, 2);
            const part2 = value.slice(2);

            return `${part1}/${part2}`;
        }
    }
}