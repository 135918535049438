<mat-card appearance="outlined" class="card">
    <ng-content select=".header-img"></ng-content>
    <h2 mat-card-title>
        <ng-content select=".title"></ng-content>
    </h2>
    <mat-card-content>
        <div class="content" [style.height]="height" [@flip]="state">
            <div class="side front" #front>
                <ng-content select=".front"></ng-content>
            </div>
            <div class="side back" #back>
                <ng-content select=".back"></ng-content>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-icon-button *ngIf="back.clientHeight > 0" (click)="toggle()">
            <mat-icon>info</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>