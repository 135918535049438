<cn-widget-card>
    <ng-container class="title">Production Backlog in Weeks</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <div class="content">
        <div class="chart-wrapper" *ngIf="!pending; else loading">
            <cn-table-chart [chartHeight]="222" [chartOnly]="true" [calculatedColumns]="calculatedColumns"
                [colorScheme]="colors" [dataObject]="data" [displayedColumns]="displayedColumns" [dollars]="false"
                yAxisLabel="Weeks Behind"></cn-table-chart>
            </div>
            <a *ngIf="showLink" routerLink="/tools/production-backlog">Add/Edit Production Backlog Data</a>
        <ng-template #loading>
            Loading your data... <mat-spinner diameter="25"></mat-spinner>
        </ng-template>
    </div>
</cn-widget-card>