import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'cn-search-bar',
    templateUrl: 'search-bar.component.html',
    styleUrls: ['search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
    @Input() public filterFunction: (searchTerm: string) => void;
    public isSearching = false;
    @ViewChild('searchInput') public searchInput: ElementRef;
    public searchSubject = new Subject<string>();

    public apply(term: string): void {
        if (this.filterFunction) {
            this.filterFunction(term);
        }
    }

    public ngOnInit(): void {
        this.searchSubject
            .pipe(
                debounceTime(300)
            )
            .subscribe((term: string) => {
                this.apply(term);
                this.isSearching = false;
            });
    }

    public search(searchTerm: string, resendExisting?: boolean): void {
        const term = resendExisting ? this.searchInput.nativeElement.value : searchTerm;
        this.searchSubject.next(term);
        this.isSearching = true;
    }
}