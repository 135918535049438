<div class="wrapper">
    <div class="header flex-row flex-wrap flex-space-between-center">
        <div class="title-wrapper flex-row flex-start-center">
            <span class="title">Think Daily</span>
            <mat-icon class="help-icon" #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltip="Think Daily and Think Daily for Businesspeople are Larry’s daily thought-provoking and motivating messages.  Find them here every day!">help_outline</mat-icon>
        </div>
        <div class="flex-row">
            <span class="extra-label mat-slide-toggle-label" (click)="business = false">Everyone</span>
            <mat-slide-toggle class="tdb-toggle" [(ngModel)]="business">Business People</mat-slide-toggle>
        </div>
    </div>
    <cn-think-daily-item class="think-daily" *ngFor="let item of business ? businessThinkDailies : thinkDailies" [header]="item.header" [title]="item.title" [content]="item.content" [date]="item.date"></cn-think-daily-item>
</div>