<div class="header">
    <ng-content select=".header"></ng-content>
</div>
<div class="slide-wrapper flex-row flex-space-between-center">
    <div class="paddles flex-column flex-center-center" *ngIf="slideData.length > displaySlides">
        <button mat-icon-button class="left-paddle paddle" (click)="scroll(-1)" [disabled]="isDisabled(true)">
            <mat-icon class="paddle-icon">keyboard_arrow_left</mat-icon>
        </button>
    </div>
    <div id="empty-placeholder" *ngIf="slideData.length === 0">
        <ng-content select=".empty-placeholder"></ng-content>
    </div>
    <div id="slide-container" class="slide-container flex-row" #slideContainer>
        <div *ngFor="let item of slideData; let index = index" #oneSlide [style.width.px]="fixedWidth ? slideContainer.clientWidth / displaySlides : undefined">
            <ng-container *ngTemplateOutlet="forItemTemplate; context: {$implicit: item, index: index}"></ng-container>
        </div>
    </div>
    <div class="paddles flex-column flex-center-center" *ngIf="slideData.length > displaySlides">
        <button mat-icon-button class="right-paddle paddle" (click)="scroll(1)" [disabled]="isDisabled(false)">
            <mat-icon class="paddle-icon">keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>