<cn-widget-card>
    <ng-container class="title">Team Strength</ng-container>
    <ng-container class="drag-handle">
        <ng-content></ng-content>
    </ng-container>
    <div class="content flex-column">
        <div class="content-wrapper flex-row" *ngIf="!pending; else loading">
            <div class="results" *ngIf="!noResults">
                <div class="team-wrapper" *ngFor="let item of teamnumbers">
                    <div class="team flex-row flex-start-center">
                        <div class="team-icon">
                            <img class="logo" src="assets/{{ item.title | lowercase }}.svg" />
                        </div>
                        <div class="team-value">{{ item.value }}</div>
                        <div class="bar-container">
                            <div class="bar-background"></div>
                            <div class="bar {{ item.title }}-back" [style.width]="(item.value / barEnd) * 100 + '%'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="noResults">
                You have no employee results.
            </div>
        </div>
        <a routerLink="/tools/team-strength">View Team Strength Dashboard</a>
        <ng-template #loading>
            Loading your data... <mat-spinner diameter="25"></mat-spinner>
        </ng-template>
    </div>
</cn-widget-card>