import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Forum, ForumCategory, ForumPost, ForumThread } from '../models/forum.model';
import { User } from '../models/user.model';

import { Config, IConfig } from '../config/config.model';
import { Role } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ForumService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public createCategory(category: ForumCategory, forumId: number): Observable<ForumCategory> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;
                if (isAdmin) {
                    return this.http.post(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories`, { category });
                } else {
                    return of({});
                }
            })
        );
    }

    public createForum(forum: Forum): Observable<Forum> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;
                if (isAdmin) {
                    return this.http.post(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums`, { forum });
                } else {
                    return of({});
                }
            })
        );
    }

    public createPost(post: ForumPost, forumId: number, categoryId: number, threadId: number): Observable<ForumPost> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.post(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads/${threadId}/posts`, { post });
            })
        );
    }

    public createThread(thread: ForumThread, forumId: number, categoryId: number): Observable<ForumThread> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.post(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads`, { thread });
            })
        );
    }

    public deleteCategory(id: number, forumId: number): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;
                if (isAdmin) {
                    return this.http.delete<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${id}`);
                } else {
                    return of(undefined);
                }
            })
        );
    }

    public deleteForum(id: number): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;
                if (isAdmin) {
                    return this.http.delete<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${id}`);
                } else {
                    return of(undefined);
                }
            })
        );
    }

    public deletePost(id: number, forumId: number, categoryId: number, threadId: number): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.delete<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads/${threadId}/posts/${id}`);
            })
        );
    }

    public deleteThread(id: number, forumId: number, categoryId: number): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.delete<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads/${id}`);
            })
        );
    }

    public getAllForums(): Observable<Forum[]> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<Forum[]>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums`);
            })
        );
    }

    public getCategories(forumId: number): Observable<ForumCategory[]> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<ForumCategory[]>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories`);
            })
        );
    }

    public getCategoryById(categoryId: number, forumId: number): Observable<ForumCategory> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}`);
            })
        );
    }

    public getForumById(forumId: number): Observable<Forum> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}`);
            })
        );
    }

    public getForums(): Observable<Forum[]> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<Forum[]>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums`);
            })
        );
    }

    public getPosts(forumId: number, categoryId: number, threadId: number): Observable<ForumPost[]> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<ForumPost[]>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads/${threadId}/posts`);
            })
        );
    }

    public getThreadById(threadId: number, forumId: number, categoryId: number): Observable<ForumThread> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads/${threadId}`);
            })
        );
    }

    public getThreads(forumId: number, categoryId: number): Observable<ForumThread[]> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<ForumThread[]>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads`);
            })
        );
    }

    public getUserDrafts(userId: number): Observable<ForumPost[]> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<ForumPost[]>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/user-profile/${userId}/drafts`);
            })
        );
    }

    public getUserDraftsByThread(userId: number, threadId: number): Observable<ForumPost[]> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<ForumPost[]>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/user-profile/${userId}/thread/${threadId}/drafts`);
            })
        );
    }

    public getUserProfileById(userId: number): Observable<User> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<User>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/user-profile/${userId}`);
            })
        );
    }

    public search(filters?: any): Observable<{ posts: ForumPost[]; threads: ForumThread[] }> {
        let filterString = '';
        const filterArray: string[] = [];
        if (filters) {
            for (const filter in filters) {
                if (filters.hasOwnProperty(filter)) {
                    filterArray.push(`${filter}=${filters[filter]}`);
                }
            }
            filterString = `?${filterArray.join('&')}`;
        }

        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.get<{ posts: ForumPost[]; threads: ForumThread[] }>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/search${filterString}`);
            })
        );
    }

    public updateCategory(category: ForumCategory, forumId: number): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;
                if (isAdmin) {
                    return this.http.put<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${category.id}`, category);
                } else {
                    return of(undefined);
                }
            })
        );
    }

    public updateForum(forum: Forum): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;
                if (isAdmin) {
                    return this.http.put<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forum.id}`, forum);
                } else {
                    return of(undefined);
                }
            })
        );
    }

    public updatePost(post: ForumPost, forumId: number, categoryId: number, threadId: number): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.put<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads/${threadId}/posts/${post.id}`, post);
            })
        );
    }

    public updateThread(thread: ForumThread, forumId: number, categoryId: number): Observable<void> {
        return this.store.select(Auth.selectAuth).pipe(
            take(1),
            switchMap((state: Auth.State) => {
                const isAdmin = state.roles ? state.roles.findIndex((r: Role) => r.name === 'admin-forums') > -1 : false;

                return this.http.put<undefined>(`${this.apiUrl}${isAdmin ? '/admin' : ''}/schools/${state.school ? state.school.id : 0}/forums/${forumId}/categories/${categoryId}/threads/${thread.id}`, thread);
            })
        );
    }
}
