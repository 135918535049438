import { HttpClient } from '@angular/common/http';
import { InjectionToken, inject, Injectable, Inject } from '@angular/core';

import { ActionPerformed, PushNotifications, PushNotificationsPlugin, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Store } from '@ngrx/store';
import { combineLatest, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { AppService, IAppService } from '../app/app.service';
import { Config, IConfig } from '../config/config.model';
import { DeviceService, IDeviceService } from '../device/device.service';

type ActionHandler = (action: PushNotificationSchema) => void;

export interface IPushNotificationService {
    init(): void;
    registerActionHandler(handler: ActionHandler): void;
}

@Injectable()
export class WebPushNotificationService implements IPushNotificationService {
    public init(): void {
        return;
    }

    public registerActionHandler(handler: ActionHandler): void {
        return;
    }
}

@Injectable()
export class MobilePushNotificationService implements IPushNotificationService {
    private actionHandlers: ActionHandler[] = [];
    private apiUrl: string;

    constructor(
        @Inject('PushNotifications') private pushNotifications: PushNotificationsPlugin,
        @Inject(DeviceService) private device: IDeviceService,
        @Inject(AppService) private app: IAppService,
        private store: Store<Auth.State>,
        private http: HttpClient,
        @Inject(Config) private config: IConfig
    ) {
        this.apiUrl = this.config.apiUrl;
    }

    public async init(): Promise<void> {
        await this.pushNotifications.addListener('registration', async(token: Token) => this.handleRegistration(token.value));
        await this.pushNotifications.addListener('registrationError', (err: any) => this.handleError(err));
        await this.pushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => this.handleNotification(notification));
        await this.pushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => this.handleNotificationAction(action));

        let permStatus = await this.pushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await this.pushNotifications.requestPermissions();
        }

        if (permStatus.receive === 'granted') {
            await this.pushNotifications.register();
        }
    }

    public registerActionHandler(handler: ActionHandler): void {
        this.actionHandlers.push(handler);
    }

    private handleError(err: any): void {
        // TODO: handle error
    }

    private handleNotification(data: PushNotificationSchema): void {
        // TODO: handle notification
    }

    private handleNotificationAction(notification: ActionPerformed): void {
        this.actionHandlers.forEach((handler: ActionHandler) => {
            handler(notification.notification);
        });
    }

    private handleRegistration(token: string): void {
        const platformPromise = this.device.platform.then((platform: string) => {
            if (platform === 'ios' && this.config.isDev) {
                return 'ios-dev';
            }

            return platform;
        });

        combineLatest([from(this.device.uuid), from(platformPromise), from(this.app.getId()), this.store.select(Auth.selectUserId)]).pipe(
            switchMap(([uuid, platform, bundle, userId]: [string, string, string, number]) => this.http.post(`${this.apiUrl}/push/register`, { uuid, platform, bundle, user: userId, token }))
        ).subscribe();
    }
}

export function pushNotificationServiceFactory(config: IConfig, push: PushNotificationsPlugin, device: IDeviceService, app: IAppService, store: Store<any>, http: HttpClient): IPushNotificationService {
    return config.isMobile ?
        new MobilePushNotificationService(push, device, app, store, http, config) :
        new WebPushNotificationService();
}

export const PushNotificationService = new InjectionToken<IPushNotificationService>('PushNotificationService', {
    providedIn: 'root',
    factory: () => pushNotificationServiceFactory(inject(Config), PushNotifications, inject(DeviceService), inject(AppService), inject(Store), inject(HttpClient))
});