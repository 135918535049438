<div class="wrapper">
    <h3 mat-dialog-title>Create New Poster</h3>
    <mat-dialog-content>
        <form #creationForm="ngForm">
            <mat-form-field [class.ng-invalid]="alreadyExists" [class.mat-input-invalid]="alreadyExists" [class.mat-form-field-invalid]="alreadyExists">
                <mat-label>Poster Name</mat-label>
                <input matInput name="name" id="name" [class.ng-invalid]="alreadyExists" [(ngModel)]="name" required>
            </mat-form-field>
            <div class="error">
                <span *ngIf="alreadyExists">Name is too similar to an existing poster!</span>
            </div>
            
            <mat-form-field>
                <mat-label>Year of Poster</mat-label>
                <mat-select name="year" id="year" [(ngModel)]="year" required>
                    <mat-option *ngFor="let row of possibleYears" [value]="row">{{ row }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button [disabled]="alreadyExists || !creationForm.valid" (click)="create()">Create</button>
        <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</div>