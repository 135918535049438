import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { INetworkInformationService, NetworkInformationService } from './network-information/network-information.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkGuard  {
    constructor(
        @Inject(NetworkInformationService) private network: INetworkInformationService
    ) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.network.hasInternet;
    }

    public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }
}