export const environment = {
    apiUrl: '/api',
    authPermissionsUrl: 'https://w2h9xb4sy2.execute-api.us-east-1.amazonaws.com/prod',
    cognitoClientId: '23g88li8qoncr9ma9dbgm30pnt',
    cognitoIdentityPoolId: 'us-east-1:502d566a-b731-4e55-8e5b-d9acf9fca2b1',
    cognitoPoolId: 'us-east-1_6vcZmlR2x',
    isDesktop: false,
    isDev: false,
    isMobile: false,
    isWeb: true,
    loggingUrl: 'https://cnauth.contractornation.com/Logger',
    loggingGroup: 'test',
    loggingStream: 'test',
    platform: 'Web'
};
