<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sorting">{{ column.title }}</th>
        <td mat-cell *matCellDef="let element" [innerHtml]="column.transform ? column.transform(element[column.field], element) : element[column.field]"></td>
        <td mat-footer-cell *matFooterCellDef [innerHtml]="getFooterField(column)"></td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
            <div class="element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                <ng-container *ngTemplateOutlet="templateRef; context: { element: element, data: expandedData }"></ng-container>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnFields"></tr>
    <tr mat-row *matRowDef="let element; columns: columnFields;"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandRow(element)">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr mat-footer-row [class.flex-hide]="!footer" *matFooterRowDef="columnFields"></tr>
</table>
<mat-paginator *ngIf="pagination" [pageSize]="currentPageSize" [pageSizeOptions]="pageSizes"></mat-paginator>