import { InjectionToken } from '@angular/core';

import { environment } from '../../../environments/environment';

export const Config = new InjectionToken<IConfig>('Config', { // eslint-disable-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    providedIn: 'root',
    factory: () => environment
});

export interface IConfig {
    apiUrl: string;
    authPermissionsUrl: string;
    cognitoClientId: string;
    cognitoIdentityPoolId: string;
    cognitoPoolId: string;
    isDesktop: boolean;
    isDev: boolean;
    isMobile: boolean;
    isWeb: boolean;
    loggingGroup: string;
    loggingStream: string;
    loggingUrl: string;
    platform: string;
}