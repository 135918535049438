import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: 'loading.component.html',
    styleUrls: ['loading.component.css']
})
export class LoadingComponent {
    public message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.message = data.message || 'Loading...';
    }

    public updateMessage(newMessage: string): void {
        this.message = newMessage;
        this.changeDetectorRef.detectChanges();
    }
}