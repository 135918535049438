import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private channels: { [event: string]: Function[] } = {};

    public emit(event: string, ...args: any[]): void {
        const channel = this.channels[event];
        if (!channel) {
            return;
        }

        channel.forEach((handler: Function) => {
            handler(...args);
        });
    }

    public subscribe(event: string, ...handlers: Function[]): void {
        if (!this.channels[event]) {
            this.channels[event] = [];
        }

        handlers.forEach((handler: Function) => {
            this.channels[event].push(handler);
        });
    }

    public unsubscribe(event: string, handler: Function | null = null): boolean {
        const channel = this.channels[event];
        if (!channel) {
            return false;
        }

        if (!handler) {
            delete this.channels[event];

            return true;
        }

        const index = channel.indexOf(handler);
        if (index < 0) {
            return false;
        }

        channel.splice(index, 1);

        if (channel.length === 0) {
            delete this.channels[event];
        }

        return true;
    }
}