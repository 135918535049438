import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';

import { LayoutsRoutingModule } from './layouts-routing.module';

import { DashboardCoursesModule } from '../dashboard-courses/dashboard-courses.module';
import { DashboardWidgetsModule } from '../dashboard-widgets/dashboard-widgets.module';

import { FooterComponent } from './footer/footer.component';
import { HeaderOnlyLayoutComponent } from './header-only-layout/header-only-layout.component';
import { HeaderComponent } from './header/header.component';
import { StandardLayoutComponent } from './standard-layout/standard-layout.component';

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        HeaderOnlyLayoutComponent,
        StandardLayoutComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        LayoutsRoutingModule,
        DashboardCoursesModule,
        DashboardWidgetsModule
    ]
})
export class LayoutsModule { }
