import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ContentChild, Directive, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';

export interface DataTableColumn {
    field: string;
    title: string;
    transform?(value: any, data?: any): any;
}

@Directive({
    selector: '[cnDataTableExpanded]'
})
export class DataTableExpandedDirective {}

@Component({
    selector: 'cn-data-table',
    templateUrl: 'data-table.component.html',
    styleUrls: ['data-table.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class DataTableComponent implements OnChanges {

    @Input() public columns: DataTableColumn[];
    @Input() public currentPageSize = 25;
    @Input() public data: any;
    public dataSource: MatTableDataSource<any>;
    @Output() public expand = new Subject<any>();
    public expandedData: any | null;
    public expandedElement: any | null;
    @Input() public footer: any | null;
    @Input() public pageSizes = [10, 25, 50, 100];
    @Input() public pagination = false;
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @ViewChild(MatSort) public sort: MatSort;
    @Input() public sorting = false;
    @ContentChild(DataTableExpandedDirective, { read: TemplateRef }) public templateRef: TemplateRef<any>;
    public get columnFields(): string[] {
        if (!this.columns) {
            return [];
        }

        return this.columns.map((c: DataTableColumn) => c.field);
    }

    public expandRow(element: any): void {
        if (this.templateRef) {
            if (this.expandedElement === element) {
                this.expandedElement = null;
            } else {
                this.expandedElement = element;

                this.expand.next(element);
            }
        }
    }

    public getFooterField(column: DataTableColumn): string {
        if (!this.footer) {
            return '';
        }

        return column.transform ? column.transform(this.footer[column.field], this.footer) : this.footer[column.field];
    }

    public ngOnChanges(): void {
        this.dataSource = new MatTableDataSource(this.data);

        if (this.pagination) {
            this.dataSource.paginator = this.paginator;
        }

        if (this.sorting) {
            this.dataSource.sort = this.sort;
        }
    }
}