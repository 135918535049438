import { Action } from '@ngrx/store';

import { LoginResponse, School, User, TbsUser } from '../../core/models';

/**
 * Ngrx can be confusing to trace, so here's a quick overview
 *
 * Actions get `dispatch`ed through the Store. Those actions are defined below with their type and a payload of data
 * The state is then affected as determined in the `reducer` (`auth.reducer.ts`, or similar for other stores), which is a pure function that returns a new copy of the state
 * After the state is updated, any Effects take place (defined in `auth.effects.ts` or similar), which can make API calls, edit local storage, etc, then potentially return a new action
 * That action is then resolved as above
 *
 * State can be accessed as an Observable through Selectors (`auth.selectors.ts` or similar), which are just functions that return slices of the state
 */

// eslint-disable-next-line no-shadow
export enum ActionTypes {
    LOGIN = '[Auth] Login',
    LOGIN_SUCCESS = '[Auth] Login Success',
    LOGIN_FAILURE = '[Auth] Login Failure',
    LOGOUT = '[Auth] Logout',
    AUTHENTICATE = '[Auth] Authenticate',
    AUTHENTICATE_SUCCESS = '[Auth] Authenticate Success',
    AUTHENTICATE_FAILURE = '[Auth] Authenticate Failure',
    REFRESH_TOKEN = '[Auth] Refresh Token',
    TBS_AUTHENTICATE = '[Auth] TBS Authenticate',
    TBS_AUTHENTICATE_SUCCESS = '[Auth] TBS Authenticate Success',
    TBS_AUTHENTICATE_FAILURE = '[Auth] TBS Authenticate Failure',

    SET_SCHOOL = '[Auth] Set School',
    UPDATE_SCHOOL = '[Auth] Update School',
    UPDATE_SCHOOL_SUCCESS = '[Auth] Update School Success',
    UPDATE_SCHOOL_FAILURE = '[Auth] Update School Failure',

    UPDATE_USER = '[Auth] Update User',

    GET_TBS_USER = '[Auth] Get TBS User',
    GET_TBS_USER_SUCCESS = '[Auth] Get TBS User Success',
    GET_TBS_USER_FAILURE = '[Auth] Get TBS User Failure'
}

export class Login implements Action {
    public readonly type = ActionTypes.LOGIN;

    constructor(public payload: { email: string; password: string; redirectUrl?: string }) {}
}

export class LoginSuccess implements Action {
    public readonly type = ActionTypes.LOGIN_SUCCESS;

    constructor(public payload: { redirectUrl?: string; response: LoginResponse }) {}
}

export class LoginFailure implements Action {
    public readonly type = ActionTypes.LOGIN_FAILURE;

    constructor(public payload: { error: any }) {}
}

export class SetSchool implements Action {
    public readonly type = ActionTypes.SET_SCHOOL;

    constructor(public payload: { redirectUrl?: string; school: number }) {}
}

export class Logout implements Action {
    public readonly type = ActionTypes.LOGOUT;

    constructor(public payload: { redirectUrl?: string }) {}
}

export class Authenticate implements Action {
    public readonly type = ActionTypes.AUTHENTICATE;

    constructor(public payload: { refresh_token: string }) {}
}

export class AuthenticateSuccess implements Action {
    public readonly type = ActionTypes.AUTHENTICATE_SUCCESS;

    constructor(public payload: { redirectUrl?: string; response: LoginResponse }) {}
}

export class AuthenticateFailure implements Action {
    public readonly type = ActionTypes.AUTHENTICATE_FAILURE;

    constructor(public payload: { error: any }) {}
}

export class RefreshToken implements Action {
    public readonly type = ActionTypes.REFRESH_TOKEN;

    constructor(public payload: { access_token: string; exp: number; id_token: string }) { }
}

export class TbsAuthenticate implements Action {
    public readonly type = ActionTypes.TBS_AUTHENTICATE;

    constructor(public payload: { access_token: string; redirectUrl?: string }) {}
}

export class TbsAuthenticateSuccess implements Action {
    public readonly type = ActionTypes.TBS_AUTHENTICATE_SUCCESS;

    constructor(public payload:  { redirectUrl?: string; response: LoginResponse }) {}
}

export class TbsAuthenticateFailure implements Action {
    public readonly type = ActionTypes.TBS_AUTHENTICATE_FAILURE;

    constructor(public payload: { error: any }) {}
}

export class UpdateSchool implements Action {
    public readonly type = ActionTypes.UPDATE_SCHOOL;

    constructor(public payload: { dark_logo?: File | null; default_network_logo?: File | null; light_logo?: File | null; favicon?: File | null; auth_background?: File | null; school: Partial<School> }) { }
}

export class UpdateSchoolSuccess implements Action {
    public readonly type = ActionTypes.UPDATE_SCHOOL_SUCCESS;

    constructor(public payload: { dark_logo?: string; default_network_logo?: string; light_logo?: string; favicon?: string; auth_background?: string; school: Partial<School> }) { }
}

export class UpdateSchoolFailure implements Action {
    public readonly type = ActionTypes.UPDATE_SCHOOL_FAILURE;

    constructor(public payload: { school: Partial<School> }) { }
}

export class UpdateUser implements Action {
    public readonly type = ActionTypes.UPDATE_USER;

    constructor(public payload: Partial<User>) { }
}

export class GetTbsUser implements Action {
    public readonly type = ActionTypes.GET_TBS_USER;

    constructor(public payload: { tbs_id: number }) { }
}

export class GetTbsUserSuccess implements Action {
    public readonly type = ActionTypes.GET_TBS_USER_SUCCESS;

    constructor(public payload: TbsUser) { }
}

export class GetTbsUserFailure implements Action {
    public readonly type = ActionTypes.GET_TBS_USER_FAILURE;

    constructor(public payload: any) { }
}

export type AuthActions =
    | Login
    | LoginSuccess
    | LoginFailure
    | SetSchool
    | Logout
    | Authenticate
    | AuthenticateSuccess
    | AuthenticateFailure
    | RefreshToken
    | TbsAuthenticate
    | TbsAuthenticateSuccess
    | TbsAuthenticateFailure
    | UpdateSchool
    | UpdateSchoolSuccess
    | UpdateSchoolFailure
    | UpdateUser
    | GetTbsUser
    | GetTbsUserSuccess
    | GetTbsUserFailure
    ;