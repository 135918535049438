import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SchoolFeatures } from '../../../core/models';
import { Auth } from '../../../root-store';

import { ThinkDailyServiceModule } from '../../think-daily-service.module';

@Injectable({
    providedIn: ThinkDailyServiceModule
})
export class ThinkDailyGuard  {
    constructor(
        private store: Store<Auth.State>
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(Auth.selectSchoolFeatures).pipe(map((features: SchoolFeatures) => features.use_think_daily));
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}