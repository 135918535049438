<div class="wrapper">
    <div class="header flex-row flex-space-between-center">
        <div>
            <ng-content mat-card-avatar select=".avatar"></ng-content>
            <div class="title">
                <ng-content select=".title"></ng-content>
            </div>
        </div>
        <ng-content select=".drag-handle"></ng-content>
    </div>
    <div class="content-container">
        <ng-content select=".content"></ng-content>
    </div>
</div>