<div class="inline-audio" *ngIf="initialized" [class.open]="opened">
    <div class="tab" (click)="toggleOpen()">
        <div class="info flex-row flex-space-between-center" *ngIf="!opened">
            <div *ngIf="course" class="title-wrapper flex-row flex-start-center">
                <mat-icon>volume_up</mat-icon>
                <span class="title">{{ course.title }}: {{ activeContent.title }}</span>
                <button mat-icon-button *ngIf="!opened && !isPlaying" (click)="play(); $event.stopPropagation()"><mat-icon>play_arrow</mat-icon></button>
                <button mat-icon-button *ngIf="!opened && isPlaying" (click)="pause(); $event.stopPropagation()"><mat-icon>pause</mat-icon></button>
            </div>
        </div>
        <div class="opened-text flex-row flex-center-center" *ngIf="opened">
            <mat-icon>keyboard_arrow_down</mat-icon>
            <span>Close</span>
        </div>
    </div>
    <div class="bar" #bar>
        <div class="now-playing flex-column flex-start-center" *ngIf="opened">
            <img class="thumbnail" [src]="course.thumbnail">
            <div class="scrub flex-row flex-start-center">
                <span>{{ currentTime }}</span>
                <mat-slider class="scrub-bar" name="scrub" #ngSlider><input matSliderThumb [value]="scrubPercentage" (input)="scrubPercentage = {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value || 0" #ngSliderThumb="matSliderThumb" /></mat-slider>
                <span>{{ totalTime }}</span>
            </div>
            <div class="info">
                <div class="course-title">{{ course.title }}</div>
                <div class="content-title">{{ activeContent.title }}</div>
            </div>
            <div class="controls flex-row flex-space-between-center">
                <button mat-icon-button [disabled]="activeContentIndex === 0" (click)="previous()"><mat-icon>skip_previous</mat-icon></button>
                <button mat-icon-button *ngIf="!isPlaying" (click)="play()"><mat-icon>play_arrow</mat-icon></button>
                <button mat-icon-button *ngIf="isPlaying" (click)="pause()"><mat-icon>pause</mat-icon></button>
                <button mat-icon-button [disabled]="activeContentIndex === content.length - 1" (click)="next()"><mat-icon>skip_next</mat-icon></button>
            </div>
            <div class="volume flex-row flex-center-center">
                <mat-icon>volume_mute</mat-icon>
                <mat-slider class="volume-bar" name="volume" [(ngModel)]="volume"><input matSliderThumb /></mat-slider>
                <mat-icon>volume_up</mat-icon>
            </div>
            <div class="up-next">
                <h5>Up Next</h5>
                <div class="up-next-list">
                    <div class="content flex-row" *ngFor="let item of content; let i = index">
                        <div class="play-button-wrapper">
                            <button mat-icon-button class="play-button" (click)="setContent(i)"><mat-icon>play_arrow</mat-icon></button>
                        </div>
                        <div class="up-next-info flex-row flex-start-center">
                            <span><strong>{{ course.title }}:</strong> {{ item.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <audio class="video-js" #target preload="auto"></audio>
    </div>
</div>