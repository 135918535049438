import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PlaylistService } from '../../core';
import { Course, CnPlaylist, UserPlaylist } from '../../core/models';

import {
    ActionTypes,
    DeleteUserPlaylist,
    DeleteUserPlaylistFailure,
    DeleteUserPlaylistSuccess,
    EditUserPlaylist,
    EditUserPlaylistFailure,
    EditUserPlaylistSuccess,
    CreateUserPlaylist,
    CreateUserPlaylistFailure,
    CreateUserPlaylistSuccess,
    GetCnPlaylistsFailure,
    GetCnPlaylistsSuccess,
    GetUserPlaylistsFailure,
    GetUserPlaylistsSuccess,
    LogCnPlaylistUsage,
    LogCnPlaylistUsageSuccess,
    LogCnPlaylistUsageFailure,
    LogUserPlaylistUsage,
    LogUserPlaylistUsageSuccess,
    LogUserPlaylistUsageFailure
} from './playlist.actions';

@Injectable({
    providedIn: 'root'
})
export class PlaylistEffects {
    constructor(
        private actions: Actions,
        private playlistService: PlaylistService
    ) {}

    public CreateUserPlaylist: Observable<CreateUserPlaylistSuccess | CreateUserPlaylistFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.CREATE_USER_PLAYLIST),
            switchMap((action: CreateUserPlaylist) => this.playlistService.createUserPlaylist(action.payload.playlist as UserPlaylist, action.payload.courses)),
            map((response: UserPlaylist) => new CreateUserPlaylistSuccess(response)),
            catchError(() => of(new CreateUserPlaylistFailure()))
        )
    );

    public DeleteUserPlaylist: Observable<DeleteUserPlaylistSuccess | DeleteUserPlaylistFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.DELETE_USER_PLAYLIST),
            switchMap((action: DeleteUserPlaylist) => forkJoin([of(action), this.playlistService.deleteUserPlaylist(action.payload.playlistId)])),
            map(([action]: [DeleteUserPlaylist, void]) => new DeleteUserPlaylistSuccess({ playlistId: action.payload.playlistId })),
            catchError(() => of(new DeleteUserPlaylistFailure()))
        )
    );

    public GetCnPlaylists: Observable<GetCnPlaylistsSuccess | GetCnPlaylistsFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.GET_CN_PLAYLISTS),
            switchMap(() => this.playlistService.getAll()),
            map((response: CnPlaylist[]) => new GetCnPlaylistsSuccess({ playlists: response })),
            catchError((err: any) => of(new GetCnPlaylistsFailure(err)))
        )
    );

    public LogCnPlaylistUsage: Observable<LogCnPlaylistUsageSuccess | LogCnPlaylistUsageFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.LOG_CN_PLAYLIST_USAGE),
            switchMap((action: LogCnPlaylistUsage) => forkJoin([of(action), this.playlistService.logCnPlaylistUsage(action.payload.playlist, action.payload.course)])),
            map(([action]: [LogCnPlaylistUsage, void]) => new LogCnPlaylistUsageSuccess(action.payload)),
            catchError((err: any) => of(new LogCnPlaylistUsageFailure()))
        )
    );

    public LogUserPlaylistUsage: Observable<LogUserPlaylistUsageSuccess | LogUserPlaylistUsageFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.LOG_USER_PLAYLIST_USAGE),
            switchMap((action: LogUserPlaylistUsage) => forkJoin([of(action), this.playlistService.logUserPlaylistUsage(action.payload.playlist, action.payload.course)])),
            map(([action]: [LogUserPlaylistUsage, void]) => new LogUserPlaylistUsageSuccess(action.payload)),
            catchError((err: any) => of(new LogUserPlaylistUsageFailure()))
        )
    );

    public GetUserPlaylists: Observable<GetUserPlaylistsSuccess | GetUserPlaylistsFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.GET_USER_PLAYLISTS),
            switchMap(() => this.playlistService.getAllUserPlaylists()),
            map((response: CnPlaylist[]) => new GetUserPlaylistsSuccess({ playlists: response })),
            catchError((err: any) => of(new GetUserPlaylistsFailure(err)))
        )
    );

    public SetUserPlaylistCourses: Observable<EditUserPlaylistSuccess | EditUserPlaylistFailure> = createEffect(() =>
        this.actions.pipe(
            ofType(ActionTypes.EDIT_USER_PLAYLIST),
            switchMap((action: EditUserPlaylist) => forkJoin([of(action), this.playlistService.editUserPlaylist(action.payload.playlist, action.payload.courses.map((c: Course) => c.id))])),
            map(([action]: [EditUserPlaylist, void]) => new EditUserPlaylistSuccess({ playlist: action.payload.playlist, courses: action.payload.courses })),
            catchError(() => of(new EditUserPlaylistFailure()))
        )
    );
}