import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[cnForbiddenNumberValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ForbiddenNumberValidatorDirective,
            multi: true
        }
    ]
})
export class ForbiddenNumberValidatorDirective implements Validator {
    @Input('cnForbiddenNumberValidator') public forbiddenNumbers: number[];

    public validate(control: AbstractControl): ValidationErrors | null {
        if (this.forbiddenNumbers.includes(control.value)) {
            return { forbiddenNumber: true };
        }

        return null;
    }
}