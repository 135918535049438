import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titlecase',
    pure: false
})
export class TitleCasePipe implements PipeTransform {
    public transform(input: string): string {
        return input.length === 0 ? '' : input.replace(/\w\S*/g, ((txt: string) => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));
    }
}