import { Component, Input, OnInit } from '@angular/core';

import { ScaleType } from '@swimlane/ngx-charts';

import { WidgetService } from '../../core';
import { LeadsADL } from '../../student-tools/models';

@Component({
    selector: 'cn-leads-adl-widget',
    templateUrl: './leads-adl-widget.component.html',
    styleUrls: ['./leads-adl-widget.component.css']
})
export class LeadsAdlWidgetComponent implements OnInit {
    public calculatedColumns: boolean[] = [false, false, false, false, false];
    public colors = { name: 'Leads ADL', selectable: false, group: ScaleType.Linear, domain: ['#0101AD', '#E9190F', '#06631C'] };
    @Input() public data: { data: LeadsADL[] } | undefined = undefined;
    public displayedAdlColumns: string[] = ['Month', 'Year', 'ADL', 'Leads Run', 'Appointments Set'];
    public pending = false;
    @Input() public showLink = true;

    constructor(
        private widgetService: WidgetService
    ) { }

    public ngOnInit(): void {
        if (!this.data) {
            this.data = { data: [] };
            this.pending = true;
            this.widgetService.getData('leads-adl')
                .subscribe((data: LeadsADL[]) => {
                    this.data!.data = data.length ? data : [];
                    this.pending = false;
                });
        }
    }
}
