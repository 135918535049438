import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Config, IConfig } from '../config/config.model';
import { Faq } from '../models';


@Injectable({
    providedIn: 'root'
})
export class FaqService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public getOne(): Observable<Faq> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Faq>(`${this.apiUrl}/schools/${schoolId}/faq`))
        );
    }
}