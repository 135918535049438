import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Config, IConfig } from '../config/config.model';
import { EventService } from '../event/event.service';

@Injectable({
    providedIn: 'root'
})
export class AccessService {
    private _canAccessDownloads: Observable<boolean> | null = null;
    private _canAccessStore: Observable<boolean> | null = null;
    private _canAccessSubUsers: Observable<boolean> | null = null;
    private _canAccessThinkDaily: Observable<boolean> | null = null;
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        private eventService: EventService,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;

        this.eventService.subscribe('logout', () => {
            this._canAccessDownloads = null;
            this._canAccessStore = null;
            this._canAccessSubUsers = null;
            this._canAccessThinkDaily = null;
        });
    }

    public canAccessDownloads(): Observable<boolean> {
        if (this._canAccessDownloads !== null) {
            return this._canAccessDownloads;
        }

        this._canAccessDownloads = this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get(`${this.apiUrl}/schools/${schoolId}/downloads`)),
            map(() => true),
            catchError(() => of(false)),
            publishReplay(1),
            refCount()
        );

        return this._canAccessDownloads;
    }

    public canAccessStore(): Observable<boolean> {
        if (this._canAccessStore !== null) {
            return this._canAccessStore;
        }

        this._canAccessStore = this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get(`${this.apiUrl}/schools/${schoolId}/store`)),
            map(() => true),
            catchError(() => of(false)),
            publishReplay(1),
            refCount()
        );

        return this._canAccessStore;
    }

    public canAccessSubUsers(): Observable<boolean> {
        if (this._canAccessSubUsers !== null) {
            return this._canAccessSubUsers;
        }

        this._canAccessSubUsers = this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get(`${this.apiUrl}/schools/${schoolId}/sub-users`)),
            map(() => true),
            catchError(() => of(false)),
            publishReplay(1),
            refCount()
        );

        return this._canAccessSubUsers;
    }

    public canAccessThinkDaily(): Observable<boolean> {
        if (this._canAccessThinkDaily !== null) {
            return this._canAccessThinkDaily;
        }

        this._canAccessThinkDaily = this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get(`${this.apiUrl}/schools/${schoolId}/think-daily`)),
            map(() => true),
            catchError(() => of(false)),
            publishReplay(1),
            refCount()
        );

        return this._canAccessThinkDaily;
    }
}