import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Course, Transaction, User } from '../../core/models';
import { Config, IConfig } from '../config/config.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public changePassword(newPassword: string): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<undefined>(`${this.apiUrl}/schools/${schoolId}/user-info/password`, { password: newPassword }))
        );
    }

    public getCourses(userId?: number): Observable<Course[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Course[]>(`${this.apiUrl}/schools/${schoolId}/user-info/${userId ? `${userId}/` : ''}courses`))
        );
    }

    public getCreditCard(): Observable<string> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<string>(`${this.apiUrl}/schools/${schoolId}/user-info/billing`))
        );
    }

    public getTransactions(): Observable<Transaction[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<Transaction[]>(`${this.apiUrl}/schools/${schoolId}/user-info/transactions`))
        );
    }

    public getUser(userId?: number): Observable<User> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<User>(`${this.apiUrl}/schools/${schoolId}/user-info${userId ? `/${userId}` : ''}`))
        );
    }

    public updateBilling(billingInfo: any): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<undefined>(`${this.apiUrl}/schools/${schoolId}/user-info/billing`, billingInfo))
        );
    }

    public updateUser(user: User): Observable<void> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<undefined>(`${this.apiUrl}/schools/${schoolId}/user-info`, user))
        );
    }

    public uploadAvatar(avatar: File): Observable<string> {
        const formData = new FormData();
        formData.append('file', avatar);

        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<string>(`${this.apiUrl}/schools/${schoolId}/user-info/avatar`, formData))
        );
    }

    public uploadCompanyLogo(logo: File): Observable<string> {
        const formData = new FormData();
        formData.append('file', logo);

        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.put<string>(`${this.apiUrl}/schools/${schoolId}/user-info/company-logo`, formData))
        );
    }
}
