import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MessageViewerComponent } from '../../shared';

@Component({
    selector: 'cn-think-daily-item',
    templateUrl: 'think-daily-item.component.html',
    styleUrls: ['think-daily-item.component.css']
})
export class ThinkDailyItemComponent {
    @Input() public content: string;
    @Input() public date: string;
    @Input() public header: string;
    @Input() public title: string;

    constructor(
        private dialog: MatDialog
    ) {}

    public getContent(): string {
        const tmp = document.createElement('div');
        tmp.innerHTML = this.content;

        return tmp.textContent || tmp.innerText || '';
    }

    public readMessage(): void {
        this.dialog.open(MessageViewerComponent, { data: { message: this.content, title: this.title } });
    }
}