<mat-form-field appearance="outline">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-select class="primary-secondary-select" [(ngModel)]="selected" (selectionChange)="emitChange()" multiple>
        <mat-select-trigger class="circle-trigger flex-row flex-end-center" *ngIf="circleTrigger">
            <div class="circles flex-row" *ngFor="let option of selected" [style.color]="option?.color">
                <mat-icon class="circle-border">radio_button_unchecked</mat-icon>
                <mat-icon class="circle-center">lens</mat-icon>
            </div>
        </mat-select-trigger>

        <ng-container *ngIf="!grouped; else groupOptions">
            <mat-option class="select-all">
                <mat-checkbox name="select-all" color="primary" [(ngModel)]="selectAllFilter[0]" (change)="handleSelectAllChange(0)">{{ allText }}</mat-checkbox>
            </mat-option>
            <mat-option *ngFor="let opt of $any(options)" [value]="opt" class="mat-option" [style.color]="opt.color || 'unset'">{{ opt.title }}</mat-option>
        </ng-container>
        <ng-template #groupOptions>
            <mat-optgroup *ngFor="let group of groups; let i = index" [label]="group">
                <mat-option class="select-all" disabled>
                    <mat-checkbox name="select-all" color="primary" [(ngModel)]="selectAllFilter[i]" (change)="handleSelectAllChange(i)">All of {{ group }}</mat-checkbox>
                </mat-option>
                <mat-option *ngFor="let opt of $any(options[i])" [value]="opt">{{ opt.title }}</mat-option>
            </mat-optgroup>
        </ng-template>
    </mat-select>
</mat-form-field>