import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Config, IConfig } from '../config/config.model';

/* eslint-disable no-console */

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private apiUrl: string;
    private logToConsole: boolean;

    constructor(
        private http: HttpClient,
        @Inject(Config) config: IConfig,
        private store: Store<Auth.State>
    ) {
        this.logToConsole = config.isDev;
        this.apiUrl = config.apiUrl;
    }

    public record(type: string, attributes?: any): void {
        const payload = { type, ...attributes };

        if (this.logToConsole) {
            console.log(payload);
        }

        this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.post<void>(`${this.apiUrl}/schools/${schoolId}/analytics/events`, payload))
        ).subscribe();
    }
}