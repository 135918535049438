import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'cn-message-viewer',
    templateUrl: './message-viewer.component.html',
    styleUrls: ['./message-viewer.component.css']
})
export class MessageViewerComponent {
    public message: string;
    public title: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.message = data.message;
        this.title = data.title;
    }
}