import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Course } from '../../core/models';

@Component({
    selector: 'cn-unwrap-course-dialog',
    templateUrl: './unwrap-course-dialog.component.html',
    styleUrls: ['./unwrap-course-dialog.component.css']
})
export class UnwrapCourseDialogComponent {
    public course: Course;

    constructor(
        private dialogRef: MatDialogRef<UnwrapCourseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        if (data) {
            this.course = data.course;
        }
    }

    public close(shouldWatch: boolean): void {
        this.dialogRef.close(shouldWatch);
    }
}
