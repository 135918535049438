import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { WidgetService } from '../../core';
import { DEFAULT_DATA, VisualAmbitionSetGroup } from '../../student-tools/models/visual-ambition.model';

@Component({
    templateUrl: 'visual-ambition-creation-dialog.component.html',
    styleUrls: ['visual-ambition-creation-dialog.component.css']
})
export class VisualAmbitionCreationDialogComponent implements OnInit {
    public name: string;
    public pending = false;
    public possibleYears: string[];
    public year: string;

    private existingPosters: string[];
    private urlFriendlyName: string;

    constructor(
        private widgetService: WidgetService,
        private dialogRef: MatDialogRef<VisualAmbitionCreationDialogComponent>
    ) {
        const currentYear = new Date().getFullYear();
        this.year = `${currentYear}`;
        this.possibleYears = [];
        for (let i = currentYear; i <= currentYear + 20; ++i) {
            this.possibleYears.push(`${i}`);
        }
    }

    public get alreadyExists(): boolean {
        if (!this.existingPosters) {
            return false;
        }

        return this.existingPosters.indexOf(this.getUrlFriendlyName(this.name)) > -1;
    }

    public create(): void {
        this.pending = true;
        this.widgetService.getData('visual-ambition').pipe(
            flatMap((data: VisualAmbitionSetGroup) => {
                this.urlFriendlyName = this.getUrlFriendlyName(this.name);
                if (data[this.urlFriendlyName]) {
                    return of(false);
                } else {
                    data[this.urlFriendlyName] = { name: this.name, year: this.year, data: DEFAULT_DATA, created_on: Date.now(), updated_on: Date.now() };

                    return this.widgetService.setData('visual-ambition', data).pipe(
                        map((response: any) => true)
                    );
                }
            })
        ).subscribe(
            (success: boolean) => {
                this.pending = false;
                if (success) {
                    this.dialogRef.close(this.getUrlFriendlyName(this.name));
                }
            }
        );
    }

    public getUrlFriendlyName(originalName: string): string {
        if (!originalName) {
            return '';
        }

        // Trim the string, remove all non-alphanumeric characters and replace spaces with dashes
        return originalName.trim().replace(/[^A-Za-z0-9- ]+/g, '').replace(/ +/g, '-').replace(/-+/g, '-').toLowerCase();
    }

    public ngOnInit(): void {
        this.widgetService.getData('visual-ambition')
            .subscribe((data: VisualAmbitionSetGroup) => {
                this.existingPosters = Object.keys(data);
            });
    }
}