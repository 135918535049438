import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './theme.state';

export const selectTheme = createFeatureSelector<State>('Theme');

export const selectAuthBackground = createSelector(selectTheme, (theme: State) => theme.auth_background || '');
export const selectDarkLogo = createSelector(selectTheme, (theme: State) => theme.dark_logo || '');
export const selectHeaderColor = createSelector(selectTheme, (theme: State) => theme.header_color || '');
export const selectHideRegisterSidebar = createSelector(selectTheme, (theme: State) => theme.hide_register_sidebar || false);
export const selectLightLogo = createSelector(selectTheme, (theme: State) => theme.light_logo || '');
export const selectLoginButtonBackground = createSelector(selectTheme, (theme: State) => theme.login_button_background || '');
export const selectLoginPanelBackground = createSelector(selectTheme, (theme: State) => theme.login_panel_background || '');
export const selectNetworkLogo = createSelector(selectTheme, (theme: State) => theme.network_logo || '');
export const selectRequireCompanyInfo = createSelector(selectTheme, (theme: State) => theme.require_company_info || false);
export const selectRequireRegistrationCode = createSelector(selectTheme, (theme: State) => theme.require_registration_code || false);
export const selectShowRegistration = createSelector(selectTheme, (theme: State) => theme.show_registration ?? true);
export const selectSupportEmail = createSelector(selectTheme, (theme: State) => theme.support_email ?? '');
export const selectTitle = createSelector(selectTheme, (theme: State) => theme.title || '');
export const selectUseTbsLogin = createSelector(selectTheme, (theme: State) => theme.use_tbs_login ?? false);
export const selectWrapCourses = createSelector(selectTheme, (theme: State) => theme.wrap_courses || false);