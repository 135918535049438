import { AuthEffects } from './auth/auth.effects';
import { CategoryEffects } from './category/category.effects';
import { LanguageEffects } from './language/language.effects';
import { PlaylistEffects } from './playlist/playlist.effects';
import { ThemeEffects } from './theme/theme.effects';

export const EFFECTS = [
    AuthEffects,
    CategoryEffects,
    LanguageEffects,
    PlaylistEffects,
    ThemeEffects
];