import { AfterViewInit, Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import videojs from 'video.js';
require('videojs-seek-buttons');

import { AnalyticsService, CourseService, ResponsiveService, ScreenSize } from '../../core';
import {Content, ContentCompletionResult, ContentFile, ContentTextTrack, Course} from '../../core/models';
import { Auth } from '../../root-store';

const DEFAULT_RESOLUTION = '1080';

interface ResolutionOption {
    height: number;
    label: string;
    file: ContentFile;
}

@Component({
    templateUrl: 'course-player.component.html',
    styleUrls: ['course-player.component.css']
})
export class CoursePlayerComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {
    public activeContent: Content;
    public activeContentIndex: number;
    public activeFile: ContentFile | null;
    public activeResolution = DEFAULT_RESOLUTION;
    public autoplay = true;
    public bufferPercent = 0;
    public course: Course;
    public courseComments = '';
    public courseRating = 0;
    public firstTimeCourseComplete = false;
    public hideLibraryButton = false;
    public loading = false;
    public mediaContent: Content[] = [];
    public newCommentText = '';
    public playbackRate: number;
    public playbackSpeeds = ['1.5', '1.0', '1.25'];
    public player: videojs.Player;
    public playerOptions: videojs.PlayerOptions = {
        fill: true,
        controls: true,
        autoplay: false,
        playbackRates: [1.0, 1.25, 1.5],
        controlBar: {
            volumePanel: true,
            pictureInPictureToggle: false,
            playbackRateMenuButton: true
        }
    };
    public resolutionComponent: videojs.MenuButton;
    public screenSize: ScreenSize;
    public ScreenSize = ScreenSize; // Make enum available in template
    public showCourseCompletion = false;
    public showDashboardButton$: Observable<boolean>;
    public standalone = false;
    @ViewChild('target') public target: ElementRef;

    private firstTimeWatching: boolean;
    private hasCompleted: boolean;
    private languages : { [code: string]: string } = {
        en: 'English',
        es: 'Spanish',
        fr: 'French'
    };
    private resolutionOptions: ResolutionOption[];
    private setTime: number;
    private videoIdentifier: number; // This stores a number to identify this user/video watch instance

    constructor(
        private courseService: CourseService,
        private responsiveService: ResponsiveService,
        private router: Router,
        private analytics: AnalyticsService,
        private store: Store<Auth.State>,
        private dialogRef: MatDialogRef<CoursePlayerComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.showDashboardButton$ = this.store.select(Auth.selectSchoolId).pipe(map((schoolId: number) => schoolId !== 3)); // Hide dashboard for Pop of the Day
        this.hideLibraryButton = data?.hideLibraryButton || false;
     }

    public get sidebarMode(): 'over' | 'side' {
        if (this.screenSize > ScreenSize.sm) {
            return 'side';
        } else {
            return 'over';
        }
    }

    public close(path?: any[]): void {
        this.updateIfNeeded();

        if (!this.standalone && this.firstTimeWatching && this.courseRating > 0) {
            this.saveRating();
        }

        // Don't redirect if path doesn't exist, or if the path matches the current url
        if (path && path.join() !== this.router.routerState.snapshot.url) {
            this.router.navigate(path);
        }
        this.dialogRef.close();
    }

    public getComment(comment: string): void {
        this.newCommentText = comment;
    }

    public getState(): 'paused' | 'playing' {
        return this.player.paused() ? 'paused' : 'playing';
    }

    public getCaptionLabel(language: string): string {
        return this.languages[language] || 'English';
    }

    public ngAfterViewInit(): void {
        // Initiate the videojs player
        this.player = videojs(this.target.nativeElement, this.playerOptions);

        // Add the rewind button and set to rewind 15 seconds
        (this.player as any).seekButtons({ back: 15, backIndex: 5 });

        // Set up the event handlers
        this.player.on(['pause', 'seeked'], () => this.updateIfNeeded());
        this.player.on('loadstart', () => this.loading = true);
        this.player.on('loadedmetadata', () => this.handleMediaLoaded());
        this.player.on('loadeddata', () => this.loading = false);
        this.player.on('ended', () => this.handleMediaEnded());

        // Create the Quality Change button
        const menuButton = videojs.getComponent('MenuButton');
        const menuItem = videojs.getComponent('MenuItem');
        const qualityChangeMenuButton = videojs.extend(menuButton, {
            createItems: () =>
                this.resolutionOptions ?
                    this.resolutionOptions.map((i: ResolutionOption) => {
                        const item = new menuItem(this.player, { label: i.label });
                        item.handleClick = async() => {
                            const time = this.player.currentTime();
                            const playbackRate = this.player.playbackRate();
                            const paused = this.player.paused();

                            this.activeFile = i.file;
                            this.player.src({ type: this.activeFile?.content_type || '', src: this.activeFile?.url || '' });

                            if (!paused) {
                                await this.player.play();
                            }

                            this.player.currentTime(time);
                            this.player.playbackRate(playbackRate);
                        };

                        return item;
                    }) :
                    []
        });

        // Add Quality Change button to control bar, with material icons class
        videojs.registerComponent('QualityChangeMenuButton', qualityChangeMenuButton);
        this.resolutionComponent = this.player.controlBar.addChild('QualityChangeMenuButton', undefined, 11) as videojs.MenuButton;
        this.resolutionComponent.addClass('vjs-quality-control-button');
        this.resolutionComponent.controlText('Quality');
        const elements = this.resolutionComponent.el().getElementsByClassName('vjs-icon-placeholder');
        if (elements.length > 0) {
            elements[0].classList.add('material-icons');
            elements[0].innerHTML = 'settings';
        }

        if (this.activeContent && this.activeContent.files) {
            this.resolutionOptions = this.activeContent.files
                .filter((f: ContentFile) => f.resolution)
                .sort((a: any, b: any) => b.resolution - a.resolution)
                .map((f: ContentFile, i: number) => ({
                    height: Number(f.resolution),
                    label: `${f.resolution}P`,
                    file: f
                }));
        }

        this.setContent(this.activeContentIndex);
    }

    public ngOnChanges(): void {
        if (!this.standalone) {
            this.courseService.getCourseContent(this.course.id)
                .subscribe(
                    (content: Content[]) => {
                        content.forEach((c: Content) => {
                            if (c.text_tracks) {
                                c.text_tracks = c.text_tracks.sort((a: ContentTextTrack, b: ContentTextTrack) => this.getCaptionLabel(a.language) < this.getCaptionLabel(b.language) ? -1 : 1);
                            }
                        });

                        this.mediaContent = content;
                        this.setContent(0);
                    }
                );

            this.courseService.getOne(this.course.id)
                .subscribe(
                    (course: Course) => {
                        this.course = course;
                    }
                );
        }
    }

    public ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
        }
    }

    public ngOnInit(): void {
        this.setContent(this.activeContentIndex);

        this.responsiveService.screenSize.subscribe((size: ScreenSize) => {
            this.screenSize = size;
        });
    }

    public setContent(index: number, update?: boolean): void {
        if (update) {
            this.updateIfNeeded();
        }

        const content = this.mediaContent[index];
        if (!content || !content.files) {
            return;
        }
        this.activeContentIndex = index;
        this.activeContent = this.mediaContent[index];
        this.videoIdentifier = Date.now();

        if (this.activeContent) {
            let fileToPlay: ContentFile | null = null;
            if (this.activeResolution) {
                const sameResolutionFile = this.activeContent.files ? this.activeContent.files.find((f: ContentFile) => !!f.resolution && f.resolution === this.activeResolution) : null;
                if (sameResolutionFile) {
                    fileToPlay = sameResolutionFile;
                }
            }

            if (!fileToPlay) {
                const firstVideoFile = this.activeContent.files ? this.activeContent.files.find((f: ContentFile) => f.content_type.includes('video')) : null;
                fileToPlay = firstVideoFile || (this.activeContent.files ? this.activeContent.files[0] : null);
            }

            this.activeResolution = fileToPlay ? (fileToPlay.resolution || DEFAULT_RESOLUTION) : DEFAULT_RESOLUTION;
            this.activeFile = fileToPlay;
            this.hasCompleted = false;
            this.firstTimeWatching = !this.activeContent.is_completed;

            if (this.activeContent.files) {
                this.resolutionOptions = this.activeContent.files
                    .filter((f: ContentFile) => f.resolution)
                    .sort((a: any, b: any) => b.resolution - a.resolution)
                    .map((f: ContentFile, i: number) => ({
                        height: Number(f.resolution),
                        label: `${f.resolution}P`,
                        file: f
                    }));

                if (this.resolutionComponent) {
                    this.resolutionComponent.update();
                }
            }

            if (this.player) {
                this.player.src({ type: this.activeFile?.content_type || '', src: this.activeFile?.url || '' });
                this.player.playbackRate(this.playbackRate);

                let activeTrackLanguage: string | null = null;
                const textTracks = this.player.remoteTextTracks();
                for (const track of Array.from(textTracks)) {
                    if (track.mode === 'showing') {
                        activeTrackLanguage = track.language;
                    }
                    this.player.removeRemoteTextTrack(track as any);
                }

                if (this.activeContent.text_tracks) {
                    for (const track of this.activeContent.text_tracks) {
                        const data = { src: track.url, srclang: track.language, kind: 'subtitles' as any, label: this.languages[track.language], mode: (track.language === activeTrackLanguage ? 'showing' : 'hidden') as any };
                        this.player.addRemoteTextTrack(data, true);
                    }
                }
            }
        }
    }

    public setRating(rating: number): void {
        this.courseRating = rating;
    }

    public updateContentCompletion(contentId: number, completion: number): void {
        if (this.standalone) {
            return;
        }

        this.courseService.updateContentCompletion(this.course.id, contentId, completion)
            .subscribe((result: ContentCompletionResult) => {
                const mediaContent = this.mediaContent.find((c: Content) => c.id === contentId);
                if (mediaContent) {
                    mediaContent.completion = completion;
                }

                if (completion === 100 && !this.hasCompleted) {
                    this.hasCompleted = true;
                    this.sendCompletionEvent();
                    if (mediaContent) {
                        mediaContent.is_completed = true;
                    }
                }

                if (result && result.firstTimeCourseComplete) {
                    this.firstTimeCourseComplete = true;
                }
            });

            this.courseService.updateWatchData(this.course.id, contentId, this.videoIdentifier, this.player.played()).subscribe();
    }

    public updateIfNeeded(): void {
        if (!this.standalone && this.player && this.activeContent && this.activeContent.id) {
            const percentage = this.calculateCompletionPercentage();
            this.updateContentCompletion(this.activeContent.id, percentage);
        }
    }

    private calculateCompletionPercentage(): number {
        return (this.player.currentTime() / this.player.duration()) * 100;
    }

    private handleMediaEnded(): void {
        if (this.activeContent && this.activeContent.id) {
            this.updateContentCompletion(this.activeContent.id, 100);
        }

        if (this.mediaContent.length > this.activeContentIndex + 1) {
            if (this.autoplay) {
                this.setContent(this.activeContentIndex + 1);
            }
        } else if (!this.standalone) {
            this.showCourseCompletion = true;
        }
    }

    private handleMediaLoaded(): void {
        const duration = this.player.duration();

        // Start from last saved location, or from the start if completion is 100
        if (this.activeContent && (!this.activeContent.completion || this.activeContent.completion < 100)) {
            if (!this.setTime) {
                const currentTime = (duration * ((this.activeContent.completion || 0) / 100));
                this.player.currentTime(currentTime - 10); // Ten seconds earlier than previous position
            } else {
                this.player.currentTime(this.setTime);
            }
        }
    }

    private saveRating(): void {
        this.courseService.updateRating(this.course.id, this.courseRating, this.courseComments).subscribe();
    }

    private sendCompletionEvent(): void {
        const attributes: any = {
            content: this.activeContent ? this.activeContent.id : 0,
            media_type: 'video',
            first_time: this.firstTimeWatching
        };
        this.analytics.record('content_complete', attributes);
    }
}
