import { RouterState } from '@ngrx/router-store';

import { State as AuthState } from './auth';
import { State as CategoryState } from './category';
import { State as LanguageState } from './language';
import { State as PlaylistState } from './playlist';
import { State as ThemeState } from './theme';

export interface RootState {
    Auth: AuthState;
    Category: CategoryState;
    Language: LanguageState;
    Playlist: PlaylistState;
    Router: RouterState;
    Theme: ThemeState;
}