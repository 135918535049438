<mat-toolbar class="toolbar flex-row flex-space-between-center" [style.background-color]="color$ | async">
    <div class="padding flex-hide-gt-sm"></div>
    <div class="logo-wrapper flex-row flex-center-center flex-start-center-gt-sm">
        <div class="logo">
            <a routerLink="/">
                <img [src]="logo$ | async">
            </a>
        </div>
    </div>
    <div class="flex-row flex-end-center">
        <a mat-button *ngIf="showGroupsLink$ | async" class="flex-hide flex-show.gt-sm" [routerLink]="['/profile', 'settings']" [fragment]="'groups'">View Assigned {{ 'GROUPS' | language | async | titlecase }}</a>
        <a *ngIf="isLoggedIn$ | async; else signIn" class="avatar-wrapper" routerLink="/profile">
            <img class="avatar" [src]="avatar$ | async">
        </a>
        <ng-template #signIn>
            <a class="link" routerLink="/login">
                Sign In
            </a>
        </ng-template>
    </div>
</mat-toolbar>