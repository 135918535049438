import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { Auth } from '../../root-store';

import { Role } from '../models';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard  {
    constructor(
        private store: Store<Auth.State>,
        private snackbar: MatSnackBar,
        private router: Router
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.hasAccess(route.data.roles, route.data.networks).pipe(
            tap((hasAccess: boolean) => {
                if (!hasAccess) {
                    this.snackbar.open('You do not have access to that page', undefined, { duration: 3000 });
                    this.router.navigate(['/login'], { queryParams: { redirectTo: state.url } });
                }
            })
        );
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    public hasAccess(roles: string[], networks?: number[]): Observable<boolean> {
        return this.store.select(Auth.selectAuth).pipe(
            filter((auth: Auth.State) => !auth.is_loading && !!auth.school),
            take(1),
            map((auth: Auth.State)  => {
                const userRoles = auth.roles;
                if (!userRoles) {
                    return false;
                }

                for (const role of roles) {
                    const foundRole = userRoles.find((r: Role) => {
                        if (networks) {
                            return r.name === role && auth.school && r.school === auth.school.id && typeof r.network !== 'undefined' && (r.network === 0 || networks.indexOf(r.network) > -1);
                        } else {
                            return r.name === role && auth.school && r.school === auth.school.id;
                        }
                    });

                    if (foundRole) {
                        return true;
                    }
                }

                return false;
            })
        );
    }
}