import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppInitService } from './app-init.service';
import { AppComponent } from './app.component';

import { CoreModule } from './core';
import { LayoutsModule } from './layouts/layouts.module';
import { RootStoreModule } from './root-store/root-store.module';
import { SharedModule } from './shared';

@NgModule({
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,

        // Order matters here for route prioritization
        // Modules are lazy-loaded in LayoutModule to enable shared layout templates
        RouterModule.forRoot([], {}),
        LayoutsModule,

        RootStoreModule,
        StoreDevtoolsModule.instrument({ maxAge: 6 , connectInZone: true })
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: (appInit: AppInitService) => async() => appInit.init(), deps: [AppInitService], multi: true }
    ]
})
export class AppModule { }
