import { inject, Inject, InjectionToken } from '@angular/core';

import { Device, DeviceId, DeviceInfo, DevicePlugin } from '@capacitor/device';

import { Config, IConfig } from '../config/config.model';

export interface IDeviceService {
    isVirtual: Promise<boolean>;
    manufacturer: Promise<string>;
    model: Promise<string>;
    platform: Promise<string>;
    uuid: Promise<string>;
    version: Promise<string>;
}

export class WebDeviceService implements IDeviceService {
    public isVirtual = Promise.resolve(false);
    public manufacturer = Promise.resolve('Web');
    public model = Promise.resolve('Browser');
    public platform = Promise.resolve('web');
    public uuid = Promise.resolve('123');
    public version = Promise.resolve('Web');
}

export class MobileDeviceService implements IDeviceService {
    constructor(
        @Inject('Device') private device: DevicePlugin
    ) {}

    public get isVirtual(): Promise<boolean> {
        return this.device.getInfo().then((info: DeviceInfo) => info.isVirtual);
    }

    public get manufacturer(): Promise<string> {
        return this.device.getInfo().then((info: DeviceInfo) => info.manufacturer);
    }

    public get model(): Promise<string> {
        return this.device.getInfo().then((info: DeviceInfo) => info.model);
    }

    public get platform(): Promise<string> {
        return this.device.getInfo().then((info: DeviceInfo) => info.platform);
    }

    public get uuid(): Promise<string> {
        return this.device.getId().then((id: DeviceId) => id.identifier);
    }

    public get version(): Promise<string> {
        return this.device.getInfo().then((info: DeviceInfo) => info.osVersion);
    }
}

export function deviceServiceFactory(config: IConfig, device: DevicePlugin): IDeviceService {
    return config.isMobile ? new MobileDeviceService(device) : new WebDeviceService();
}

export const DeviceService = new InjectionToken('DeviceService', {
    providedIn: 'root',
    factory: () => deviceServiceFactory(inject(Config), Device)
});