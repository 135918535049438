<div class="wrapper">
    <div class="contents flex-row flex-start-center">
        <div class="thumbnail" (click)="play()">
            <img class="thumbnail" [src]="course.thumbnail">
            <mat-icon class="play">play_arrow</mat-icon>
        </div>
        <div class="text flex-column flex-space-between-start">
            <div class="truncate" title="{{ course.title }}">{{ course.title }}</div>
            <div class="title truncate" title="{{ content.title }}">{{ content.title }}</div>
            <div class="subject" [style.background-color]="course.color">{{ course.subject_title }}</div>
        </div>
    </div>
</div>