import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Config, IConfig } from '../config/config.model';

import { LanguageToken } from '../models';

import { Auth } from '../../root-store';

@Injectable({
    providedIn: 'root'
})
export class LanguageTokenService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private store: Store<Auth.State>,
        @Inject(Config) config: IConfig
    ) {
        this.apiUrl = config.apiUrl;
    }

    public getAll(): Observable<LanguageToken[]> {
        return this.store.select(Auth.selectSchoolId).pipe(
            take(1),
            switchMap((schoolId: number) => this.http.get<LanguageToken[]>(`${this.apiUrl}/schools/${schoolId}/language`))
        );
    }
}