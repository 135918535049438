import { Category } from '../../core/models';

import { ActionTypes, CategoryActions } from './category.actions';
import { State, initialState } from './category.state';

export function reducer(state: State = initialState, action: CategoryActions): State {
    switch (action.type) {
        case ActionTypes.GET_CATEGORIES_SUCCESS: {
            return { ...state, categories: action.payload, loaded: true };
        }
        case ActionTypes.COMPLETE_SUCCESS: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);
            const completed = action.payload.assetIndex === state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.length - 1;

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        chapters: [
                            ...state.categories[categoryIndex].chapters!.slice(0, action.payload.chapterIndex),
                            {
                                ...state.categories[categoryIndex].chapters![action.payload.chapterIndex],
                                items: [
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(0, action.payload.assetIndex),
                                    {
                                        ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items[action.payload.assetIndex],
                                        completed: true
                                    },
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(action.payload.assetIndex + 1)
                                ],
                                completed
                            },
                            ...state.categories[categoryIndex].chapters!.slice(action.payload.chapterIndex + 1)
                        ]
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.FAVORITE: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        favorite: true
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.FAVORITE_FAILURE: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        favorite: false
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.FAVORITE_ASSET: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        chapters: [
                            ...state.categories[categoryIndex].chapters!.slice(0, action.payload.chapterIndex),
                            {
                                ...state.categories[categoryIndex].chapters![action.payload.chapterIndex],
                                items: [
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(0, action.payload.assetIndex),
                                    {
                                        ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items[action.payload.assetIndex],
                                        favorite: true,
                                        favorited_on: new Date().toISOString()
                                    },
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(action.payload.assetIndex + 1)
                                ]
                            },
                            ...state.categories[categoryIndex].chapters!.slice(action.payload.chapterIndex + 1)
                        ]
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.FAVORITE_ASSET_FAILURE: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        chapters: [
                            ...state.categories[categoryIndex].chapters!.slice(0, action.payload.chapterIndex),
                            {
                                ...state.categories[categoryIndex].chapters![action.payload.chapterIndex],
                                items: [
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(0, action.payload.assetIndex),
                                    {
                                        ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items[action.payload.assetIndex],
                                        favorite: false,
                                        favorited_on: undefined
                                    },
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(action.payload.assetIndex + 1)
                                ]
                            },
                            ...state.categories[categoryIndex].chapters!.slice(action.payload.chapterIndex + 1)
                        ]
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.UNFAVORITE: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        favorite: false
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.UNFAVORITE_FAILURE: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        favorite: true
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.UNFAVORITE_ASSET: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        chapters: [
                            ...state.categories[categoryIndex].chapters!.slice(0, action.payload.chapterIndex),
                            {
                                ...state.categories[categoryIndex].chapters![action.payload.chapterIndex],
                                items: [
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(0, action.payload.assetIndex),
                                    {
                                        ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items[action.payload.assetIndex],
                                        favorite: false,
                                        favorited_on: undefined
                                    },
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(action.payload.assetIndex + 1)
                                ]
                            },
                            ...state.categories[categoryIndex].chapters!.slice(action.payload.chapterIndex + 1)
                        ]
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.UNFAVORITE_ASSET_FAILURE: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        chapters: [
                            ...state.categories[categoryIndex].chapters!.slice(0, action.payload.chapterIndex),
                            {
                                ...state.categories[categoryIndex].chapters![action.payload.chapterIndex],
                                items: [
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(0, action.payload.assetIndex),
                                    {
                                        ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items[action.payload.assetIndex],
                                        favorite: true,
                                        favorited_on: new Date().toISOString()
                                    },
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(action.payload.assetIndex + 1)
                                ]
                            },
                            ...state.categories[categoryIndex].chapters!.slice(action.payload.chapterIndex + 1)
                        ]
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        case ActionTypes.UPDATE_COMPLETION_SUCCESS: {
            const categoryIndex = state.categories.findIndex((c: Category) => c.id === action.payload.category);

            return {
                ...state,
                categories: [
                    ...state.categories.slice(0, categoryIndex),
                    {
                        ...state.categories[categoryIndex],
                        chapters: [
                            ...state.categories[categoryIndex].chapters!.slice(0, action.payload.chapterIndex),
                            {
                                ...state.categories[categoryIndex].chapters![action.payload.chapterIndex],
                                items: [
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(0, action.payload.assetIndex),
                                    {
                                        ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items[action.payload.assetIndex],
                                        completion: action.payload.completion
                                    },
                                    ...state.categories[categoryIndex].chapters![action.payload.chapterIndex].items.slice(action.payload.assetIndex + 1)
                                ]
                            },
                            ...state.categories[categoryIndex].chapters!.slice(action.payload.chapterIndex + 1)
                        ]
                    },
                    ...state.categories.slice(categoryIndex + 1)
                ]
            };
        }
        default: {
            return { ...state };
        }
    }
}