import { School, User } from '../../core/models';

import { ActionTypes, AuthActions } from './auth.actions';
import { State, initialState } from './auth.state';

export function reducer(state: State = initialState, action: AuthActions): State {
    switch (action.type) {
        case ActionTypes.LOGIN: {
            return {
                ...state,
                is_loading: true
            };
        }
        case ActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                ...action.payload.response,
                is_loading: false,
                is_logged_in: true,
                error: null
            };
        }
        case ActionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                is_loading: false,
                is_logged_in: false,
                error: action.payload.error
            };
        }
        case ActionTypes.SET_SCHOOL: {
            let school: School | undefined;
            if (state.schools) {
                school = state.schools.find((s: School) => s.id === action.payload.school);
            }

            return {
                ...state,
                school: school || null
            };
        }
        case ActionTypes.LOGOUT: {
            return { ...initialState };
        }
        case ActionTypes.AUTHENTICATE: {
            return {
                ...state,
                is_loading: true
            };
        }
        case ActionTypes.AUTHENTICATE_SUCCESS: {
            return {
                ...state,
                ...action.payload.response,
                is_loading: false,
                is_logged_in: true
            };
        }
        case ActionTypes.AUTHENTICATE_FAILURE: {
            return {
                ...state,
                is_loading: false,
                is_logged_in: false
            };
        }
        case ActionTypes.TBS_AUTHENTICATE: {
            return {
                ...state,
                is_loading: true
            };
        }
        case ActionTypes.TBS_AUTHENTICATE_SUCCESS: {
            return {
                ...state,
                ...action.payload.response,
                is_loading: false,
                is_logged_in: true
            };
        }
        case ActionTypes.TBS_AUTHENTICATE_FAILURE: {
            return {
                ...state,
                is_loading: false,
                is_logged_in: false
            };
        }
        case ActionTypes.REFRESH_TOKEN: {
            return {
                ...state,
                id_token: action.payload.id_token,
                access_token: action.payload.access_token,
                exp: action.payload.exp
            };
        }
        case ActionTypes.UPDATE_SCHOOL_SUCCESS: {
            if (action.payload.dark_logo) {
                action.payload.school.dark_logo = action.payload.dark_logo;
            }

            if (action.payload.light_logo) {
                action.payload.school.light_logo = action.payload.light_logo;
            }
            if (action.payload.default_network_logo) {
                action.payload.school.default_network_logo = action.payload.default_network_logo;
            }

            let schools: School[] | null = null;
            if (state.schools) {
                schools = [...state.schools];
                const schoolIndex = schools.findIndex((s: School) => s.id === action.payload.school.id);
                if (schoolIndex > -1) {
                    schools.splice(schoolIndex, 1);
                    schools.push(action.payload.school as School);
                }
            }

            return {
                ...state,
                schools,
                school: {
                    ...state.school,
                    ...action.payload.school as School
                }
            };
        }
        case ActionTypes.UPDATE_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload as User
                }
            };
        }
        case ActionTypes.GET_TBS_USER_SUCCESS: {
            return {
                ...state,
                tbs_user: {
                    ... action.payload
                }
            };
        }
        case ActionTypes.GET_TBS_USER_FAILURE: {
            return {
                ...state,
                tbs_user: null
            };
        }
        default: {
            return state;
        }
    }
}