import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * This directive provides an easy way to use the Hint attributes provided by IntroJS.
 * See https://introjs.com/docs/hints/attributes/ for more information.
 *
 * hint: The text that should be shown when the hint is clicked.
 * hintPosition: The position of the hint pop-up, relative to the element.
 */
@Directive({
    selector: '[hint]' // eslint-disable-line @angular-eslint/directive-selector
})
export class HintDirective implements OnInit {
    @Input() public hint: string;
    @Input() public hintPosition: string;

    constructor(
        private el: ElementRef
    ) {}

    public ngOnInit(): void {
        this.el.nativeElement.setAttribute('data-hint', this.hint);

        if (this.hintPosition) {
            this.el.nativeElement.setAttribute('data-hintposition', this.hintPosition);
        }
    }
}